
<div class="menu-container">
  <app-menu-super-user></app-menu-super-user>
  <br>
<div class="card card-outline-secondary">
 
    <div class="card-body data-content">
        <h1 class="title" >Agendar cliente</h1>
    <form autocomplete="off" class="form"  name="miFormularioAgendar" [formGroup]="miFormularioAgendar"   (ngSubmit)="PostAgendamiento()">
        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Cliente</label>
          <div class="col-lg-8 col-12 inputs">
            <div class="dropdown" *ngIf="actualize == false">
              <div id="pruebas1" class="form-control input-form" (click)="MostarSelect()" >
                {{ selectedOption ? selectedOption : 'Selecciona Una Opción' }}
              </div>
              <div [class.dropdown-menu-show]="!showMenus" class="dropdown-menu">
                <input id="search_inpt" class="dropdown-menu-search" type="text"  placeholder="Buscar..." (input)="search()">
                <div class="dropdown-menu-inner">
                  <div class="dropdown-menu-item" *ngFor="let item of filteredItems"  [attr.data-value]="item.id" [ngClass]="{ 'selected': item === selectedMenus }" (click)="selectOption(item)">{{ item.name }} - {{item.id}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="actualize == true"> 
              <input type="text" readonly id="clients" name="clients"  class="form-control input-form"  formControlName="client">
              <input type="text" hidden class="form-control input-form"  id="id_client" name="id_client" formControlName="id" >
            </div>
            
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Fecha Agendamiento</label>
          <div class="col-lg-8 col-12 inputs">
            <input placeholder="Fecha Inicial" type="text" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', minDate:minDateValue  }" formControlName="date">
          </div>
        </div>


        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Valor</label>
          <div class="col-lg-8 col-12 inputs">
            <input type="number" id="monto" name="monto"  inputmode="numeric" class="form-control input-form"  placeholder="Valor a prestar" formControlName="value">
          </div>
        </div>
          
        <div class="form-group row" style="margin-bottom: 90px !important;">
          <label class="col-lg-3 col-form-label form-control-label"></label>
          <div class="col-lg-8 col-12 inputs">
            <input id="buttons_enviar" class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
          </div>
        </div>
      </form>


    </div>
  </div>
</div>


