import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-metas',
  templateUrl: './form-metas.component.html',
  styleUrls: ['./form-metas.component.css']
})
export class FormMetasComponent {

  miFormularioMetas: FormGroup = this.formBuilder.group({
    'nom_user': ['', [Validators.required, Validators.pattern('[a-zA-Z ]+')]],
    'semanal': ['', Validators.required],
    'tipo_de_ventas': ['', Validators.required],
    'nombre_bono': ['', Validators.required],
  });

  router: any;
  constructor(private formBuilder: FormBuilder) {}

  async enviarFormularioMetas(){

    const nom_user = (document.querySelector('#nom_use') as HTMLInputElement).value;
    const semanal = (document.querySelector('#semanal') as HTMLInputElement).value;
    const tipo_de_ventas = (document.querySelector('#tipo_de_ventas') as HTMLInputElement).value;
    const nombre_bono = (document.querySelector('#nombre_bono') as HTMLInputElement).value;

    if (!nom_user || !semanal || !tipo_de_ventas || !nombre_bono) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
    const formdataMetas = {
      // id: id,
      nom_user,
      semanal,
      tipo_de_ventas,
      nombre_bono,
    };

    try {
      const response = await fetch('http://192.168.0.19:5000/api/metas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formdataMetas)
      });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          // Vaciar los campos de entrada del formulario
          const elementos = ['#nom_user','#semanal', '#tipo_de_ventas', '#nombre_bono'];
          for (let i = 0; i < elementos.length; i++) {
          (document.querySelector(elementos[i]) as HTMLInputElement).value = '';
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al guardar el formulario...\n\n',
          text: 'Al parecer esta Mata ya se encuentra registrada.',
          showConfirmButton: false,
          timer: 3600
        });
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }
}
