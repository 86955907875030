import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';


@Component({
  selector: 'app-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.css'],
})
export class FormMessageComponent  implements OnInit {

  constructor(private routers: Router, private route: ActivatedRoute,public url: Url) { }

  ngOnInit(): void {
    this.getUsers();
  }
  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  datos: any[] = [];
  async getUsers(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "users", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
   }
  
  async addMessage() {
    const token = this.getCookie("_token")
    const message = (document.querySelector('#message') as HTMLSelectElement).value;
    const users = (document.querySelector('#users') as HTMLSelectElement).value;
    const data = {
      message: message,
      users_id: users
    };
  
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
      try {
        const response = await fetch(this.url.Ms1 + "message", {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          },
          body: formData
        });
  
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Enviado!',
            text: 'Mensaje Creado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              this.routers.navigate(['list-usuario']);
            }
          });

        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error!',
            text: 'No se pudo crear mensaje',
            showConfirmButton: true,
          })
        }
      } catch (error) {
        console.error('Error en la solicitud', error);
      }
   
  }
}
