
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content" style="overflow-y: scroll;overflow-x: hidden;">
        <h2>Caja Sucursal</h2>
        <button *ngIf="showCash" class="btn btn-primary" style="background-color:#0e658e !important;border: none; margin-left: 15px;" (click)="updateCash()" >Iniciar caja</button>
        <form autocomplete="off" class="form"  name="miFormularioCash"  [formGroup]="miFormularioCash" #formularioUser="ngForm" >
          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Fecha Caja</label>
            <div class="col-lg-8 col-12 inputs">
              <input placeholder="Fecha Inicial" type="text" id="dates" name="dates" class="form-control input-form" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" formControlName="dates" [disabled]="true">
              </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Inversion</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="investment" name="investment" placeholder="Inversion" [disabled]="true">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">
              Pagos Por Transferencia
              <button type="button" class="btn btn-primary info-transfer" (click)="paymentTransferRoute()">Detalle</button>
            </label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="pix_payment" name="pix_payment" placeholder="Pagos por transferencia" [disabled]="true">
            </div>
          </div>
          
 
      
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Salidas</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="expenses" name="expenses" placeholder="Egreso"  [disabled]="true">
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Pagos diario</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="nombre_concepto" name="nombre_concepto" placeholder="Ingreso"  >
            </div>
          </div> -->

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Resultado Diario</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="ingresos_diarios" name="ingresos_diarios" placeholder="Ingreso" [disabled]="true">
            </div>
          </div>

          <div class="form-group row" >
            <label class="col-lg-3 col-form-label form-control-label">Saldo Acumulado</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text"  id="total_balance" name="total_balance" placeholder="Saldo Acumulado" [disabled]="true">
            </div>
          </div>

          <div class="form-group row" style="margin-bottom: 200px !important;">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs" style="text-align: center;" >
             <input class="btn btn-primary subtmit-bt" style="width: 40vw;" type="button" value="Historico de caja" (click)="History_cash()">
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="contains-item-toggle fab-menu3" >
      <div class="circle-toggles">
        <div>
          <button class="btns-agg-options"  (click)="MovimientoList()" >
           Listar
          </button>
        </div>
        <div>
          <button class="btns-agg-options" (click)="MovimientoForm()">
            Crear
          </button>
         
        </div>
      </div>
    </div>
  
    <div class="menu-items-container">
      <div style="margin: auto;">
        <button class="btns-agg fab-button-movimientos">
          <img src="../../assets/images/infinito.png" alt="">
        </button>
    
      </div>
      <div style="margin: auto;">
        <button class="btns-agg fab-button-client"  (click)="inversionModal()"  >
          <img src="../../assets/images/infinito.png" alt="">
        </button>
      </div>

    <div style="display: flex; bottom: 0; left: 0; right: 0; position: fixed;">
      <div style="margin: auto;">
        <p class="label-btnsloan">Movimientos</p>
      </div>
      <div style="margin: auto;">
        <p class="label-btnsloan">Inversion</p>
      </div>
    </div>
  </div>
  
</div>
