
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <button class="btns-agg" (click)="addUser()">
        Nuevo +
    </button> 
    <div class="principal-user">
        <div *ngFor="let dato of datos; let i = index" class="card card-outline-secondary inicio-cont">
     
          <div class="card-body data-content" style="display: flex;">
            
            <div style="font-size: 16px; margin-left: 23px;">
                <h4>{{ dato.name }}</h4>
                <h4>Ruta: {{ dato.routes[0].routes_id.name }}</h4>
                <p style="font-size: 13px; ">
                    <b>
                        Rol: 
                    </b>
                    {{ dato.roles_id.type }}
                <br>
                    <b>
                        Bono:                
                    </b>
                    {{ dato.wages_id.bonus }}
                <br>
                    <b>
                        Meta:
                    </b> 
                    {{ dato.wages_id.meta }}
                <br>
                    <b>
                        Salario:
                    </b>
                    ${{ dato.wages_id.salary }}
                </p>
                
            </div>
          </div>
        </div>
    </div>
   
  </div>
  
</div>
