import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent  implements OnInit {

  constructor(private router: Router,public url: Url, private parames: ActivatedRoute) {}

  ngOnInit(): void {
    this.validReset();
  }

  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async validReset(): Promise<any> {
    const token = this.parames.snapshot.params['token'];
    const token_valid = this.getCookie("_token")
    try {
      const response = await fetch(this.url.Ms1 + "reset_password", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token,
          "ngrok-skip-browser-warning": "69420"
        }
      })
      if (!response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error!',
          text: 'Token invalido para este proceso',
          showConfirmButton: true,
          allowOutsideClick: false, // Evitar clics fuera del cuadro de diálogo
          allowEscapeKey: false, // Evitar el cierre con la tecla "Esc"
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['super-usuario']);
          }
        });
      }

    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }

  }

  logut(){
    const cookiesToDelete = ['_rol', '_id', '_token'];

    // Establecer una fecha de expiración pasada para cada cookie
    const pastDate = new Date(0).toUTCString();

    cookiesToDelete.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=${pastDate}; path=/`;
    });
    window.location.reload();
  }

  async reset_pass() {
    const token = this.parames.snapshot.params['token'];
    const password = (document.querySelector('#password') as HTMLSelectElement).value;
    const confirm_password = (document.querySelector('#confirm_password') as HTMLSelectElement).value;

    if (password !== confirm_password) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Las contraseñas no son iguales...',
        showConfirmButton: false,

      });
      return;
    }
    const formdataUser = {
      password,
      confirm_password
    }

    try {
      const response = await fetch(this.url.Ms1 + "reset_password", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ token,
        },
        body: JSON.stringify(formdataUser)
      })
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Se envio un correo para el cambio de contraseña',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            this.logut()
            this.router.navigate(['']);
          }
        });
      }

    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }

  }

}
