import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Url } from '../service/data.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-payment-nomina',
  templateUrl: './payment-nomina.component.html',
  styleUrls: ['./payment-nomina.component.css'],
})
export class PaymentNominaComponent  implements OnInit,AfterViewInit {
  showMenus = false;
  selectedDates: Date;
  selectedOption: string | null = null; // Inicialmente no se ha seleccionado ninguna opción
  searchQuery: string = "";
  selectedMenus: any | null = null; 
  selectMenuPass =  "";
  value_pago = "";
  cash_id = "";
  get filteredItems(): any[] {
    return this.datos.filter(datos =>
      datos.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
 
  constructor(public url: Url, private location: Location) { 
    this.selectedDates = new Date();
    this.searchQuery = '';
    this.datos = [];
  }

  async ngOnInit() {
    await this.getClient();
  }

  ngAfterViewInit() {

  }


  datos: any[] = [];
  async getClient(): Promise<any> {
    const token = this.getCookie("_token")
    const ruta_id = this.getCookie("_route")
    const response = await fetch(this.url.Ms1 + "users", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  search(){
    let searhcs = (document.querySelector('#search_inpt') as HTMLInputElement).value
    this.searchQuery = searhcs;
  }

  MostarSelect(){
    if(this.showMenus == false){
      this.showMenus = true
    }
    else{
      this.showMenus = false
    }
  }


  async getRuta(id:string): Promise<any>{
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "user_route/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    const result = await response.json();
    if('error' in result){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Usuario no tiene ruta asignada para registro del pago',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
    else if ('id' in result) {
      this.cash_id = String(result.routes_id.cash_flow_id)
      return result;
    }

  }


  async selectOption(item: any) {
    this.selectedOption = item.name;
    this.selectedMenus = item;
    this.selectMenuPass = item.id;
    this.value_pago = item.wages_id.salary
    await this.getRuta(this.selectMenuPass)
  }

  async postLoan(){
    const token = this.getCookie("_token")
    const monto = (document.getElementById('monto') as HTMLSelectElement).value;
    const type_payment = (document.getElementById('type_payment') as HTMLSelectElement).value;
    const users_id = this.selectMenuPass
    const chas_ids = this.cash_id


    if (!chas_ids) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Usuario no tiene ruta asignada para registro del pago',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    if (!monto || !type_payment || !users_id) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }


    const data = {
      value_motion: monto,
      selectedType: type_payment,
      cash_id: chas_ids
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    try {
      const response = await fetch(this.url.Ms1 + "movimiento", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: formData
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Nomina registrada',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            this.location.back();
          }
        })
      
      }

    }
    catch{

    }




  }




}
