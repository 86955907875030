import { Component } from '@angular/core';

@Component({
  selector: 'app-form-pago-nomina',
  templateUrl: './form-pago-nomina.component.html',
  styleUrls: ['./form-pago-nomina.component.css']
})
export class FormPagoNominaComponent {

}
