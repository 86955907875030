<form autocomplete="off" class="form" name="miFormularioUser" (ngSubmit)="PaymentsLoan()"  #formularioUser="ngForm">
  <div class="form-group row" >
    <label class="col-lg-3 col-form-label form-control-label">Fecha y valor de pago</label>
    <div class="col-lg-8 col-12 inputs" style="height: 45px;">
      <input class="form-control input-form" (click)="fechaIndi()" type="text" [value]="selectedDates_info + ' / $' + value_cuota2" [disabled]="true">
      <input placeholder="Fecha Inicial"  style="visibility: hidden;" type="text" id="date_payment" name="date_payment" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates"  (ngModelChange)="onDateChange($event)" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', minDate:minDateValue }">
    </div>
  </div> 

    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label">Valor Cuota</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form" id="value_cuota" name="value_cuota" [value]="this.value_cuota"  type="number" placeholder="Valor Cuota"  inputmode="numeric">
      </div>
    </div>

    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label">Cuota Pico</label>
      <div class="col-lg-8 col-12 inputs">
        <input disabled class="form-control input-form" id="saldos" name="saldos" [value]="this.saldo"  type="text" placeholder="Valor Cuota" >
      </div>
    </div>

    
    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label">Tipo De Pago</label>
      <div class="col-lg-8 col-12 inputs">
        <select name="type_payments" id="type_payments"  class="form-control input-form">
          <option value="" selected>--Seleccionar--</option>
          <option value="True">Transferencia</option>
          <option value="False">Efectivo</option>
        </select>
      </div>
    </div>

    
    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label">Descripcion</label>
      <div class="col-lg-8 col-12 inputs">
        <input disabled class="form-control input-form" id="details" name="details" type="text" placeholder="Descripcion">
      </div>
    </div>


    <!-- 
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Numero de cuotas</label>
      <div class="col-lg-8 col-12 inputs">
        <select class="form-control input-form"  id="cuotas" name="cuotas">
          <option value="">-Selecionar--</option>
          <option *ngFor="let index of optionCount" [value]="index">{{index}}</option>
        </select>
      </div>
    </div>

    -->
    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input class="btn btn-primary subtmit-bt" id="enviar_form" type="submit" value="Pagar">
      </div>
    </div>
    <div style="text-align: center;">
      <div style="display: flex;">
        <input class="btn btn-primary subtmit-pay-not" id="pay_not" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="NotPay()"  value="No pago">
        <input class="btn btn-primary subtmit-mañana-pay" id="tomorrow_pay" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')"  (click)="sendPayTo()" value="Mas tarde">
      </div>
    </div>

  </form>