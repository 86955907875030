<div class="menu-container" style="position: relative;">

  <app-menu-super-user></app-menu-super-user>
  <h4>Lista Verificaciones</h4>
  <div style="width: 100%; border-right: solid 2px #adb5bd59; font-size: 16px; padding-left: 10px; padding-right: 10px;">
    <div>
      <div class="dropdown">
        <div class="form-control input-form" (click)="MostarSelect()">
          {{ selectedOption ? selectedOption : 'Selecciona una opcion' }}
        </div>
        <div [class.dropdown-menu-show]="!showMenus" class="dropdown-menu">
          <div class="dropdown-menu-inner">
            <div class="dropdown-menu-item" *ngFor="let dato of datos"  
            [attr.data-value]="dato.id" [ngClass]="{ 'selected': dato === selectedMenus }" (click)="listLoanVerifik(dato)">
              <b>Revision {{dato.revision}} </b>
              <br>
              {{convertirFecha(dato.date)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <span>
      <b>
        Clientes verificado: {{length_verifik}}/{{total_custo}}
      </b>
    </span>
    <br>
    <div *ngIf="loans != ''" class="conten-loans">
      <div class="info_init_c" *ngFor="let loan of loans">
        <div class="dta_init">
          {{convertirFecha(loan.date)}}
        </div>
        <div class="princi-loans">
          <div class="conte-info">
            <b>Cliente: </b><br>
            <span>
              {{loan.customers_id.name}} / {{loan.customers_id.customer_detail_id.surname}}
            </span>
          </div>
          <div class="conte-info">
            <b>Descuadre:</b><br>
            <span *ngIf="loan.total_adjust < 0">$ {{ loan.total_adjust }}</span>
          </div>
          <div class="conte-value">
            <b>A favor:</b><br>
            <span *ngIf="loan.total_adjust >= 0">$ {{ loan.total_adjust }}</span>
          </div>
        </div>
      </div>
     
    </div>
    <br>
    <table class="table">
      <tbody style="text-align: center;">
        <tr>
          <td></td>
          <th colspan="2">Resultado</th>
        </tr>
        <tr>
          <th class="tile_total"> Total Descuadre</th>
          <td colspan="2">${{descuadreNegativo}}</td>
        </tr>
        <tr>
          <th class="tile_total">Total a favor</th>
          <td colspan="2">${{descuadrePositivo}}</td>
        </tr>
        <tr>
          <th class="tile_total">Resultado</th>
          <th>{{Total_verific(descuadreNegativo,descuadrePositivo)}}</th>
          <td colspan="2">${{total_revis}}</td>
        </tr>
      </tbody>
    </table>

    
    <button class="btn btn-primary subtmit-bt" (click)="NewRevicion()" *ngIf="!permissions.hasOwnProperty('btn_verificacion_crear')">Nueva Verificacion</button>

  </div>
</div>
