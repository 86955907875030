import { Component } from '@angular/core';

@Component({
  selector: 'app-Pacific',
  templateUrl: './Pacific.component.html',
  styleUrls: ['./Pacific.component.css']
})
export class PacificComponent {

}
