<div *ngIf="showSaludo" [class]="claseTitular" id="container2">
    <div *ngIf="role_user == 'SuperUsuario'">
       <app-logo></app-logo>
    </div>

    <div *ngIf="role_user == 'Cobrador'">
        <img id="rol-cobrador" src="../../assets/images/login_cobrador.png" alt="">
    </div>

    <div *ngIf="role_user == 'Socio'">
        <img id="rol-cobrador" src="../../assets/images/login_cobrador.png" alt="">
    </div>
    <div *ngIf="role_user == 'Supervisor'">
        <img id="rol-cobrador" src="../../assets/images/login_cobrador.png" alt="">
    </div>

    <div *ngIf="role_user == 'Administrador'">
        <img id="rol-cobrador" src="../../assets/images/login_cobrador.png" alt="">
    </div>
</div>

<div class="content-text" *ngIf="showTextSaludo">
    <h3>{{name_user}}</h3>
    <br>
    <br>
    <p class="text-init">
      {{textSaludo}}
    </p>
    <br><br>
    <button class="next-profile" (click)="nextProfile()">
        <i class="fa fa-check"></i>
    </button>
</div>



<div *ngIf="showVistaUser">
    <app-super-usuario></app-super-usuario>
</div>