
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2 style="display: inline-block;">Pago nomina</h2>

      </div>
    </div>
  
  </div>
  
</div>
