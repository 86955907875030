import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';

@Component({
  selector: 'app-menu-super-user',
  templateUrl: './menu-super-user.component.html',
  styleUrls: ['./menu-super-user.component.css']
})

export class MenuSuperUserComponent implements OnInit  {
  faArrowLeft = faArrowLeft;
  faCog = faCog;
  menuItems = [{ label: 'Crear Usuario' }, { label: 'Crear Ruta' }];
  sucursal = [{ label: 'Nueva Sucursal' }];
  isRotated = false;
  isConfigOpen = false;
  isConfigOpen2 = false;
  rol: string | null = null;
  permission: any = {}
  constructor(private router: Router, private location: Location,private url: Url) {}


  ngOnInit() {
    this.rol = this.getCookie('_rol');
    this.getValidToken();
    this.permission = Permission.ElementPermissionObjects()
  }



  isHiddenRoute(): boolean {
    const currentRoute = this.router.url;
    return currentRoute.includes('/form-sucursal') || currentRoute.includes('/form-ruta');
  }
 
  onMenuItemClick(item: any) {
    this.router.navigateByUrl(item.route);
  }

  // onMenuItemClick(item: any) {
  //   this.router.navigate(['/form-usuario']);
  // }

  goBack() {
    const sucursals = this.getCookie("_branch")
    const rol = this.getCookie("_rol")
    const routes = this.getCookie("_route")
    const currentRoute = this.router.url;
    const routeWithoutParams = currentRoute.split('?')[0]; // Obtener la URL sin parámetros
    const paramsRoute =  currentRoute.split('?')[1];
    const routeWithoutIds = routeWithoutParams.replace(/\/\d+/g, ''); // Eliminar los números al final de la URL
    console.log(routeWithoutIds)
    switch (routeWithoutIds) {

      case '/form-sucursal':
      case '/list-rutas':
      case '/list-nominas':
        if (rol != "SuperUsuario"){
          this.location.back();
        }else{
          this.router.navigate(['/super-usuario']);
        }
        break;

      case '/lista-userSucursal':
      case '/cash-branch':
        this.router.navigate(['/super-usuario']);
      break;
      case '/form-festivos':
      case '/form-concepto':
      case '/nomina':
      case '/payment-wages':
      case '/history-cash':
      case '/history-cash-sucursal':
      case '/form-ruta':
      case '/form-caja':
      case '/bonos':
      case '/permissions':
      case '/list-permissions':
      case '/forgot_pass':
      case '/list-usuario':
      case '/list-movimiento-sucursal':
      case '/form-usuario':
      case '/form-modalidad':
        this.location.back();
      break;
      case '/form-movimiento':
      case '/details-route':
      case '/list-verik':
        if(routes != null){
          this.router.navigate(['/list-loans']);
        }
        else{
          this.router.navigate(['list-rutas', sucursals]);
        }
        break

      case '/list-client-ruta':
      case '/form-loans':
      case '/list-agendar-loan':
      case '/loan-cancel':
      case '/form-payment':
      case '/alert-payment':
      case '/loan-refinance':
      case '/form-agendar':
      case '/list-movimiento':
        this.router.navigate(['/list-loans']);
      break;
      case '/list-loans':
      case '/pago-nomina':
      case '/list-wages-payment':
        this.router.navigate(['/list-rutas/', sucursals]);
      break;
      case '/form-message':
        this.router.navigate(['/list-usuario']);
      break;
      case '/super-usuario':
        this.router.navigate(['/list-saludo']);
      break;

      case '/form-cliente':
        if(paramsRoute == undefined){
          this.router.navigate(['/list-loans']);
        }else{
          this.router.navigate(['/list-client-ruta']);
        }
      break;
      default:
        this.router.navigate(['/o']);
        break;
    }
            
            
  }
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  openConfig() {
    this.isRotated = !this.isRotated;
    this.isConfigOpen = !this.isConfigOpen;
  }
  logut(){
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const cookieName = cookie.split('=')[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }
    sessionStorage.clear();
    window.location.href = "/"
  }

  tempConfigOpen2 = false;
  tempConfigOpen3 = false;
  openConfig2() {
    this.isRotated = !this.isRotated;
    if(this.isConfigOpen2 == true){
      if(this.showSubmenu2 && !this.tempConfigOpen3){
        this.tempConfigOpen3 = true
        return this.isConfigOpen2 = true
      }
      if(this.tempConfigOpen3 && !this.showSubmenu2){
        return  this.showSubmenu2 = false
      }
      if(this.tempConfigOpen3){
        this.showSubmenu2 = false
        return this.tempConfigOpen3 = false
      }

      return this.isConfigOpen2 = false
     
    }
    else{
      return  this.isConfigOpen2 = true
    }
}


async UpdateFile(){
  const token = this.getCookie("_token")
  const options2 = `
  <option selected disabled>--Seleccionar--</option>
 `;

  const response = await fetch(this.url.Ms1 + "sucursal", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
  });
  const data = await response.json(); // Convertir la respuesta a JSON
  const options = data.map((sucursal: any) => {
    return `
    <option selected disabled>--Seleccionar--</option>
      <option value="${sucursal.id}" data-id="${sucursal.id}">${sucursal.name}</option>
    `;
  }).join('');


  const html_c = `
    <span>Sucursal</span>
    <select class="form-control input-form" id="sucursales">
        ${options}
    </select>
    <span>Rutas</span>
     <select class="form-control input-form" id="routes">
        ${options2}
    </select>
    <span>Tabla</span>
    <select name="select_payment" id="select_payment" class="form-control">
      <option value="">--Selecionar--</option>
      <option value="Customers">Cliente</option>
      <option value="Loans">Prestamo</option>
    </select>

    <span>Archivo</span>
    <input type="file" id="file_upd" class="form-control" />
  `;

  Swal.fire({
    title: 'Cargar datos',
    showCloseButton: true,
    html: html_c,
    showConfirmButton: true,
    confirmButtonColor: '#0e658e',
    confirmButtonText: 'Subir datos',  
    preConfirm: () => {
      const selectedRoute = (document.getElementById('select_payment') as HTMLSelectElement).value;
      const sucursal = (document.getElementById('sucursales') as HTMLSelectElement).value;
      const routes = (document.getElementById('routes') as HTMLSelectElement).value;
      if (!selectedRoute || !sucursal || !routes) {
        Swal.showValidationMessage('Por favor, ingrese toda la informacion.');
      }
      return selectedRoute;
    }
  }).then(async (result:any) => {
    if (result.isConfirmed){
      const image_up = (document.getElementById("file_upd") as HTMLInputElement).files;
      const sucursal = (document.getElementById('sucursales') as HTMLSelectElement).value;
      const routes = (document.getElementById('routes') as HTMLSelectElement).value;
      const formData = new FormData();
      if (image_up) {
        formData.append('file', image_up[0]);
      }

      formData.append('name', result.value);
      formData.append('route_id', routes);
      formData.append('sucursal_id', sucursal);

      const response = await fetch(this.url.Ms1 + "updates/cvs", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        body: formData
      })
      if(response.ok){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Tabla cargada',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        })
      }
      else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error!',
          text: 'Error al enviar documento',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        })
      }

    }
    
  })

  document.getElementById('sucursales')?.addEventListener('change', async (event) => {
    const sucursalId = (event.target as HTMLSelectElement).value;

    if (sucursalId) {
      const response2 = await fetch(this.url.Ms1 + "routes_list/sucursal/" + sucursalId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      });
      const data2 = await response2.json(); // Convertir la respuesta a JSON

      // Crear las opciones para el select de rutas
      const updatedOptions2 = data2.map((route: any) => {
        return `
          <option selected disabled>--Seleccionar--</option>
          <option value="${route.id}" data-id="${route.id}">${route.name}</option>
        `;
      }).join('');

      // Actualizar el select de rutas en el modal
      const routesSelect = document.getElementById('routes') as HTMLSelectElement;
      routesSelect.innerHTML = updatedOptions2;
    }
  });
}

crearMovimiento(){
  this.router.navigate(['form-movimiento'])

}

  miCajaRuta(){
    const cash_id = this.getCookie("_cash")
    if(cash_id){
      this.router.navigate(['form-caja', cash_id]);
    }else{
      alert("Ingrese en una ruta para usar una caja")
      return
    }
  }


  recuperar_password() {
    this.router.navigate(['forgot_pass']);
  }

    permissions(){
      this.router.navigate(['permissions']);
    }
  
  routes_rutas() {
    const cash_id = this.getCookie("_branch")
    if (cash_id){
      this.router.navigate(['list-rutas/', cash_id]);
    }
    else{
      alert("seleccione primero una sucursal")
    }
  }

  
  routes_sucursal() {
    this.router.navigate(['super-usuario']);
  }

  mi_perfil() {
    this.router.navigate(['perfin-superuser']);
  }

  mis_usuarios(){
    this.router.navigate(['list-usuario']);
  }
  formNomina(){
    this.router.navigate(['nomina']);
  }
  formBonos(){
    this.router.navigate(['bonos']);
  }

  listNomina(){
    this.router.navigate(['list-nominas']);
  }

  formConcept(){
    this.router.navigate(['form-concepto']);
  }

  getValidToken(){
    const token = this.getCookie("_token")
    if (!token){
      this.router.navigate(['']);
    }
  }


  paymentNomina(){
    this.router.navigate(['payment-wages']);
  }
  showSubmenu = false;
  
//   toggleSubmenu() {
//     if (this.isConfigOpen2) {
//       this.showSubmenu = !this.showSubmenu;
//   } else {
//       this.tempConfigOpen3 = true;
//       this.isConfigOpen2 = true;
//       this.showSubmenu = true;
//   }
// }
  
  showSubmenu2 = false;
  toggleSubmenu2() {
    let html_c = `
        <div id="action-buttons" style="display: flex;justify-content: space-around;">
          <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-list">Listar</button>
          <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-create">Crear</button>
          <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-bonos">Bonos</button>
      </div>
      `;
    Swal.fire({
      title: 'Selecciona una accion',
      showConfirmButton: false,  // Mostrar el botón "OK"
      html:html_c,
      showCloseButton: true,
      onOpen: () => {
        const btnCreateModal = document.getElementById('btn-create');
        const btnListModal = document.getElementById('btn-list');
        const btnBonosModal = document.getElementById('btn-bonos');
        
        if (btnCreateModal) {
            btnCreateModal.addEventListener('click', () => {
              this.formNomina()
              Swal.close()
            }
          );
        }
        if (btnListModal) {
            btnListModal.addEventListener('click', () => {
              this.listNomina()
              Swal.close()
            }
          );
        }
        if (btnBonosModal) {
            btnBonosModal.addEventListener('click', () => {
              this.formBonos()
              Swal.close()
            }
          );
        }
      },
    })

    // if(this.isConfigOpen2) { //menu abierto
    //   this.showSubmenu2 = !this.showSubmenu2;
    //   }

  }
}
