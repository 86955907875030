import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.css'],
})
export class PaymentCancelComponent  implements OnInit {

  constructor(public url: Url,private router: Router, ) { }

  ngOnInit() {
    this.ListLoanCancel();
  }
  //Variables
  info_loan: any[] = [];
  filter_data = false;
  originalDatos: any[] = [];

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async ListLoanCancel(): Promise<any> {
    const id = this.getCookie("_route")
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "cancel_clients/" + id, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      let info = jsonResponse['clients'];
      const uniqueClients = this.findUniqueClients(info);
      this.info_loan = uniqueClients;
      this.originalDatos = this.info_loan;
      return this.info_loan;
    }
  }

  filterList(searchTerm: any): void {
    const info =  searchTerm?.target?.value?.toLowerCase() || '';
    const search_info = this.info_loan
    if (!info) {
      this.info_loan = [...this.originalDatos];
      this.filter_data = false;
    } else {
      this.filter_data = true;
      this.info_loan = this.originalDatos.filter(item =>
        (item.id.toString().includes(info) ||
        item.name.toLowerCase().includes(info))
      );
    }
  }

  findUniqueClients(clients: any[]): any[] {
      const uniqueClientsMap: Map<number, any> = new Map();
      for (const client of clients) {
          const clientId = client.id;
          if (!uniqueClientsMap.has(clientId) || client.value > uniqueClientsMap.get(clientId).value) {
              uniqueClientsMap.set(clientId, client);
          }
      }
      const uniqueClients = Array.from(uniqueClientsMap.values());
      return uniqueClients;
  }


  calcularDiferenciaEnDias(date_end: string, last_payment: string): number {
    if (date_end && last_payment) {
      const dateEnd = new Date(date_end);
      const lastPayment = new Date(last_payment);
      const diferenciaEnMilisegundos = lastPayment.getTime() - dateEnd.getTime();
  
      const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      if(diferenciaEnDias < 0){
        return 0;
      }

      return diferenciaEnDias;
    } else {
      return 0;
    }
  }


  async agendarForm(dato: any):  Promise<any> {
    const navigationExtras: NavigationExtras = {
      queryParams: { dato: JSON.stringify(dato), agendar: true }
    };
    this.router.navigate(['form-agendar'], navigationExtras);
  }
}
