import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';


@Component({
  selector: 'app-form-modalidad',
  templateUrl: './form-modalidad.component.html',
  styleUrls: ['./form-modalidad.component.css']
})
export class FormModalidadComponent {


  constructor(private router: Router,public url: Url, private parames: ActivatedRoute) {}
  datos: any[] = [];

  ngOnInit(): void {
    this.getModalidad()
  }
  getCookie(name: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  async getModalidad(){
    const id_cliente = this.getCookie('_client');
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "loan_mode/" + id_cliente, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    let datos = await response.json()
    if(response.status == 200){
      const frequency = (document.querySelector('#frequency') as HTMLSelectElement);
      const quotas_number = (document.querySelector('#quotas_number') as HTMLInputElement);
      const percentage = (document.querySelector('#percentage') as HTMLInputElement);
      const limit_loan = (document.querySelector('#limit_loan') as HTMLInputElement);
      frequency.value = datos.frequency
      quotas_number.value = datos.quotas_number
      percentage.value = datos.percentage
      limit_loan.value = datos.limit_loan
    }

  }


  async postModalidad(){
    const token = this.getCookie('_token');
    const frequency = (document.querySelector('#frequency') as HTMLSelectElement).value;
    const quotas_number = (document.querySelector('#quotas_number') as HTMLInputElement).value;
    const percentage = (document.querySelector('#percentage') as HTMLInputElement).value;
    const limit_loan = (document.querySelector('#limit_loan') as HTMLInputElement).value;
    const customers_id = this.getCookie('_client');

    const formdataSucursal = {
      frequency: frequency,
      quotas_number:quotas_number,
      percentage:percentage,
      quota:quotas_number,
      limit_loan:limit_loan,
      customers_id:customers_id,
      
    };

    if (!frequency || !quotas_number || !percentage || !limit_loan){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena el campo',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',

      });
      return;
    }

    try {
      const response = await fetch(this.url.Ms1 + 'loan_mode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formdataSucursal),
      });

      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['list-client-ruta']);
          }
        });
      }


    }catch (error) {
      console.error('Error al enviar el formulario', error);
    }



  }


}
