
<div class="menu-container" style="position: relative;">

  <app-menu-super-user></app-menu-super-user>


  <div class="list-sucursa">
    <div *ngFor="let sucursal of datosAgrupados | keyvalue" class="contaner-list2">
      <div class="sucursal-header" style="margin-bottom: 10px;">
        <label for="" class="sucursal-name" style="font-size: 20px; font-weight: bold;">
          {{sucursal.key}}
        </label>
      </div>
    <div *ngFor="let dato of sucursal.value" class="contaner-list">
      <div class="contener-datas ">
        <div style="width: 100%; text-align: start; border-bottom: solid 2px #adb5bd59;">
          <div style="text-align: center; border-bottom: solid 2px #adb5bd59;width: 95%;margin: auto; height: 35px;">
            <label for="" class="info-client">
              {{dato.details}}
            </label>
          </div>
          <div style="display: block; overflow: hidden;overflow-x: auto;">
            <div class="row" style="width: 20%;text-align: center; display: table-cell;">
              <div style="border-right: solid 2px #adb5bd59; height: 21px">
                <p style="font-size: 15px;">{{ dato.frequency|| "No aplica"|titlecase  }}</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Frecuencia</span>
              </div>
            </div>
            <div class="row" style="width: 20%;text-align: center; display: table-cell; min-width: 100px;">
              <div style="border-right: solid 2px #adb5bd59; height: 21px">
                
                <p style="font-size: 15px;">{{dato.date}}</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Dia pago</span>
              </div>
            </div>
            <div class="row" style="width: 20%;text-align: center; display: table-cell;">
              <div style="border-right: solid 2px #adb5bd59; height: 21px">
                <p style="font-size: 15px;">${{dato.salary|number:'1.2-2' || "No aplica"}}</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Salario</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style="display: flex; height: 40px;">
        <div style="width: 50%; border-right: solid 2px #adb5bd59; font-size: 16px; padding-left: 10px; overflow-y: scroll;">
          <p style="border-radius: 7px;background-color: #8BC34A;text-align: center;margin: auto;font-weight: 500;width: 70px;color:#fff; margin-top: 7px;" (click)="tableBonos(dato)">Bonos</p>
        </div>
        <div style="width: 50%; font-size: 16px; padding-left: 10px; overflow-y: scroll;">
          <p style="border-radius: 7px;background-color: #2196F3;text-align: center;margin: auto;font-weight: 500;width: 70px;color:#fff; margin-top: 7px;"  (click)="tableMetas(dato)">Metas</p>
    
        </div>
      </div>
    </div>
  </div>
  </div>

</div>