
  <style>
    .head-mod{
      min-height: 40px;
    }
  </style>
  <div class="menu-container-superior">
    <i class="back bi bi-chevron-left" (click)="goBack()"></i>
  
    <div class="config" id="2"  (click)="openConfig2()" >
      <svg role="img" [ngClass]="{'rotate': isRotated}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gear" 
      class="svg-inline--fa fa-gear" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path fill="currentColor" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"></path></svg>
    </div>


      <div class="fondo-config" *ngIf="isConfigOpen2" (click)="openConfig2()" >
        <div>

        </div>
        <div class="profile-menus"  [ngClass]="{'show': isConfigOpen2}">
          <div class="hader-config">
           <div class="profiles-ins">
             Pacific
           </div>
           <div class="confs">
            <svg role="img" style="width: 24px; left: 30px; position: relative;" (click)="openConfig2()" [ngClass]="{'rotate': isRotated}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gear" 
            class="svg-inline--fa fa-gear" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path fill="currentColor" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"></path></svg>
           </div>
          </div>
   
         
          <div class="selects-infos" >
          <div class="row row-cols-4" style="justify-content: center; gap: 10px;">
            <div (click)="UpdateFile()" class="card col" style="max-width: 85px; max-height: 100px; padding: 0px !important;">
            <div class="card-header head-mod"
              style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #8BC34A;">Archivos</div>
                <div class="card-body" style="display: contents;"><img 
                    src="../../assets/images/update.svg" alt=""
                    style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
              </div>

            <div (click)="permissions()" *ngIf="!permission.hasOwnProperty('btn_permisos_ver')" class="card col" style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod" style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #9E9E9E;">Permisos</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/permisos.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
            <div class="card col" (click)="mis_usuarios()" *ngIf="!permission.hasOwnProperty('btn_usuarios_ver')"
              style="max-width: 85px;max-height: 100px;border-color: #ffc107b3;padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #ffc107;">Usuarios</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/usuario.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
            <div (click)="routes_sucursal()" *ngIf="!permission.hasOwnProperty('div_sucursal_listar')" class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #8BC34A;">Sucursal</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/sucursal.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
            <div (click)="routes_rutas()" *ngIf="!permission.hasOwnProperty('btn_ruta_ver')" class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #2196F3;"> Rutas</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/rutas.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>

            <div (click)="recuperar_password()" *ngIf="!permission.hasOwnProperty('btn_contraseña_update')"  class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style=" text-align: center;  font-size: 11px; white-space: pre-wrap;padding: 3px 0px; background-color: #e91e3a;">
                Recuperar Contraseña</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/contraseña.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>


            <div (click)="miCajaRuta()"  *ngIf="!permission.hasOwnProperty('btn_caja_create')" class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #2196F3;"> Cajas</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/caja.svg" alt="" style="width: 55px; margin: 10px auto; display: block; height: 40px;">
              </div>
            </div>
            <div (click)="formConcept()"  *ngIf="!permission.hasOwnProperty('btn_concepto_create')" class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="background-color: #FFC107;text-align: center;  font-size: 11px; white-space: pre-wrap;padding: 3px 5px; ">
                Plan De Cuentas</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/cuentas-plan.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
            <div id="pagos" (click)="toggleSubmenu2()" [ngClass]="{'show-submenu2': showSubmenu2}" *ngIf="!permission.hasOwnProperty('btn_nomina_listar')" class="card col"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center; font-size: 12px;background-color: #795548;"> Nomina</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/nomina.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
            <div class="card col" (click)="logut()"
              style="max-width: 85px; max-height: 100px; padding: 0px !important;">
              <div class="card-header head-mod"
                style="padding: 5px var(--bs-card-cap-padding-x); text-align: center;  font-size: 12px;">Salir</div>
              <div class="card-body" style="display: contents;"><img 
                  src="../../assets/images/salidas.svg" alt=""
                  style="width: 55px; margin: 10px auto; display: block; height: 40px;"></div>
            </div>
          </div>
          
            <br>
            
            <p class="footer-infos">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur nemo illo dicta, atque deserunt tempore quae, laborum nihil itaque voluptatum totam cum, ipsam labore dolorum corrupti. Maiores aut recusandae totam.
            </p>
          </div>
         </div>
      </div>
     

  </div>
