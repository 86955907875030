
<div class="menu-container" style="position: relative;">

<app-menu-super-user></app-menu-super-user>
    <div class="list-sucursa" >
        <ng-container *ngFor="let dato of datos_ruta">
            <div class="contaner-princi">
                <div style="margin-top: 45px;">
                    
                    <h5 class="nombre-sucursales">{{ dato.name }}</h5>
                </div>
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                <div class="contener-datas" >
                    <div style="width: 51%;">
                        <button class="btns-agg modalidad-infos" (click)="DetalleRoutes(dato.id)" *ngIf="!permissions.hasOwnProperty('btn_detalle_ruta_ver')">
                            Detalle
                        </button>
                    </div>
                    <div style="width: 23%; text-align: start;">
                        <i class="back" (click)="verInfoRoutes(dato)" [ngClass]="{'rotate': dato.showInfo}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </i>
                    </div>
                    <div style="width: 27%;">
                        <svg (click)="Cliente(dato.id, dato.cash_flow_id,dato.time_zone,dato.indicate_number)" class="back-ver svg-into rotate-45" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="34">
                            <path d="M0,23V16A9.01,9.01,0,0,1,9,7h4.83V5.414A2,2,0,0,1,17.244,4l5.88,5.879a3,3,0,0,1,0,4.242L17.244,20a2,2,0,0,1-3.414-1.414V17H8a6.006,6.006,0,0,0-6,6,1,1,0,0,1-2,0ZM15.83,8a1,1,0,0,1-1,1H9a7.008,7.008,0,0,0-7,7v1.714A7.984,7.984,0,0,1,8,15h6.83a1,1,0,0,1,1,1v2.586l5.879-5.879a1,1,0,0,0,0-1.414L15.83,5.414Z"/>
                        </svg>

                    </div>

                </div>
                    <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr style="text-align: center;">
                                    <th>Cantidad Clientes</th>
                                    <th>Monto Total Prestado</th>
                                    <th>Valor Total Cartera</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{dato.len_client}}</td>
                                    <td>$ {{dato.total_prestado|number:'1.2-2'}}</td>
                                    <td>$ {{dato.suma_valor_cartera|number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr style="text-align: center;">
                                    <th>Valor Total Por Cobrar</th>
                                    <td>${{dato.por_cobrar|number:'1.2-2'}}</td>
                                </tr>
                            </thead>
                           
                        </table>
                        <div class="pruebas"> 
                            <b>Supervisor:</b> 
                            <ng-container *ngIf="dato.users.Supervisor"> 
                                {{ dato.users.Supervisor.name }}
                            </ng-container> 
                            <br /> 
                            <b>Administrador:</b> 
                            <ng-container *ngIf="dato.users.Administrador">
                                {{dato.users.Administrador.name }} 
                            </ng-container> 
                            <br /> 
                            <b>Cobrador:</b>
                            <ng-container *ngIf="dato.users.Cobrador">
                                {{ dato.users.Cobrador.name }} 
                            </ng-container> 
                            <br /> 
                            <b>Socio:</b> 
                            <ng-container *ngIf="dato.users.Socio">
                                {{ dato.users.Socio.name }} 
                            </ng-container> 
                            <br /> 
                        </div>

                        <p class="informacion_de_usuario">
                            <button class="btns-delete marg-btn" (click)="borrar(dato)" *ngIf="!permissions.hasOwnProperty('btn_ruta_delete')">
                                <i class="bi bi-trash"></i>
                            </button>
                            <button class="btns-agg marg-btn" (click)="actualizar(dato)" *ngIf="!permissions.hasOwnProperty('btn_ruta_update')">
                                <i class="bi bi-pencil-square"></i>
                            </button>
        
                            
                        </p>
                    
                    </div>
                </div>
        
                <button class="btns-caja" (click)="FormCaja(dato.cash_flow_id)" *ngIf="!permissions.hasOwnProperty('btn_caja_create')"  >
                    Caja
                </button>
            </div>
         
        </ng-container>

        <div class="contains-item-toggle fab-menu2" >
            <div class="circle-toggles">
           
              <div>
                <button class="btns-agg-options" (click)="listPayNomina()" >
                 Listar
                </button>
              </div>
    
              <div>
                <button class="btns-agg-options" (click)="ListaNomina()" >
                  Pagar
                </button>
               
              </div>
            </div>
        </div>

     
        <div class="menu-items-container">
            <button class="btns-agg" style="margin: auto;" >
                Metas
            </button>
            
            <button class="btns-agg btns-nominas" style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_nomina_ver')" >
             Nomina
            </button>


          </div>
    </div>
</div>
    
<router-outlet></router-outlet>

