
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
      <input class="form-control input-form" type="text" id="buscar_sales" placeholder="Buscar" (input)="filterList($event)">
        <div class="card-body data-content" style="overflow-y: scroll;">
          <h2>Lista Clientes</h2>
          <br><br>
          <div style="height: 72%; overflow-y: scroll; margin-bottom:100px;" *ngIf="!filter_data">
            <table class="table custom-table">
              <thead>
                <tr>  
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Telefono</th>
                  <th scope="col">Direccion</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let dato of datos; let i = index">
                  <tr [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse-' + i"
                    [attr.aria-expanded]="!collapsedRows[i]" [attr.aria-controls]="'collapse-' + i">
                    
                    <th scope="row" style="width: 30px;">{{ i + 1 }}</th>
                    <td (click)="toggleRow(i)" style="width: 185px;">
                      {{dato.name}} {{dato.customer_detail_id.surname}}
                      <small class="d-block">Documento:<br>{{dato.id}}</small>
                    </td>

                    <td style="width: 145px;">
                      {{dato.phone}}
                      <small class="d-block">
                        Tope:
                        {{ dato.loan_mode_routes_id ? '$' + (dato.loan_mode_routes_id.limit_loan | number:'1.2-2') : "$0.0" }}
                      </small>
                    </td>
                    <td style="width: 179px;">{{dato.customer_detail_id.address}}</td>
             
                    <td style="width: 34%">
                      <div style="display: flex;">
                        <div id="container-floating2" >
                          <div id="floating-button2"class="info_subs5" (click)="mostarToltip(dato.id,1,dato)"> 
                            <p class="plus" style="display: grid;padding: 5px;">
                              <img style="width: 100%;" src="../../assets/images/ruta.svg" alt="">
                            </p>
                            <span class="tooltip{{dato.id}}_6 tooltip_5 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.id + '_6'] ? 'block' : 'none'">Crear prestamo</span>
                          </div>
                        </div>
                        <div id="container-floating" >
                          <div class="nd4 nds info_subs" (click)="mostarToltip(dato.customer_detail_id.id,2,dato)">
                            <fa-icon [icon]="faImage" class="icono-borrar"></fa-icon>
                            <span class="tooltip{{ dato.customer_detail_id.id}} tooltip_2 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.customer_detail_id.id] ? 'block' : 'none'">Ver imagenes</span>
                          </div>
                          
                          <div class="nd3 nds info_subs3" (click)="mostarToltip(dato.id,3,dato)"> 
                            <fa-icon [icon]="faPencilAlt" class="icono-actualizar" ></fa-icon>
                            <span class="tooltip{{dato.id}}_2 tooltip_3 globalTooltip"  [style.display]="tooltipStates['tooltip' + dato.id + '_2'] ? 'block' : 'none'">Editar cliente</span>
                          </div>
                          
                          <div class="nd1 nds info_subs4" (click)="mostarToltip(dato.id,4,'dato')">
                            <p class="letter">M</p>
                            <span class="tooltip{{dato.id}}_3 tooltip_4 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.id + '_3'] ? 'block' : 'none'">Modalidad y tope</span>
                          </div>
                        
                          <div id="floating-button">
                            <p class="plus">+</p>
                            <img class="edit" src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png">
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

        
                <tr [class.d-none]="!collapsedRows[i]">
                  <th class="sub_info_tables" style="color: #ff0000 !important;font-size:23px;text-align: center;">↳</th>
                  <th class="sub_info_tables">
                    Historico
                    <small style="color: #000;" class="d-block">
                      <button id="historys" class="btns-agg" (click)="VerHistorial(dato.id)">Ver</button>
                    </small>
                  </th>

                  <th class="sub_info_tables"  *ngIf="!permissions.hasOwnProperty('btn_prestamo_editar_alerta')">
                    Color
                    <small style="color: #000;" class="d-block">
                      <button id="historys" class="btns-agg" (click)="updateColor(dato.id)">Cambiar</button>
                    </small>
                  </th>
               
              
                  <th class="sub_info_tables" *ngIf="!permissions.hasOwnProperty('btn_cliente_reasignar')">
                    Ruta
                    <small style="color: #000;" class="d-block">
                      <button id="historys" (click)="mostarToltip2(dato.customer_detail_id.id,2,dato)" class="btns-agg" >Reasignar</button>
                      <span class="tooltip{{ dato.customer_detail_id.id}} tooltip_2 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.customer_detail_id.id] ? 'block' : 'none'">Reasignar Ruta</span>
                    </small>
               
                  </th>
                  
      
                  <th class="sub_info_tables"  *ngIf="permissions.hasOwnProperty('btn_prestamo_editar_alerta')">
                  </th>

                  <th  class="sub_info_tables" *ngIf="permissions.hasOwnProperty('btn_cliente_reasignar')">
                  </th>



                  <th class="sub_info_tables"></th>

                  </tr>

                  <tr class="spacer"><td colspan="100"></td></tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div style="height: 72%; overflow-y: scroll;" *ngIf="filter_data">
            <table class="table custom-table">
              <thead>
                <tr>  
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Telefono</th>
                  <th scope="col">Direccion</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let dato of datos; let i = index">
                  <tr [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse-' + i"
                    [attr.aria-expanded]="!collapsedRows[i]" [attr.aria-controls]="'collapse-' + i">
                    
                    <th scope="row">{{ i + 1 }}</th>
                    <td (click)="toggleRow(i)" style="width: 185px;">
                      {{dato.name}} {{dato.customer_detail_id.surname}}
                      <small class="d-block">Documento:<br>{{dato.id}}</small>
                    </td>

                    <td>
                      {{dato.phone}}
                      <small class="d-block">
                        Tope:
                        {{ dato.loan_mode_routes_id ? '$' + (dato.loan_mode_routes_id.limit_loan | number:'1.2-2') : "$0.0" }}
                      </small>
                    </td>
                    <td>{{dato.customer_detail_id.address}}</td>
             
                    <td style="width: 34%;" >
                      <div style="display: flex;">
                        <div id="container-floating2" >
                          <div id="floating-button2"class="info_subs5" (click)="mostarToltip(dato.id,1,dato)"> 
                            <p class="plus" style="display: grid;padding: 5px;">
                              <img style="width: 100%;" src="../../assets/images/ruta.svg" alt="">
                            </p>
                            <span class="tooltip{{dato.id}}_6 tooltip_5 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.id + '_6'] ? 'block' : 'none'">Crear prestamo</span>
                          </div>
                        </div>
                        <div id="container-floating" >
                          <div class="nd4 nds info_subs" (click)="mostarToltip(dato.customer_detail_id.id,2,dato)">
                            <fa-icon [icon]="faImage" class="icono-borrar"></fa-icon>
                            <span class="tooltip{{ dato.customer_detail_id.id}} tooltip_2 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.customer_detail_id.id] ? 'block' : 'none'">Ver imagenes</span>
                          </div>
                          
                          <div class="nd3 nds info_subs3" (click)="mostarToltip(dato.id,3,dato)">
                            <fa-icon [icon]="faPencilAlt" class="icono-actualizar" ></fa-icon>
                            <span class="tooltip{{dato.id}}_2 tooltip_3 globalTooltip"  [style.display]="tooltipStates['tooltip' + dato.id + '_2'] ? 'block' : 'none'">Editar cliente</span>
                          </div>
                          
                          <div class="nd1 nds info_subs4" (click)="mostarToltip(dato.id,4,'dato')">
                            <p class="letter">M</p>
                            <span class="tooltip{{dato.id}}_3 tooltip_4 globalTooltip" [style.display]="tooltipStates['tooltip' + dato.id + '_3'] ? 'block' : 'none'">Modalidad y tope</span>
                          </div>
                        
                          <div id="floating-button">
                            <p class="plus">+</p>
                            <img class="edit" src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png">
                          </div>
                        </div>
                      </div>

                    </td>
                  </tr>

        
                <tr [class.d-none]="!collapsedRows[i]">
                  <th class="sub_info_tables" style="color: #ff0000 !important;font-size:23px;text-align: center;">↳</th>
                  <th class="sub_info_tables">
                    Historico
                    <small style="color: #000;" class="d-block">
                      <button id="historys" class="btns-agg" (click)="VerHistorial(dato.id)">Ver</button>
                    </small>
                  </th>

                  <th class="sub_info_tables">
                    Color
                    <small style="color: #000;" class="d-block">
                      <button id="historys" class="btns-agg" (click)="updateColor(dato.id)">Cambiar</button>
                    </small>
                  </th>
                  <th class="sub_info_tables">
                    Ruta
                    <small style="color: #000;" class="d-block">
                      <button id="historys" class="btns-agg" (click)="AsingRutas(dato.id)">Reasignar</button>
                    </small>
                  </th>
                  <th class="sub_info_tables"></th>
      
                  </tr>

                  <tr class="spacer"><td colspan="100"></td></tr>
                </ng-container>
              </tbody>
            </table>

  
          </div>
  
          
         
        </div>
      </div>
    </div>
</div>
