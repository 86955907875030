import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-details-route',
  templateUrl: './details-route.component.html',
  styleUrls: ['./details-route.component.css'],
})
export class DetailsRouteComponent  implements OnInit {
  selectedDates: Date;
  selectedFinal: Date;
  maxDate: Date;
  minDate: Date;
  porcentaje_recaudo: any = 0;
  porcentaje_efectivo: any = 0;
  porcentaje_transfer: any = 0;
  showAmountRecaudo: boolean = false;
  showAmountEfectivo: boolean = false;
  showAmountTransfer: boolean = false;

  constructor(public url: Url,private router: Router,private parames: ActivatedRoute) { 
    const hoy = new Date();
    this.selectedDates = new Date();
    this.selectedFinal = new Date();
    this.maxDate = new Date();
    this.minDate = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 10);
  }
  datos: any[] = [];
  ngOnInit() {
    this.getDetailsRoute()
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  filterList(searchTerm: any): void {
    const info =  searchTerm?.target?.value?.toLowerCase() || '';
    
  }

  formatDate(inputDate:string) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  }

  async getDetailsRoute(){
    const token = this.getCookie("_token")
    const id = parseInt(this.parames.snapshot.params['id']);
    const sucursal = this.getCookie("_branch")
    const ruta_id = this.getCookie("_route")
    const data = {
      route_id: id,
      date_init: this.formatDate(String(this.selectedDates)),
      date_end: this.formatDate(String(this.selectedFinal))
    }

    const response = await fetch(this.url.Ms1 + "cash_route_detail", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
      body: JSON.stringify(data)
    });

       if (response.ok) {
        const jsonResponse = await response.json();
        this.datos = [jsonResponse]
     }

     let recaudo_pretendidos = this.datos[0].Recaudo.Recaudo_Pretendido
     let recaudo_total = this.datos[0].Recaudo.Recaudo_Total
     let pay_efectivo = this.datos[0].Recaudo.Pagos_Efectivo
     let pay_transfer = this.datos[0].Recaudo.Pagos_Transferencia

      let porcentaje_recaudo = recaudo_pretendidos !== 0 ? (recaudo_total * 100) / recaudo_pretendidos : 0;
      this.porcentaje_recaudo = Math.round(porcentaje_recaudo * 10) / 10;

      let porcentaje_efectivo = recaudo_total !== 0 ? (pay_efectivo * 100) / recaudo_total : 0;
      this.porcentaje_efectivo = Math.round(porcentaje_efectivo * 10) / 10;

      let porcentaje_transfer = recaudo_total !== 0 ? (pay_transfer * 100) / recaudo_total : 0;
      this.porcentaje_transfer = Math.round(porcentaje_transfer * 10) / 10;

  }

  ViewsInfos(id: any){
    let contens = document.getElementById("recaudo_pretend")
    let pagos_efectivo = document.getElementById("pagos_efectivo")
    let pagos_transfer = document.getElementById("pagos_transfer")
    if(id == '1'){
      if(this.showAmountRecaudo == false){
        this.showAmountRecaudo = !this.showAmountRecaudo;
        contens?.classList.add("actives")
      }
      else{
        this.showAmountRecaudo = !this.showAmountRecaudo;
        contens?.classList.remove("actives")
      }
    }
    if(id == '2'){
      if(this.showAmountEfectivo == false){
        this.showAmountEfectivo = !this.showAmountEfectivo;
        pagos_efectivo?.classList.add("actives")
      }
      else{
        this.showAmountEfectivo = !this.showAmountEfectivo;
        pagos_efectivo?.classList.remove("actives")
      }
    }
    if(id == '3'){
      if(this.showAmountTransfer == false){
        this.showAmountTransfer = !this.showAmountTransfer;
        pagos_transfer?.classList.add("actives")
      }
      else{
        this.showAmountTransfer = !this.showAmountTransfer;
        pagos_transfer?.classList.remove("actives")
      }
    }
  }

  async detailSearchCash(){
    const token = this.getCookie("_token")
    const id = parseInt(this.parames.snapshot.params['id']);
    const sucursal = this.getCookie("_branch")
    const ruta_id = this.getCookie("_route")
    const data = {
      route_id: id,
      date_init: this.formatDate(String(this.selectedDates)),
      date_end: this.formatDate(String(this.selectedFinal))
    }

    const response = await fetch(this.url.Ms1 + "cash_route_detail", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
      body: JSON.stringify(data)
    });

       if (response.ok) {
        const jsonResponse = await response.json();
        this.datos = [jsonResponse]
     }

     let recaudo_pretendidos = this.datos[0].Recaudo.Recaudo_Pretendido
     let recaudo_total = this.datos[0].Recaudo.Recaudo_Total
     let pay_efectivo = this.datos[0].Recaudo.Pagos_Efectivo
     let pay_transfer = this.datos[0].Recaudo.Pagos_Transferencia

      let porcentaje_recaudo = recaudo_pretendidos !== 0 ? (recaudo_total * 100) / recaudo_pretendidos : 0;
      this.porcentaje_recaudo = Math.round(porcentaje_recaudo * 10) / 10;

      let porcentaje_efectivo = recaudo_total !== 0 ? (pay_efectivo * 100) / recaudo_total : 0;
      this.porcentaje_efectivo = Math.round(porcentaje_efectivo * 10) / 10;

      let porcentaje_transfer = recaudo_total !== 0 ? (pay_transfer * 100) / recaudo_total : 0;
      this.porcentaje_transfer = Math.round(porcentaje_transfer * 10) / 10;
  }
}
