
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2>Crear mensaje personalizado</h2>
        <form autocomplete="off" class="form" name="miFormularioSucursal" (ngSubmit)="addMessage()" >
          <div class="form-group row">
            <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Mensaje personalizado:</label>
            <div class="col-lg-8 col-12 inputs">
              <textarea class="form-control input-form" name="message" id="message" cols="10" rows="5"  formControlName="message"></textarea>
    
            </div>
          </div>
         
          <div class="form-group row">
            <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Usuario</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form"  name="users" id="users">
                <option selected disabled>--Selecionar--</option>
                <option *ngFor="let user of datos" [value]="user.id">{{ user.name }}</option>

              </select>
            </div>
          </div>
         
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs">
              <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
