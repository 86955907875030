import { Component, } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
import * as $ from 'jquery'; // Importa jQuery
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-list-nomina',
  templateUrl: './list-nomina.component.html',
  styleUrls: ['./list-nomina.component.css'],
})
export class ListNominaComponent {


  constructor(private router: Router,public url: Url,private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.getClient();
  }
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  sumarFrecuencia(fecha: any, frecuencia: any) {
    if (frecuencia==="quincenal"){
      console.log(fecha,frecuencia)
      const dias = parseInt(fecha);
      const frecuenciaDias = frecuencia === "quincenal" ? 15 : 0;
      let string=dias.toString()+"/"+ ((dias + frecuenciaDias) % 30).toString();
      return string;
    }else{
      return fecha;
    }
    

  }

  formateaMiles(numero: any) {
    return this.decimalPipe.transform(numero, '1.2-2');
  }
  
  datos: any[] = [];
  datosAgrupados: { [key: string]: any[] } = {};
  async getClient(): Promise<any> {
    const token = this.getCookie("_token")
    const role = this.getCookie("_rol")
    if(role == "SuperUsuario"){
      var url_get = this.url.Ms1 + "wages_config"
    }
    else{
      var ids = this.getCookie("_branch")
      var url_get = this.url.Ms1 + "wages_config/" + ids
    }
    const response = await fetch(url_get, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         jsonResponse.forEach((dato:any) => {
          const sucursal = dato.sucursal_id.name;
          if (!this.datosAgrupados[sucursal]) {
            this.datosAgrupados[sucursal] = [];
          }
          this.datosAgrupados[sucursal].push(dato);
          });
          this.datos = jsonResponse
          return this.datos;
     }
  }


   tableMetas(dato:any){
    let htmlconten = `
    <div style="display: inline-grid; margin:auto;">
    <table class="table table-responsive table-bordered table-hover" style="display: block;border-collapse:collapse;overflow-y:scroll; ">
        <thead>
            <th style="min-width:115px;">Valor</th>
            <th style="min-width:115px;">Porcentaje</th>
            
        </thead>
        <tbody>`;
        htmlconten += `
          ${dato.bonos_config.map((result:any) => `
              <tr>
                <td style="text-align: center;">$${this.formateaMiles(result.value)}</td>
                <td style="text-align: center;">${result.percentage}%</td>
              </tr>
            `).join('')}
        `;
        htmlconten += `
        </tbody>
        </table>
        </div>`;

    Swal.fire({
      title: 'Metas',
      html: htmlconten,
      showCloseButton: true,
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#0e658e',
    });
  }

  tableBonos(dato:any){
    let htmlconten = `
    <div style="display: inline-grid; margin:auto;">
    <table class="table table-responsive table-bordered table-hover" style="display: block;border-collapse:collapse;overflow-y:scroll; ">
        <thead>
          <th style="text-align: center;">Nombre</th>
          <th style="text-align: center;">Frecuencia</th>
          <th style="text-align: center;">Dia</th>
          <th style="text-align: center;">Valor</th>
          <th style="text-align: center;">Retiradas</th>
          <th style="text-align: center;" style="width: 123px;">Ventas nuevas</th>
        </thead>
        <tbody>`;
        htmlconten += `
          ${dato.goals_config.map((result:any) => `
              <tr>
              <td style="text-align: center;">${result.name}</td>
              <td style="text-align: center;">${result.frequency}</td>
              <td style="text-align: center;">${this.sumarFrecuencia(result.dates,result.frequency)}</td>
              <td style="text-align: center;">$${this.formateaMiles(result.value)}</td>
              <td style="text-align: center;">${result.percentage_retiradas}%</td>
              <td style="text-align: center;">${result.quantity_new}$</td>
              </tr>
            `).join('')}
        `;
        htmlconten += `
        </tbody>
        </table>
        </div>`;

    Swal.fire({
      title: 'Bonos',
      html: htmlconten,
      showCloseButton: true,
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#0e658e',
    });
  }
}
