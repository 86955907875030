<app-menu-super-user></app-menu-super-user>

<div class="container" *ngIf="mostrarDiv1">
  <button class="save_pass" (click)="restaurar_password()">Restaurar contraseña</button>
  <form action="" class="form">
    <h4>tabla con la info del super usuario</h4>
  </form>
</div>

<div *ngIf="mostrarDiv2" class="pregunta">
  <form (submit)="enviarFormulario()" class="form">
      <label for="" class="nombre">Nombre completo del usuario</label>
      <input type="text" id="nombre" name="nombre-sucursal" class="form-input" value="" placeholder="Nombre">
      <button class="save" type="submit">Enviar</button>
  </form>
</div>

<div *ngIf="mostrarDiv3" class="for_cambio">
  <form action="">
    <input type="password" id="pass1" name="pass1" class="form-input" value="" placeholder="Contraseña">
    <input type="password" id="pass2" name="pass2" class="form-input" value="" placeholder="Repetir contraseña">
    <button class="save" type="submit">Cambiar</button>
  </form>
</div>
