
<div class="menu-container">
  <app-menu-super-user></app-menu-super-user>
  <br>
  <div class="card card-outline-secondary">
    <input class="form-control input-form" type="text" id="buscar_sales" placeholder="Buscar" (input)="filterList($event)">
    <div class="card-body data-content" style="margin-bottom: 100px;overflow-y:scroll;">
      <h2>Listado Cancelado</h2>
      <table class="table table-responsive table-bordered table-hover"
        style=" display: block;border-collapse:collapse;overflow-y:scroll; ">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Detalle</th>
            <th>Valor Prestamo</th>
            <th>Modalidad</th>
            <th>Valor Cuota</th>
            <th>Dias Credito</th>
            <th>Dias Mora</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        
          <ng-container *ngFor="let dato of info_loan;">
            <tr>
              <td>{{dato.name}}</td>
              <td>{{dato.detalle}}</td>
              <td>{{dato.value_loan}}</td>
              <td>{{dato.frequency}}</td>
              <td>{{dato.fee_payment}}</td>
              <td>{{dato.days_loans}}</td>
              <td>{{ calcularDiferenciaEnDias(dato.date_end,dato.last_payment) }}</td>
              <td>
                <button class="btns-agg" (click)="agendarForm(dato)">Agendar</button>
              </td>
            </tr>
          
          </ng-container>
        </tbody>

      </table>
    </div>
  </div>

</div>