<body>
  <section class="loader"> 
    <span class="loader__text"><span>Expande,</span></span>
    <span class="loader__text" id="text1"><span>tu negocio</span></span>
    <span class="loader__text" id="text2"><span>con confianza</span></span>
    <div class="loader__slice"></div> 
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
  </section>
  <section class="hero">
    <h1 class="hero__title"><span>Pacific.</span></h1>
  </section>
</body>
