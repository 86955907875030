import { Component, OnInit, AfterViewInit, } from '@angular/core';  
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-form-caja',
  templateUrl: './form-caja.component.html',
  styleUrls: ['./form-caja.component.css']
})
export class FormCajaComponent  implements OnInit,AfterViewInit{
  datos_cash: any[] = [];
  fecha_query = "" ;

  constructor(private router: Router,public url: Url, private route: ActivatedRoute,private formBuilder: FormBuilder) {
    this.miFormularioCash = this.formBuilder.group({
      total_pix:[''],
      dates: [new Date(), Validators.required], // Fecha actual como valor predeterminado
      investment: [''],
      total_balance: [''],
      expenses: [''],
      income: [''],
      detalle_investment: [''],
      base: [''],
      sale: [''],
      income_dayli: [''],
    });
    this.miFormularioCash.get('dates')?.valueChanges.subscribe(nuevaFecha => {
      const fecha = new Date(nuevaFecha);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Nota: Los meses en JavaScript son base 0
      const anio = fecha.getFullYear();
      const fechaFormateada = `${dia}/${mes}/${anio}`;
      this.fecha_query = fechaFormateada
      this.consulta();
    });
  
  }

  miFormularioCash: FormGroup = this.formBuilder.group({
    dates: [new Date(), Validators.required], // Fecha actual como valor predeterminado
    'investment': ['', Validators.required,],
    'total_balance': ['', Validators.required,],
    'expenses': ['', Validators.required,],
    'income': ['', Validators.required,],
    'detalle_investment': ['', Validators.required,],
    'base': ['', Validators.required,],
    'sale': ['', Validators.required,],
    'income_dayli': ['', Validators.required,],
  });
  
  dato: any;
  esEdicion = false;

  ngAfterViewInit() {
  
  }

  ngOnInit(): void {   
    this.getCash();      
    this.disableInvestmentControl();
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  formatearNumero(numero:any) {
    let data = parseFloat(numero);
    let formattedNumber = data.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
    return formattedNumber?.startsWith("-") ? "-$" + formattedNumber.slice(1) : "$" + formattedNumber;
  }


 async getCash(): Promise<any> {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Los meses en JavaScript son indexados desde 0
    const year = currentDate.getFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

    const formdataUser = {
      date:formattedDate
    }
    const token = this.getCookie("_token")
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    const id = this.route.snapshot.params['id'];
    document.cookie = "_cash" + "=" + id + ";" + expiresStr + ";path=/";
    const response = await fetch(this.url.Ms1 + "caja_day/" + id, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
       body: JSON.stringify(formdataUser)
     });
 
     if(response.status == 200){
      let result = await response.json();
      result = result[0];
      let sumas = parseFloat(result.total_balance) + parseFloat(result.total_pix)
      this.miFormularioCash.patchValue({
        'total_pix': this.formatearNumero(result.total_pix),
        'investment':this.formatearNumero( result.investment),
        'total_balance': this.formatearNumero(sumas),
        'expenses':this.formatearNumero(result.expenses),
        'income': this.formatearNumero(result.income),
        //'detalle_investment': this.formatearNumero(result.detalle_investment),
        'base': this.formatearNumero(result.base),
        'sale': this.formatearNumero(result.loans),
        'income_dayli': this.formatearNumero(result.income_dayli),
      });
    
     }
 
   }

  
   async postCash(){
    const token = this.getCookie("_token")
    let cash_id = this.route.snapshot.params['id']
    const addedDate = new Date();
    const date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;


    const investment = (document.querySelector('#investment') as HTMLInputElement).value;
    const total_pix = (document.querySelector('#total_pix') as HTMLInputElement).value;
    const total_balance = (document.querySelector('#total_balance') as HTMLInputElement).value;
    const expenses = (document.querySelector('#expenses') as HTMLInputElement).value;
    const base = (document.querySelector('#base') as HTMLInputElement).value;
    const sale = (document.querySelector('#sale') as HTMLInputElement).value;
    const income = (document.querySelector('#nombre_concepto') as HTMLInputElement).value;
    const detalle_investment = (document.querySelector('#detalle_investment') as HTMLInputElement).value;
    const income_dayli = (document.querySelector('#ingresos_diarios') as HTMLInputElement).value;

    const formdataUser = {
      date:date_final,
      total_pix:total_pix,
      payments: income,
      loans: sale,
      investment: investment,
      income: income,
      expenses: expenses,
      total_balance: total_balance,
      cash_flow_id: parseInt(cash_id),
      base: base,
      income_dayli: income_dayli
    };


    try {
      const response = await fetch(this.url.Ms1 + "caja_day", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          });
       
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al actualizar Usuario...\n\n',
          text: 'validad informacion',
          showConfirmButton: false,
        });
      }
     
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }

   }

   disableInvestmentControl(): void {
    this.miFormularioCash.get('total_balance')?.disable();
    this.miFormularioCash.get('total_pix')?.disable();
    this.miFormularioCash.get('investment')?.disable();
    this.miFormularioCash.get('expenses')?.disable();
    this.miFormularioCash.get('base')?.disable();
    this.miFormularioCash.get('income')?.disable();
    this.miFormularioCash.get('detalle_investment')?.disable();
    this.miFormularioCash.get('sale')?.disable();
    this.miFormularioCash.get('income_dayli')?.disable();
  }

  async consulta(){
    const token = this.getCookie("_token")
    const id = this.route.snapshot.params['id'];
    const formdataUser = {
      date: this.fecha_query
    }

    const response = await fetch(this.url.Ms1 + "caja_day/" + id, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       },
       body: JSON.stringify(formdataUser)
     });
     const results = await response.json();

     if (Array.isArray(results) && results.length > 0) {
      const result = results[0];
      this.miFormularioCash.patchValue({
        'total_pix': this.formatearNumero(result.total_pix),
        'investment':this.formatearNumero( result.investment),
        'total_balance': this.formatearNumero(result.total_balance),
        'expenses':this.formatearNumero(result.expenses),
        'income': this.formatearNumero(result.income),
        //'detalle_investment': this.formatearNumero(result.detalle_investment),
        'base': this.formatearNumero(result.base),
        'sale': this.formatearNumero(result.loans),
        'income_dayli': this.formatearNumero(result.income_dayli),
      });
      return
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'No existe caja para este dia',
        text: 'validad informacion',
        showConfirmButton: true,
        confirmButtonColor: '#0e658e',
      });
      this.miFormularioCash.patchValue({
        'total_pix':0,
        'investment': 0,
        'total_balance': 0,
        'expenses': 0,
        'income': 0,
        'base':0,
        'sale':0,
        'income_dayli':0
        // Agrega más campos según necesites
      });
      return
    }
    

  }


  HistoryCash(){
    this.router.navigate(['/history-cash']);
  }
 

}
