import { Component, OnInit } from '@angular/core';
import { gsap } from 'gsap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss'],
})
export class AnimationComponent  implements OnInit {
  introTl = gsap.timeline();
  constructor(private router: Router) { }

 async ngOnInit() {
    this.introTl
    .to('.loader__text span', { autoAlpha: 1 })
    .from('.loader__text span', { yPercent: 150, stagger: .125, duration: .325, ease: 'Power3.inOut' })
    .to('.loader__text span', { yPercent: 150, stagger: .125, duration: .325, ease: 'Power3.inOut' }, '+=.9')

    .from('#text1', { yPercent: 45, stagger: .125, duration: .325, ease: 'Power3.inOut' })
    .from('#text2', { yPercent: 89, stagger: .125, duration: .325, ease: 'Power3.inOut' })


    .to('.loader__slice', { yPercent: 100, stagger: .125, duration: .2, ease: 'Power3.inOut' }, '<.125')
    .from('.hero__title span', { yPercent: -100, duration: .6, ease: 'Power3.inOut' }, '<.4.5')
  }

}
