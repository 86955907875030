import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-vista-uno',
  templateUrl: './vista-uno.component.html',
  styleUrls: ['./vista-uno.component.css'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('3000ms ease-out', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})

export class VistaUnoComponent {
  constructor(private router: Router) { }

  ngOnInit() {

      this.router.navigate(['']);
    
  }
}
