import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-list-permissions',
  templateUrl: './list-permissions.component.html',
  styleUrls: ['./list-permissions.component.css'],
})
export class ListPermissionsComponent  implements OnInit {

  constructor(private router: Router,public url: Url, private route: ActivatedRoute) {}
  datos: any[] = [];
  isConfigOpen = false;
  selectedItems: any[] = [];
  checkItems: any[] = [];
  sendPermissos: any = {};
  ListGroupPermissions: any[] = [];
  ListPermissionRoles: any[] = [];

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  ngOnInit() {
    this.getPermision()
  }

  verInfoLoan(dato: any) {
    this.isConfigOpen = !this.isConfigOpen;
    dato.isConfigOpen = !dato.isConfigOpen;
    const index = this.selectedItems.indexOf(dato);
    if (index >= 0) {
        this.selectedItems.splice(index, 1);
    } else {
        this.selectedItems.push(dato);
    }
  }

 async getPermision(){
    const token =  this.getCookie('_token');
    let usurio = this.route.snapshot.params['usuario']

    const response_permission = await fetch(this.url.Ms1 + "permissions_roles", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    });
    const response = await fetch(this.url.Ms1 + "permissions", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    });
    const permissions_roles = await response_permission.json();
    const permissions = await response.json();

    const groupedData = new Map<string, any>();
    permissions.forEach((item:any) => {
      const name = item.name;
      if (!groupedData.has(name)) {
        groupedData.set(name, []);
      }
      groupedData.get(name).push(item);
    });

    groupedData.forEach((permissions, name) => {
      const group = { name, permissions };
      this.ListGroupPermissions.push(group);
    });
    const filteredData = permissions_roles.filter((item:any) => item.roles_id.id === parseInt(usurio));
    const grouPermisionRoles= new Map<string, any>();
    filteredData.forEach((item:any) => {

      const name = item.permission_id.name;
      if (!grouPermisionRoles.has(name)) {
        grouPermisionRoles.set(name, []);
      }
      grouPermisionRoles.get(name).push(item);
    });


    grouPermisionRoles.forEach((permissions, name) => {
      const group = { name, permissions };
      this.ListPermissionRoles.push(group);
    });

  }
  
  isPermissionSelected(permissionGroup: any): any {
    if (permissionGroup){
      const isPermissionSelected = this.ListPermissionRoles.some(rolePermission =>
        rolePermission.permissions.some((permisos:any) => permisos.permission_id.id === permissionGroup)
      )
      return isPermissionSelected;
    }
    return false;
  }


  checkPermissos(event: Event): void {
    const target = event.currentTarget as HTMLElement;
    const itemId = target.querySelector('.checkbox .check-icon')?.getAttribute('data-value');
    const modulo = target.querySelector('.checkbox .check-icon')?.getAttribute('data-modulo');
  
    if (itemId && modulo) {
      if (this.checkItems.includes(Number(itemId))) {
        this.checkItems = this.checkItems.filter(id => id !== Number(itemId));
        target.classList.add('not_checked2');
        target.classList.remove('checked2');
      } else {
        this.checkItems.push(Number(itemId));
        target.classList.remove('not_checked2');
        target.classList.add('checked2');
      }
      
    }
  }

  validInfoPermissions() {
    const elementos = document.getElementsByClassName('not_checked2');
    let usuario = this.route.snapshot.params['usuario'];
    if (elementos.length > 0) {
        this.sendPermissos = {
            role: this.list_roles(usuario),
            permissions: {}
        };

        Array.from(elementos).forEach((elemento, index) => {
            const checkIcon = elemento.querySelector('.checkbox .check-icon');
            if (checkIcon) {
                const dataValue = checkIcon.getAttribute('data-name');
                const dataModulo = checkIcon.getAttribute('data-modulo');
                if (dataModulo) {
                    if (!this.sendPermissos.permissions[dataModulo]) {
                        this.sendPermissos.permissions[dataModulo] = [];
                    }
                    this.sendPermissos.permissions[dataModulo].push(dataValue);
                } 
            } 
        });
    }
    else{
      this.sendPermissos = {
        role: this.list_roles(usuario),
        permissions: {"Usuarios":[]}
      }
    }
    return this.sendPermissos
}


  async submitPermissions() {
    const token =  this.getCookie('_token');
    let datos = this.validInfoPermissions()

    const response = await fetch(this.url.Ms1 + "permissions", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
      body: JSON.stringify(datos)
    });
    const result = await response.json()
    if (result){
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Guardando...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      window.location.reload()
    }
  }
  

  list_roles(usuario: any){
    let role = ''
    switch (usuario) {
      case '5':
        role = "Cobrador"
        break;
      case '2':
        role = "Administrador"
        break;
      case '3':
        role = "Socio"
        break;
      case '4':
        role = "Supervisor"
        break;
    }
    return role
  }

}
