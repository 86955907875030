<div class="card-body data-content">
<form autocomplete="off" class="form" name="miFormularioUser"  (ngSubmit)="postLoan()">

  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Cliente</label>
    <div class="col-lg-8 col-12 inputs">
      <div class="dropdown">
        <div class="form-control input-form" id="pruebas" (click)="MostarSelect()">
          {{ selectedOption ? selectedOption : 'Selecciona una opcion' }}
        </div>
        <div [class.dropdown-menu-show]="!showMenus" class="dropdown-menu">
          <input id="search_inpt" class="dropdown-menu-search" type="text"  placeholder="Buscar..." (input)="search()">
          <div class="dropdown-menu-inner">
            <div class="dropdown-menu-item" *ngFor="let item of filteredItems"  [attr.data-value]="item.id" [ngClass]="{ 'selected': item === selectedMenus }" (click)="selectOption(item)">{{ item.name }} {{ item.customer_detail_id.surname }} - {{item.id}}</div>
          </div>
        </div>
      </div>
          
    </div>
  </div>


  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Fecha Inicial</label>

    <div class="col-lg-8 col-12 inputs">
      <input placeholder="Fecha Inicial" disabled type="text" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Monto / Tope</label>
    <div class="col-lg-8 col-6 inputs">
      <input type="number" id="monto"  inputmode="numeric" name="monto"  class="form-control input-form"  placeholder="Valor a prestar" (change)="value_cuota()">
      
    </div>
    <div class="col-lg-2 col-6 inputs">
      <input type="number" id="limitT"  inputmode="numeric" name="limitT"  class="form-control input-form"  placeholder="Tope" disabled  [(ngModel)]="tope">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Frecuencia</label>
    <div class="col-lg-8 col-12 inputs">
      <!-- <ng-container *ngIf="modalidad_info && modalidad_info.length > 0; else showSelect">
        <input type="text" class="form-control input-form prueba" name="frecuency_input" id="frecuency" placeholder="Ingresar frecuencia" [(ngModel)]="frecuency_data">
      </ng-container> -->

        <select class="form-control input-form" name="frecuency" id="frecuency" size="0">
          <option value="" disabled selected>--Seleccionar--</option>
          <option value="Diario">Diario</option>
          <option value="Semanal">Semanal</option>
          <option value="Quincenal">Quincenal</option>
          <option value="Mensual">Mensual</option>
        </select>

      
    </div>
  </div>
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Porcentaje</label>
    <div class="col-lg-8 col-12 inputs">
      <input type="number" id="percentage" name="percentage"  class="form-control input-form"  inputmode="numeric"  placeholder="Porcentaje" (change)="value_cuota()" [(ngModel)]="percentaje">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Total Cuotas</label>
    <div class="col-lg-8 col-12 inputs">
      <input type="number" id="cuotas" name="cuotas"  class="form-control input-form"  inputmode="numeric" (change)="value_cuota()">    
    </div>
  </div>
  <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Valor Cuota</label>
      <div class="col-lg-8 col-12 inputs">
        <input id="value_total" name="value_total" class="form-control input-form" disabled type="text" placeholder="Valor Cuota" >
      </div>
    </div>

    
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Cobrar luego de</label>
      <div class="col-lg-8 col-12 inputs">
        <div class="dropdown">
          <div class="form-control input-form" (click)="MostarSelect2()">
            {{ selectedOption2 ? selectedOption2 : 'Selecciona una opcion' }}
          </div>
          <div [class.dropdown-menu-show]="!showMenus2" class="dropdown-menu">
            <input id="search_inpt2" class="dropdown-menu-search" type="text"  placeholder="Buscar..." (input)="search2()">
            <div class="dropdown-menu-inner">
              <div class="dropdown-menu-item" *ngFor="let item of filteredItems2"  [attr.data-value]="item.id" [ngClass]="{ 'selected': item === selectedMenus2 }" (click)="selectOption2(item)">
                {{ item.order }} {{ item.customers_id.name }}  {{ item.customers_id.customer_detail_id.surname }} ${{item.value}} - Prestamo #{{item.id}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label"></label>
    <div class="col-lg-8 col-12 inputs">
      <input class="btn btn-primary subtmit-bt" id="enviar_form" type="submit" value="Guardar">

    </div>
  </div>
</form>


</div>