import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
@Component({
  selector: 'app-form-ruta',
  templateUrl: './form-ruta.component.html',
  styleUrls: ['./form-ruta.component.css']
})
export class FormRutaComponent {
  

  miFormularioRuta: FormGroup = this.formBuilder.group({
    'name_route': ['', Validators.required,],
    'sucursal_id': ['', Validators.required,],
    'day_holidays': ['', Validators.required,],
    'horario': ['', Validators.required,],
    'city': ['', Validators.required,],
  });
  constructor(private formBuilder: FormBuilder, private routers: Router, private route: ActivatedRoute,public url: Url) {}
  info_param: any; 
  router: any;
  dato: any;
  esEdicion = false;
  id = ""
  festivos: any;
  permissions: any ={}
  is_brasil: boolean = false;
  ngOnInit(): void {
    this.getSucursal();
    this.getFestivos();
      if (this.dato) {
        this.esEdicion = true
        this.miFormularioRuta.patchValue({
          'name_route': this.dato.name,
          'name_cash_flow': this.dato.name_cash_flow,
          'sucursal_id': this.dato.branch_id.id,
          'city': this.dato.city,
          'horario': this.dato.time_zone,
          'day_holidays': this.dato.day_holidays_configuration_id.id
        });
        this.fomrZone(this.dato.time_zone)
        const indic = (document.getElementById("cont_indi") as HTMLElement);
        if(indic){
          let match = this.dato.indicate_number.match(/\(([^)]+)\)/);
          let number_in_parentheses = match ? match[1] : null;
          (document.getElementById("indit_br") as HTMLInputElement).value = number_in_parentheses
        }
      }
    this.permissions = Permission.ElementPermissionObjects()

  }  
  
  indicate(id:any){
    var id_stw = "";
    switch (id) {
      case "America/Sao_Paulo":
      case "America/Manaus":
      case "America/Fortaleza":
        id_stw = "BR";  // Brasil
        break;
      case "America/Argentina/Buenos_Aires":
        id_stw = "AR";  // Argentina
        break;
      case "America/Santiago":
        id_stw = "CL";  // Chile
        break;
      case "America/Bogota":
        id_stw = "CO";  // Colombia
        break;
      case "America/Lima":
        id_stw = "PE";  // Perú
        break;
      case "America/Mexico_City":
        id_stw = "MX";  // México
        break;
      case "America/Panama":
        id_stw = "PA";  // Panamá
        break;
      case "America/Caracas":
        id_stw = "VE";  // Venezuela
        break;
      case "America/Guyana":
        id_stw = "GY";  // Guyana
        break;
      case "America/Asuncion":
        id_stw = "PY";  // Paraguay
        break;
      case "America/La_Paz":
        id_stw = "BO";  // Bolivia
        break;
      case "America/Montevideo":
        id_stw = "UY";  // Uruguay
        break;
      case "America/Cayenne":
        id_stw = "GF";  // Guayana Francesa
        break;
      case "America/Paramaribo":
        id_stw = "SR";  // Surinam
        break;
      default:
        id_stw = "Timezone not found";
    }
    return id_stw
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  datos: any[] = [];
  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
   }


   async getFestivos(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "festivos", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.festivos = jsonResponse;
         return this.festivos;
     }
   }


   async fomrZone(time:any = null){
    var user_time_zone;
    if (time != null){
      user_time_zone = time
    }else{
      user_time_zone = (document.querySelector('#user_time_zone') as HTMLSelectElement).value;
    }
    var id_stw = "";
    switch (user_time_zone) {
      case "America/Sao_Paulo":
      case "America/Manaus":
      case "America/Fortaleza":
        id_stw = "BR";  // Brasil
        break;
      case "America/Argentina/Buenos_Aires":
        id_stw = "AR";  // Argentina
        break;
      case "America/Santiago":
        id_stw = "CL";  // Chile
        break;
      case "America/Bogota":
        id_stw = "CO";  // Colombia
        break;
      case "America/Lima":
        id_stw = "PE";  // Perú
        break;
      case "America/Mexico_City":
        id_stw = "MX";  // México
        break;
      case "America/Panama":
        id_stw = "PA";  // Panamá
        break;
      case "America/Caracas":
        id_stw = "VE";  // Venezuela
        break;
      case "America/Guyana":
        id_stw = "GY";  // Guyana
        break;
      case "America/Asuncion":
        id_stw = "PY";  // Paraguay
        break;
      case "America/La_Paz":
        id_stw = "BO";  // Bolivia
        break;
      case "America/Montevideo":
        id_stw = "UY";  // Uruguay
        break;
      case "America/Cayenne":
        id_stw = "GF";  // Guayana Francesa
        break;
      case "America/Paramaribo":
        id_stw = "SR";  // Surinam
        break;
      default:
        id_stw = "Timezone not found";
    }

    if (id_stw !== "Timezone not found") {
      try {
          const response = await fetch(`https://restcountries.com/v3.1/alpha/${id_stw}`);
          const result = await response.json();
          const flagUrl = result[0]?.flags?.png;
          if (flagUrl) {
              const imgElement = document.querySelector('#basic-addon1 img') as HTMLImageElement;
              if (imgElement) {
                  imgElement.src = flagUrl;
                  imgElement.alt = `${result[0]?.name?.common} flag`;
              }
          }
      } catch (error) {
          console.error("Error fetching country data:", error);
      }
      if (id_stw == "BR"){
        this.is_brasil = true;
        (document.getElementById("cont_indi") as HTMLElement).style.display = "block";
      }
      else{
        (document.getElementById("cont_indi") as HTMLElement).style.display = "none";
      }
    }
    return
   }

   async enviarFormularioUser() {
    const token = this.getCookie("_token")
    const name = (document.querySelector('#name_route') as HTMLInputElement).value;
    const branch_id = (document.querySelector('#sucursal_id') as HTMLSelectElement).value;
    const city = (document.querySelector('#city') as HTMLInputElement).value; 
    const day_holidays = (document.querySelector('#day_holidays') as HTMLSelectElement).value;
    const enviar_rutas = (document.querySelector('#enviar_rutas') as HTMLSelectElement);
    const user_time_zone = (document.querySelector('#user_time_zone') as HTMLSelectElement).value;
    const indic = (document.getElementById("cont_indi") as HTMLElement);
    var index_br = ""
    if(indic){
      var values = (document.getElementById("indit_br") as HTMLInputElement).value;
      index_br =`(${values})`;
    }

    if (!name || !branch_id || !user_time_zone || !city) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,

      });
      return;
    }
    enviar_rutas.style.display = "none";

    let id_stw = this.indicate(user_time_zone)
    const formdataUser = {
      name,
      name_cash_flow: "caja " + name,
      branch_id,
      city,
      time_zone:user_time_zone,
      indicate_number:id_stw,
      index_br,
      day_holidays_configuration_id: day_holidays
    };
    try {
      const response = await fetch(this.url.Ms1 + "routes", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            enviar_rutas.style.display = "block";
            if (result.isConfirmed) {
              this.routers.navigate(['/super-usuario']);       
            }
          });
       
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al guardar el formulario...\n\n',
          text: 'Al parecer este Usuario ya se encuentra registrado.',
          showConfirmButton: false,
        });
        enviar_rutas.style.display = "block";
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }  

  async actualizarFormularioRuta(formulario: any){
    const token = this.getCookie("_token")
    const name = formulario.name_route.toUpperCase();
    let indicate = this.indicate(formulario.horario)
    const branch_id = formulario.sucursal_id
    const day_holidays = formulario.day_holidays
    const time_zone = formulario.horario
    const city = formulario.city
    const indic = (document.getElementById("cont_indi") as HTMLElement);
    var index_br = ""
    if(indic){
      var values = (document.getElementById("indit_br") as HTMLInputElement).value;
      index_br =`(${values})`;
    }

    if (!name || !branch_id || !time_zone || !city) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,
      });
      return;
    }

    const formdataUser = {
      name,
      branch_id,
      city,
      time_zone,
      indicate_number:indicate,
      index_br,
      day_holidays_configuration_id: day_holidays
    };

    try {
      const response = await fetch(this.url.Ms1 + "routes/" + this.dato.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            window.location.reload()
          });
       
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al actualizar Usuario...\n\n',
          text: 'validad informacion',
          showConfirmButton: false,
        });
      }
     
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }

  }  

  convertToUppercase(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  listUserSelect(){
    let users = this.dato.users;
    let userValues = Object.values(users);
    let names = userValues.reduce((acc, user : any) => {
      return acc + (acc === '' ? '' : '/') + user.name;
    }, '');
  
    return names;
  }
  async getUseRoutes(id: any){
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "get_users_routes/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         return jsonResponse
     }
    

  }
  
  async ListUserEdit(){
    const token = this.getCookie('_token');
    let ids = this.dato.id
    let users_select = await this.getUseRoutes(ids)
    let users = this.dato.users;
    let htmlInners = '';
    let userNames = Object.values(users).reduce((acc, user: any) => {
      return acc + (acc === '' ? '' : '/') + user.name;
    }, '');


    for (let userType of ['Supervisor', 'Administrador', 'Cobrador', 'Socio']) {
        let user = users[userType];
        let userName = user ? user.name : '--Seleccionar--';
        let userid = user ? user.id : ''; // Obtener el nombre del usuario si existe, de lo contrario utilizar una cadena vacía
        let userOptions = users_select[userType].map((user: any) => `<option value="${user.id}">${user.name}</option>`).join('');
        let defaultOption = user ? `<option value="${user.id}" selected>${user.name}</option>` : `<option value="">--Seleccionar--</option>`;
        let selectHtml = `
            <select id="${userType.toLowerCase()}_list" name="${userType.toLowerCase()}_list" class="form-control input-form form-county">
                ${user ? '<option value="">--Seleccionar--</option>' : ''}
                ${defaultOption}
                ${userOptions}
            </select>
        `;
        htmlInners += `
            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label">${userType}</label>
                <div class="col-lg-8 col-12 inputs">
                    ${selectHtml}
                </div>
            </div>
        `;
    }
    Swal.fire({
      title: 'Modificar usuarios ruta',
      html: htmlInners,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#0e658e',
      confirmButtonText: 'Actualizar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let select_cobrador = (document.getElementById("cobrador_list") as HTMLSelectElement).value
        let select_admin = (document.getElementById("administrador_list") as HTMLSelectElement).value
        let select_super = (document.getElementById("supervisor_list") as HTMLSelectElement).value
        let select_socio = (document.getElementById("socio_list") as HTMLSelectElement).value

        let body = {
          route_id: ids,
          Cobrador:select_cobrador,
          Administrador:select_admin,
          Supervisor:select_super,
          Socio:select_socio,
        }
        const response = await fetch(this.url.Ms1 + 'edit_user_routes', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Usuarios ruta modificados',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }
      }
    })
  }
}
