<div class="card-body data-content">
  <form autocomplete="off" class="form" name="miFormularioUser"  (ngSubmit)="PostRefinance()">

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Fecha Inicial</label>
    
      <div class="col-lg-8 col-12 inputs">
        <input disabled placeholder="Fecha Inicial" type="text" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Saldo a Refinanciar</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="number" id="monto" name="monto"  inputmode="numeric" disabled class="form-control input-form"  placeholder="Valor a prestar" [(ngModel)]="monto" >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Frecuencia</label>
      <div class="col-lg-8 col-12 inputs">
        <ng-container *ngIf="modalidad_info && modalidad_info.length > 0; else showSelect">
          <input type="text" class="form-control input-form" name="frecuency" id="frecuency" placeholder="Ingresar frecuencia" [(ngModel)]="frecuency_data">
        </ng-container>
        
        <ng-template #showSelect>
          <select class="form-control input-form" name="frecuency" id="frecuency" size="0">
            <option value="" disabled selected>--Seleccionar--</option>
            <option value="Diario">Diario</option>
            <option value="Semanal">Semanal</option>
            <option value="Quincenal">Quincenal</option>
            <option value="Mensual">Mensual</option>
          </select>
        </ng-template>
        
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Porcentaje</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="number" id="percentage"  inputmode="numeric" name="percentage"  class="form-control input-form"  placeholder="Porcentaje" (change)="value_cuota()" [(ngModel)]="percentaje">
      </div>
    </div>


    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Total Cuotas</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="number" id="cuotas"  inputmode="numeric" name="cuotas"  class="form-control input-form" (change)="value_cuota()">    
      </div>
    </div>


    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Valor Cuota</label>
      <div class="col-lg-8 col-12 inputs">
        <input id="value_total" name="value_total" class="form-control input-form" disabled type="text" placeholder="Valor Cuota" >
      </div>
    </div>

    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
    
      </div>
    </div>


  </form>
</div>
