import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css'],
})
export class PermissionsComponent  implements OnInit {

  constructor(public url: Url,private router: Router, ) { }

  ngOnInit() {}

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  list_permission(usuario: any){
    switch (usuario) {
      case 'Cobrador':
        this.router.navigate(['list-permissions', 5]);
        break;
      case 'Administrador':
        this.router.navigate(['list-permissions', 2]);
        break;
      case 'Socio':
        this.router.navigate(['list-permissions', 3]);
        break;
      case 'Supervisor':
        this.router.navigate(['list-permissions', 4]);
        break;
    }
  }


  
}
