import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-history-cash',
  templateUrl: './history-cash.component.html',
  styleUrls: ['./history-cash.component.css'],
})
export class HistoryCashComponent  implements OnInit {
  selectedDates: Date;
  selectedFinal: Date;

  constructor(public url: Url,private router: Router){ 
    this.selectedDates = new Date();
    this.selectedFinal = new Date();
  }

  ngOnInit() {
    this.getHistoryCash();
  }
  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  datos: any[] = [];
  async getHistoryCash(): Promise<any>{
    const id = this.getCookie("_cash")
    const token = this.getCookie("_token")
    let data = {
      startDate: this.selectedDates,
      finishDate: this.selectedFinal
    }

     const response = await fetch(this.url.Ms1 + "daily_by_dates/" + id, {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
       });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
  }

  adjustDateToLocale(dateString: string): string {
    const date = new Date(Date.parse(dateString));
    const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return adjustedDate.toISOString(); // o el formato que desees
  }

  search(){
    this.getHistoryCash()
  }

  formatNumber(number:any){
    return number?.startsWith("-") ? "-$" + number.slice(1) : "$" + number;
  }

}
