
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2  *ngIf="rol === 'SuperUsuario'">Recuperar contraseña</h2>
        <h2  *ngIf="rol !== 'SuperUsuario'">Cambiar contraseña</h2>
        
        
        <div *ngIf="rol === 'SuperUsuario'">
          <form autocomplete="off" class="form" name="miFormularioSucursal" style="margin-top: 85px;" (ngSubmit)="enviarFormularioUser()" >
            <div class="form-group row">
              <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Correo electronico administrativo:</label>
              <div class="col-lg-8 col-12 inputs">
                <input class="form-control input-form" type="email" id="email_user" name="email_user" placeholder="Correo de super usuario"  formControlName="email_user">
              </div>
            </div>
           
            <div class="form-group row">
              <label class="col-lg-3 col-form-label form-control-label"></label>
              <div class="col-lg-8 col-12 inputs">
                <input  *ngIf="!esEdicion" class="btn btn-primary subtmit-bt" type="submit" value="Recuperar">
              </div>
            </div>
          </form>
        </div>
    

        <div *ngIf="rol !== 'SuperUsuario'">
          <form autocomplete="off" class="form" name="miFormularioSucursal" style="margin-top: 40px;" (ngSubmit)="restarUserPass()" >
            <div class="form-group row">
              <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Contraseña antigua:</label>
              <div class="col-lg-8 col-12 inputs">
                <input class="form-control input-form" type="password" id="pass_ant" name="pass_ant" placeholder="Contraseña antigua"  formControlName="pass_ant">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Nueva contraseña:</label>
              <div class="col-lg-8 col-12 inputs">
                <input class="form-control input-form" type="password" id="pass_new" name="pass_new" placeholder="Nueva contraseña"  formControlName="pass_new">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Verificar contraseña:</label>
              <div class="col-lg-8 col-12 inputs">
                <input class="form-control input-form" type="password" id="pass_comfirm" name="pass_comfirm" placeholder="Verificar contraseña"  formControlName="pass_comfirm">
              </div>
            </div>
           
            <div class="form-group row">
              <label class="col-lg-3 col-form-label form-control-label"></label>
              <div class="col-lg-8 col-12 inputs">
                <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
              </div>
            </div>
          </form>
        </div>
    
      </div>
    </div>
  
  </div>
  
</div>
