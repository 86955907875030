<app-menu-super-user></app-menu-super-user>
<div class="container">
    <h1 class="title">Metas</h1>
    <form class="form" name="miFormularioMetas" [formGroup]="miFormularioMetas" (ngSubmit)="enviarFormularioMetas()">
        <input type="text" id="nom_user" name="nom_user" class="form-input" value="" placeholder="Nombre del Usuario">
        
        <input type="date" id="semanal" name="semanal" class="form-input" value="" placeholder="Semanal">
        
        <input type="text" id="tipo_de_ventas" name="tipo_de_ventas" class="form-input" value="" placeholder="Tipos de Ventas">
        
        <input type="number" id="nombre_bono" name="nombre_bono" class="form-input" value="" placeholder="Nombre del Bono">
        
        <button class="save" type="submit">Guardar</button>
    </form>
</div>