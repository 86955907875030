<div style="position: relative;">

    <div>
        <b>
            {{names}} - {{surname}}
        </b>
    </div>
    <div style="display: flex; margin-bottom: 33px;">
        <div class="grid-item" (click)="openImg('Negocio','img4',img1, 'img4')">
            <img src="https://via.placeholder.com/88" style="width: 88px; height: 88px;" id="negocio"  alt="Imagen 1">
            <p style="margin-top: 10px; font-size: 12px;">Negocio</p>
        </div>
        <div class="grid-item" (click)="openImg('Cedula frontal','img1', img2, 'img1')">
            <img src="https://via.placeholder.com/88" style="width: 88px; height: 88px;" id="frontal" alt="Imagen 1">
            <p style="margin-top: 10px; font-size: 12px;">Cedula frontal</p>
        </div>
    </div>
    <div style="display: flex;">
        <div class="grid-item" (click)="openImg('Cedula atras','img2',img3, 'img2')"> 
            <img src="https://via.placeholder.com/88" style="width: 88px; height: 88px;" id="posterior" alt="Imagen 1">
            <p style="margin-top: 10px; font-size: 12px;">Cedula atras</p>
        </div>
        <div class="grid-item" (click)="openImg('Perfil','img3',img4, 'img3')">
            <img src="https://via.placeholder.com/88" id="selfi" style="width: 88px; height: 88px;" alt="Imagen 1">
            <p style="margin-top: 10px; font-size: 12px;">Foto de perfil</p>
        </div>
    </div>

</div>