
<div class="card-body data-content" id="conten-from-f">
  <form autocomplete="off" class="form" name="miFormularioFestivos"  (ngSubmit)="postFestivos()">

    <div class="form-group row" *ngIf="listFestivo" >
      <label class="col-lg-3 col-form-label form-control-label">Lista festivos</label>
      <div class="col-lg-8 col-12">
        <select class="form-control form-county" id="list_concepts" (change)="SelectOptionFestivo($event)">
          <option value="" disabled selected>--Seleccionar--</option>
          <option *ngFor="let datos of festivos" [attr.data-name]="datos.name"  [value]="datos.id">{{ datos.name }}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Grupo festivos</label>
      <div class="col-lg-8 col-12">
        <input type="text" class="form-control input-form" [(ngModel)]="name_festivo" name="name_festivo" id="name_festivo" style="text-transform: uppercase;">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Fecha festivo</label>
      <div class="col-lg-8 col-12">
        <input type="text" required class="form-control input-form" bsDatepicker [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY' }" [(ngModel)]="selectedDate" name="selectedDate" id="selectedDate" (ngModelChange)="onSelectedDateChange()">
      </div>
    </div>
  
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Dias feriados</label>
      <div class="col-lg-8 col-12" id="contens-listar">
        <ul>
          <li *ngFor="let date of selectedDates; let i = index">
            {{ date }}
            <button class="btn btn-primary btn-list" (click)="removeSelectedDate(i)">Quitar</button>
          </li>
        </ul>
        
      </div>
    </div>
  
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12">
        <input class="btn btn-primary subtmit-bt" *ngIf="!listFestivo" type="submit" value="Guardar">
        <input class="btn btn-primary subtmit-bt-update"  *ngIf="!permissions.hasOwnProperty('btn_festivos_update') && !listFestivo" (click)="updateFestivo()" value="Editar">

        <input class="btn btn-primary subtmit-bt" *ngIf="listFestivo" value="Guardar" (click)="PostFestivoUpdates()" >
        <input class="btn btn-primary subtmit-bt-update" *ngIf="listFestivo" (click)="updateFestivo()" value="Crear">
      </div>
    </div>
  </form>
      
  
  
  
</div>