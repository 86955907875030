<div class="card-body data-content">
  <form autocomplete="off" class="form" name="miFormularioMovimiento"  (ngSubmit)="PostAlert()">
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Fecha agendar</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="text" id="date_motion" name="date_motion" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', minDate:minDateValue  }">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Detalle</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="text" id="detalle_alert" name="detalle_alert" class="form-control input-form">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
      </div>
    </div>
  </form>
</div>