import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
  AfterViewInit,
  Renderer2,
}from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
import { Location } from '@angular/common';



@Component({
  selector: 'app-from-concepto',
  templateUrl: './from-concepto.component.html',
  styleUrls: ['./from-concepto.component.css']
})
export class FromConceptoComponent {
  listConcept = false;
  selectedRouteId: number[] = [];


  
  constructor(private router: Router,public url: Url, private location: Location) {}

  @ViewChild('selectBtn') selectBtnRef!: ElementRef;
  @ViewChild('btnText') btnTextRef!: ElementRef;
  @ViewChild('itemsContainer') itemsContainerRef!: ElementRef;

  permissions: any ={}
  addClickEventListener() {
    const selectBtn = this.selectBtnRef.nativeElement;
    selectBtn.addEventListener('click', () => {
      selectBtn.classList.toggle('open');
    });
  }

 
  ngAfterViewInit() {
    this.addClickEventListener();
  }

  ngOnInit() {
    this.getSucursal();
    this.getUsers();
    this.permissions = Permission.ElementPermissionObjects()
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  
  click_checks(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('checked');
    let checked = document.querySelectorAll(".checked");
    var btnText = document.querySelector(".btn-text") as HTMLElement;
    let selectedTexts: string[] = [];
    checked.forEach((item: Element) => {
      selectedTexts.push(item.textContent || "");
    });

    // Asignamos los textos concatenados al botón
    if (selectedTexts.length > 0) {
      btnText.innerText = selectedTexts.join(", ");
    } else {
      btnText.innerText = "Seleccionar Ruta";
    }

}

  

 async CreateConcepto(){
    let button = (document.getElementById("submits-save") as HTMLInputElement)
    button.style.display = "none";
    const token = this.getCookie("_token")
    const name = (document.querySelector('#nombre_concepto') as HTMLSelectElement).value;
    const type = (document.querySelector('#type_concept') as HTMLInputElement).value;
    const detail = (document.querySelector('#detalle') as HTMLInputElement).value
    const limit = (document.querySelector('#tope_cash') as HTMLInputElement).value;
    const period = (document.querySelector('#periods') as HTMLSelectElement).value;
    const sucursal_id = (document.querySelector('#sucursa_ids') as HTMLSelectElement).value;
    let limit_value;
    if(limit == ''){
      limit_value = 0
    }else{
      limit_value = parseInt(limit)
    }

    const formdata = {
      name,
      type,
      detail,
      limit: limit_value,
      period,
      sucursal_id
    };

    if(!name || !type || !detail || !sucursal_id){
      button.style.display = "inline-block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,
    })
    return
  }

    try {
      const response = await fetch(this.url.Ms1 + "concepto", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formdata)
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
           window.location.reload()
          }
        });
       
      }
      else{
        button.style.display = "inline-block";
      }

    }
    catch (error) {
      button.style.display = "inline-block";
      console.error('Error al enviar el formulario', error);
    }

  }
  
  convertToUppercase(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
  convertCapilize(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

  EditConcept(){
      this.listConcept = !this.listConcept
      this.vaciarCampos()
  }

  async SelectOption(): Promise<any>{
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_concepts') as HTMLSelectElement).value;
    const response = await fetch(this.url.Ms1 + "concepto/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
      if (response.ok) {
        const jsonResponse = await response.json();
        const nombre_concepto = (document.querySelector('#nombre_concepto') as HTMLSelectElement);
        const type_concept = (document.querySelector('#type_concept') as HTMLSelectElement);
        const periods = (document.querySelector('#periods') as HTMLSelectElement);
        const detalle = (document.querySelector('#detalle') as HTMLSelectElement);
        const tope_cash = (document.querySelector('#tope_cash') as HTMLSelectElement);
        const sucursal_id = (document.querySelector('#sucursa_ids') as HTMLSelectElement);
        if(jsonResponse.sucursal_id){
          sucursal_id.value = jsonResponse.sucursal_id.id
        }
        detalle.value = jsonResponse.detail
        tope_cash.value = jsonResponse.limit
        nombre_concepto.value = jsonResponse.name
        type_concept.value = jsonResponse.type
        periods.value = jsonResponse.period
        
    }

  }

  datos_mode: any[] = [];
  async getModeLoan(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "concepto", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_mode = jsonResponse;
         return this.datos_mode;
     }
   }
   datos_routes: any = [];
   async getUsers(): Promise<any> {
    const token = this.getCookie("_token")
     const response = await fetch(this.url.Ms1 + "users", {
         method: 'GET',
         headers: {
           "Authorization": `Bearer ${token}`,
           "ngrok-skip-browser-warning": "69420"
         },
       });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_routes = jsonResponse;
         return this.datos_routes;
     }
    };
   async updateConcept(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_concepts') as HTMLSelectElement).value;
    const name = (document.querySelector('#nombre_concepto') as HTMLSelectElement).value;
    const type = (document.querySelector('#type_concept') as HTMLInputElement).value;
    const detail = (document.querySelector('#detalle') as HTMLInputElement).value
    const limit = (document.querySelector('#tope_cash') as HTMLInputElement).value;
    const period = (document.querySelector('#periods') as HTMLSelectElement).value;
    const sucursal_id = (document.querySelector('#sucursa_ids') as HTMLSelectElement).value;

    const formdata = {
      name,
      type,
      detail,
      limit,
      period,
      sucursal_id
    };
    try {
      const response = await fetch(this.url.Ms1 + "concepto/" + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formdata)
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Actualizado',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);

    }


   }

   async ConceptSucursal(): Promise<any>{
    if(this.listConcept){
      const token = this.getCookie("_token")
      const sucursal_id = (document.querySelector('#sucursa_ids') as HTMLSelectElement).value;
      const response = await fetch(this.url.Ms1 + "concepto_sucursal/" + sucursal_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      this.datos_mode = await response.json()
      return this.datos_mode
    }
   }


   async DeleteConcept(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_concepts') as HTMLSelectElement).value;

    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'Seguro quiere eliminar?',
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'OK',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(this.url.Ms1 + "concepto/" + id, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`
            },
          });
          let infos = await response.json()
          if ("error" in infos) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No se puede eliminar este concepto',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            }).then((result) => {
              if (result.isConfirmed) {
                return
              }
            });
          }
          else {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Eliminado',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            });
          }
        } catch (error) {
          console.error('Error al enviar el formulario', error);
    
        }
      }
    });

    

   }

   datos: any[] = [];
   async getSucursal(): Promise<any> {
     const token = this.getCookie("_token")
     const role = this.getCookie("_rol")
     
     if(role != "SuperUsuario"){
      const id = this.getCookie("_branch")
      if(id){
        const response = await fetch(this.url.Ms1 + "sucursal/" + id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            "ngrok-skip-browser-warning": "69420"
          },
        });
          if (response.ok) {
            const jsonResponse = await response.json();
            this.datos = [jsonResponse];
            return this.datos;
        }
      }
     }
     const response = await fetch(this.url.Ms1 + "sucursal", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "ngrok-skip-browser-warning": "69420"
        },
      });
        if (response.ok) {
          const jsonResponse = await response.json();
          this.datos = jsonResponse;
          return this.datos;
      }
    }


    vaciarCampos() {
      const form = document.forms.namedItem('miFormularioSucursal') as HTMLFormElement;
      if (form) {
          for (let i = 0; i < form.elements.length; i++) {
              const element = form.elements[i] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
              if (element.tagName === 'INPUT') {
                  const inputType = element.type;
                  if (inputType === 'text' || inputType === 'number' || inputType === 'email' || inputType === 'tel'|| inputType === 'date') {
                      element.value = '';
                  }
              } else if (element.tagName === 'SELECT') {
                  const selectElement = element as HTMLSelectElement;
                  selectElement.selectedIndex = 0;
              } 
          }
      }
    }
}
