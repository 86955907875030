<div class="login slide">

  <div *ngIf="showAnimation">
    <app-animation></app-animation>
  </div>


  <div *ngIf="showLogin" [@fade]>


    <div class="container">
      <div class="row justify-content-center mt-5">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card shadow">
            <div class="card-title text-center border-bottom">
              <h2 class="p-3">Ingresar</h2>
            </div>
            <div class="card-body">
              <form (ngSubmit)="onSubmit()">
                <div class="mb-4">
                  <label for="username" class="form-label">Username/Pseudonimo</label>
                  <input type="text" placeholder="ingrese su usuario" class="form-control" name="username" id="username"
                    [(ngModel)]="username" />
                </div>
                <div class="mb-4">
                  <label for="password" class="form-label">Password</label>
                  <input type="password" class="form-control" placeholder="ingrese su contraseña" name="password"
                    id="password" [(ngModel)]="password" />
                </div>
                <div class="mb-4">
                  <input type="checkbox" class="form-check-input" id="remember" />
                  <label style="margin-left: 8px;" for="remember" class="form-label">Recuerdame</label>
                </div>
                <div class="d-grid">
                  <button type="submit" id="submit-bt" class="btn text-light main-bg">Ingresar</button>
                  <p *ngIf="loginError" class="text-danger mt-3">Usuario o contraseña invalido.</p>
                </div>
              </form>
            </div>

            

          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="showIngreso">
    <app-list-saludo></app-list-saludo>
  </div>
  
</div>