<div class="lista_sucursales" *ngIf="!permissions.hasOwnProperty('div_sucursal_listar')">
    <ng-container *ngFor="let dato of datos" >
        <div class="">
            <div class="row">
                <div  [class.ocults]="permissions.hasOwnProperty('btn_usuarios_sucursal_ver')"  class="col container_ver_sucursales">
                    <i (click)="ver_usuarios_de_sucursales(dato.id)" *ngIf="!permissions.hasOwnProperty('btn_usuarios_sucursal_ver')">
                        <h5 class="ver_usuarios_de_sucursal">Ver Usuarios</h5>
                    </i>
                </div>
                <div [class.ocults]="permissions.hasOwnProperty('btn_caja_sucursal_ver')" class="col container_ver_sucursales">
                    <i (click)="cash_views(dato.id, dato.id)" >
                        <h5 class="ver_usuarios_de_sucursal">Caja Sucursal</h5>
                    </i>
                </div>
            </div>
            
        </div>
        
        <div [ngClass]="{'list_info': selectedItems.includes(dato.name)}" style="margin-bottom: 20px;">
            <div class="contaner-list"  >
                <div style="width: 15%;" *ngIf="!permissions.hasOwnProperty('btn_ruta_ver')" >
                    <svg  (click)="mostrar_sucursal(dato)" class="back-ver rotate-45" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="34">
                        <path d="M0,23V16A9.01,9.01,0,0,1,9,7h4.83V5.414A2,2,0,0,1,17.244,4l5.88,5.879a3,3,0,0,1,0,4.242L17.244,20a2,2,0,0,1-3.414-1.414V17H8a6.006,6.006,0,0,0-6,6,1,1,0,0,1-2,0ZM15.83,8a1,1,0,0,1-1,1H9a7.008,7.008,0,0,0-7,7v1.714A7.984,7.984,0,0,1,8,15h6.83a1,1,0,0,1,1,1v2.586l5.879-5.879a1,1,0,0,0,0-1.414L15.83,5.414Z"/>
                    </svg>
                </div>
                <div style="width: 87%;">
                    <h5 class="nombre-sucursales" [attr.data-sucursal-name]="dato.name">{{ dato.name }}</h5>
                </div>
                <div style="width: 15%;">
                    <i class="back" (click)="verInfoSucursal(dato)" [ngClass]="{'rotate': dato.showInfo}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                    </i>
                </div>
            </div>
        <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr style="text-align: center;">
                        <th>Pais</th>
                        <th>Telefono</th>
                        <th>Moneda</th>
                        <th>Hora Inicial</th>
                        <th>Hora Final</th>
                        <th>Cantidad Rutas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{dato.country}}</td>
                        <td>{{dato.phone}}</td>
                        <td>{{dato.currency}}</td>
                        <td>{{dato.working_time}}</td>
                        <td>{{dato.date_finysh_day}}</td>
                        <td>{{dato.cantidad_rutas}}</td>
                    </tr>
                </tbody>
            </table>
           
            <p class="informacion_de_usuario">
                <button class="btns-delete marg-btn" *ngIf="!permissions.hasOwnProperty('btn_sucursal_delete')" (click)="borrar(dato)">
                    <i class="bi bi-trash"></i>
                </button>
                <button class="btns-agg marg-btn"  *ngIf="!permissions.hasOwnProperty('btn_sucursal_update')"  (click)="actualizar(dato)">
                    <i class="bi bi-pencil-square"></i>
                </button>
                


                
            </p>
        </div>
    </div>
    </ng-container>
</div>

