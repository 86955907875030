
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary" style="overflow-y: scroll;">
 
      <div class="card-body data-content">
        <h2>Modalidad</h2>
        <form autocomplete="off" class="form" name="miFormularioSucursal" (ngSubmit)="postModalidad()"  >
          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Frecuencia</label>
            <div class="col-lg-8 col-12 inputs">
                <select class="form-control input-form" id="frequency" name="frequency" size="0">
                    <option value="" disabled selected>--Seleccionar--</option>
                    <option value="Diario">Diario</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Mensual">Mensual</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Cantidad de cuotas</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="number" id="quotas_number" name="quotas_number" placeholder="Cantidad de cuotas">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Porcentaje</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="number" id="percentage"  inputmode="numeric" name="percentage" placeholder="Porcentaje">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Tope</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="number" id="limit_loan"  inputmode="numeric" name="limit_loan" placeholder="Tope">
            </div>
          </div>
          

          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs" style="margin-bottom: 190px !important;">
              <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
