import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-loan-refinance',
  templateUrl: './loan-refinance.component.html',
  styleUrls: ['./loan-refinance.component.css'],
})
export class LoanRefinanceComponent  implements OnInit {
  selectedDates: Date;
  frecuency_data = "";
  cantidadOpciones: number = 62;
  tope =  "";
  percentaje = "";
  monto = "";
  id_parm: any = 0;
  get_pays:boolean = true;
  constructor(public url: Url,private parames: ActivatedRoute, private router: Router) { 
    this.selectedDates = new Date();
  }

  ngOnInit() {
    this.getLoan();
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  value_cuota(){
    let monto = parseFloat((document.querySelector('#monto') as HTMLSelectElement).value);
    let cuotas = parseFloat((document.querySelector('#cuotas') as HTMLSelectElement).value);
    let porcentaje = parseFloat((document.querySelector('#percentage') as HTMLInputElement).value);
    let porcentaje_calucado = (porcentaje / 100)
    let value_total = (((monto * porcentaje_calucado) + monto) / cuotas).toFixed(2);
    if (parseInt((document.querySelector('#monto') as HTMLSelectElement).value) > parseInt(this.tope)){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error!',
        text: 'Valor mayor a lo disponible',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      })
    }
    if(!monto || !cuotas || !porcentaje){
      (document.querySelector('#value_total') as HTMLSelectElement).value = '0';
    }
    else{

      (document.querySelector('#value_total') as HTMLSelectElement).value = value_total;
    }
  }

  async getLoan(){
    const id = this.id_parm
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "prestamo/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      this.monto = jsonResponse.loan_details_id.outstanding_balance


      const response_pay = await fetch(this.url.Ms1 + "pago_loan/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "ngrok-skip-browser-warning": "69420"
        },
      });

      if(response_pay.ok){
        this.get_pays = false
      }

    }
  }

  modalidad_info: any[] = [];
 
  createRange(n: number): number[] {
    return Array.from({ length: n }, (_, index) => index);
  }

  async PostRefinance(){
   const ids = parseInt(this.id_parm);
   const token = this.getCookie("_token")
   const date_initial = (document.querySelector('#date_initial') as HTMLSelectElement).value;
   const monto = (document.querySelector('#monto') as HTMLSelectElement).value;
   const frecuency = (document.querySelector('#frecuency') as HTMLSelectElement).value;
   const porcentaje =  (document.querySelector('#percentage') as HTMLSelectElement).value;
   const cuotas = (document.querySelector('#cuotas') as HTMLSelectElement).value;
   const value_total = (document.querySelector('#value_total') as HTMLSelectElement).value;

  let date_final = ""
  if (frecuency == "Mensual"){
    const total_days = (30 * parseInt(cuotas))
    const initialDate = new Date(date_initial.split('/').reverse().join('/'));
    const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
    date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
  }
  if (frecuency == "Quincenal"){
    const total_days = (15 * parseInt(cuotas))
    const initialDate = new Date(date_initial.split('/').reverse().join('/'));
    const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
    date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
  }
  if (frecuency == "Semanal"){
    const total_days = (7 * parseInt(cuotas))
    const initialDate = new Date(date_initial.split('/').reverse().join('/'));
    const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
    date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
  }
  if (frecuency == "Diario"){
    const total_days = (1 * parseInt(cuotas))
    const initialDate = new Date(date_initial.split('/').reverse().join('/'));
    const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
    date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
  }
  if(parseInt(porcentaje) <= 0 || porcentaje == ''){
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Valor invalido en porcentaje',
      showConfirmButton: true,
      confirmButtonText: 'OK',  
      allowOutsideClick: false, 
      allowEscapeKey: false,
      confirmButtonColor: '#0e658e',
    })
    return
  }
  if(this.get_pays == true){
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Prestamo sin pagos realizados',
      showConfirmButton: true,
      confirmButtonText: 'OK',  
      allowOutsideClick: false, 
      allowEscapeKey: false,
      confirmButtonColor: '#0e658e',
    })
    return
  }
  const data = {
    start_date: date_initial,
    expiry_date: date_final,
    fee_payment: parseFloat(value_total).toFixed(2),
    total_installments: cuotas,
    frequency: frecuency,
    porcentaje: porcentaje,
    loan_id: ids,
  };

  try {
    const response = await fetch(this.url.Ms1 + "refinance", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Enviado!',
        text: 'Prestamo refinanciado',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      }).then((result) => {
        if(result.isConfirmed){
          const selectElement = document.getElementById("select-search") as HTMLSelectElement;
          selectElement.value = "Clientes de la ruta"; // Seleccionar la opción deseada
          selectElement.dispatchEvent(new Event('change'));
        }
      })
    }else{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Error!',
        text: 'Refinanciación falló',
        showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
      }).then((result) => {
       
      })
    }

  }catch (error) {
    console.error('Error al enviar el formulario', error);
  }


  }
}
