import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Url,Permission } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-list-verifika',
  templateUrl: './list-verifika.component.html',
  styleUrls: ['./list-verifika.component.css'],
})
export class ListVerifikaComponent  implements OnInit {

  constructor(public url: Url,private parames: ActivatedRoute,) { }

  datos: any = [];
  loans: any = [];
  showMenus = false;
  selectedOption: string | null = null; // Inicialmente no se ha seleccionado ninguna opción
  selectedMenus: any | null = null; 
  permissions: any ={}
  descuadreNegativo :any = 0;
  descuadrePositivo:any = 0;
  total_revis: any = 0;
  length_verifik:any = 0;
  total_custo:any = 0;
  async ngOnInit() {
    await this.getRevisions()
    this.permissions = Permission.ElementPermissionObjects()
    if(this.datos){
      const latestEntry = this.datos.reduce((max: { id: number }, entry: { id: number }) => {
        return entry.id > max.id ? entry : max;
      }, this.datos[0]);
      await this.listLoanVerifik(latestEntry)
    }
    const ruta_id = this.getCookie("_route")
    this.total_custo = await this.CountCustomer(ruta_id)
  }

  MostarSelect(){
    if(this.showMenus == false){
      this.showMenus = true
    }
    else{
      this.showMenus = false
    }
  }

 convertirFecha(fechaString:any) {
    const meses = [
      "enero", "febrero", "marzo", "abril", "mayo", "junio", 
      "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    
    const fecha = new Date(fechaString);
    const dia = fecha.getDate();
    const mes = fecha.getMonth();
    const año = fecha.getFullYear();
    const fechaFormateada = `${dia} - ${meses[mes]} - ${año}`;
    return fechaFormateada;
  }
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async getRevisions(){
    const token = this.getCookie("_token")
    const id = this.parames.snapshot.params['id'];
    const response = await fetch(this.url.Ms1 + "get_revisions/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
      if (response.ok) {
        const jsonResponse = await response.json();
        jsonResponse.sort((a:any, b:any) => b.revision - a.revision);
        this.datos = jsonResponse;
      }
  }
  
  async listLoanVerifik(dato:any){
    const token = this.getCookie("_token")
    let ids = dato.id
    const response = await fetch(this.url.Ms1 + "get_loans_revised/" + ids, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
      if (response.ok) {
        const jsonResponse = await response.json();
        this.loans = jsonResponse;
        this.length_verifik =  this.loans.length
      }
      this.selectedOption = "Revision " +  dato.revision + " " + this.convertirFecha(dato.date);
      this.selectedMenus = dato;
      this.showMenus = false
      this.descuadreNegativo = 0
      this.descuadrePositivo = 0
      this.loans.forEach((loan: any) => {
        const totalAdjust = parseFloat(loan.total_adjust);
        if (totalAdjust < 0) {
          this.descuadreNegativo += totalAdjust;
        }else{
          this.descuadrePositivo += totalAdjust;
        }
      })
  }

  NewRevicion(){
    Swal.fire({
      position: 'top-end',
      icon: 'info',
      title: 'Nueva verificacion',
      text: '¿Seguro quieres crear una nueva verificacion?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Guardar',  
      allowOutsideClick: false,
      allowEscapeKey: false,  
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = this.getCookie("_token")
        const id = this.parames.snapshot.params['id'];
        let bodys = {
          route_id: id
        }
        const response = await fetch(this.url.Ms1 + "new_revision", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            "ngrok-skip-browser-warning": "69420"
          },
          body: JSON.stringify(bodys)
        });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    })
   
    
  }
  
  Total_verific(descuadre:any,favor:any){
     let total = (descuadre) + (favor)
      if(total < 0){
        this.total_revis = total
        return "Descuadre"
      }
      else{
        this.total_revis = total
        return "A favor"
      }
  }
  async CountCustomer(ruta_id:any){
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "get_account_custom/" + ruta_id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse.total_customers
    }

  }
}
