import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LoginComponent {
  username = '';
  password = '';
  loginError = false;
  showLogin = false;
  showAnimation = true;
  showIngreso = false;
  introTl = gsap.timeline();

  constructor(private router: Router,public url: Url) {}

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  onSubmit() {
    const formdataUser = {
      "username": this.username,
      "password": this.password
    };

    const response = fetch(this.url.Ms1 + "login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formdataUser)
    })
    .then(ressponse => {
      if(ressponse.status != 200){
        throw new Error("Error en la respuesta del servidor");
      }
      return ressponse.json()
    })
    .then(rest =>{
      let role = rest.role.type
      const date = new Date();
      date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
      const expiresStr = "expires=" + date.toUTCString();
      document.cookie = "_token" + "=" + rest.access_token + ";" + expiresStr + ";path=/";
      document.cookie = "_rol" + "=" + role + ";" + expiresStr + ";path=/";
      document.cookie = "_id" + "=" + rest.user_id.id + ";" + expiresStr + ";path=/";
      document.cookie = "_name" + "=" + rest.name+ ";" + expiresStr + ";path=/";
      sessionStorage.setItem('permission',  JSON.stringify(rest.permissions));

      if("route" in rest){
        document.cookie = "_branch" + "=" + rest.route[0].routes_id.branch_id.id + ";" + expiresStr + ";path=/";
        document.cookie = "_inicio" + "=" + rest.route[0].routes_id.branch_id.working_time + ";" + expiresStr + ";path=/";
        document.cookie = "_final" + "=" + rest.route[0].routes_id.branch_id.date_finysh_day + ";" + expiresStr + ";path=/";
      }

      this.router.navigate(['/list-saludo']);
      this.showLogin = false;
    
    })
    .catch(error => {
      this.loginError = true;
    });
 
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showAnimation = false;
      this.showLogin = true;
    }, 5000);
     
    const token = this.getCookie("_token")
    if (token != null){
      this.showLogin = false;
      this.showIngreso = true;
      this.router.navigate(['/list-saludo']);
    }
    else{
      this.router.navigate(['']);
    }
  }
}

