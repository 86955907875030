
<div class="menu-container" style="position: relative;">

<app-menu-super-user></app-menu-super-user>

<div class="list-sucursa" >
  <h3 class="text-center">Nomina</h3>

  <div  *ngFor="let dato of nominas"  class="contaner-princi">
    <div class="contaner-list" >
      <div class="contener-datas" >
        <div style="width: 100%; text-align: start;">
          <div style="text-align: center; border-bottom: solid 2px #adb5bd59;width: 95%;margin: auto; height: 35px; overflow-x:scroll; white-space:nowrap;">
            <label for="" class="info-client" >{{dato.info_user.name}} / {{dato.info_user.role}}</label>
    
            <div *ngIf="dato.wage_last > 0" class="tag_red">
              <span class="tag_state"></span>
              <span class="tag_label">Pendientes {{dato.wage_last}}</span>
            </div>
          </div>

          <div style="display: block; overflow: hidden;overflow-x: auto;">

            <div class="row" style="text-align: center; display: table-cell;">
              <div style="border-right: solid 2px #adb5bd59; height: 24px; white-space: nowrap;">
                <p style="font-size: 15px;">{{Dias_payInfo(dato.range_to_date)}} dias</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59; height: 30px;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Pago</span>
              </div>
            </div>
            <div class="row" style="text-align: center; display: table-cell;">
              <div style="border-right: solid 2px #adb5bd59; height: 24px;">
                <p style="font-size: 15px;">${{dato.wages.salary| number:'1.2-2'}}</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59; height: 30px;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Sueldo</span>
              </div>
            </div>
            <div class="row" style="text-align: center; display: table-cell;">
              <div style="border-right: solid 2px #adb5bd59; height: 24px;">
                <p style="font-size: 15px;" *ngIf="dato.comision != 'no'">${{dato.comision | number:'1.2-2'}}</p>
                <p style="font-size: 15px;" *ngIf="dato.comision == 'no'">NO</p>
              </div>
              <div style="border-right: solid 2px #adb5bd59; height: 30px;">
                <span style="font-size: 16px; font-weight: 600; display: block;">Comision</span>
              </div>
            </div>

            <div class="row" style="text-align: center; display: table-cell;" *ngIf="dato.goals != ''">
              <div style="height: 14px;">
                <p style="font-size: 15px;">
                  Bonos
            
                </p>
              </div>
              <div style="border-right: solid 2px #adb5bd59; height: 30px;">
                <button class="btn-agend bnt-loans-pago" (click)="PostBonos(dato)">
                  Ver
                </button>
                
              </div>
            </div>



            <div class="row" style="text-align: center; display: table-cell;">
              <div style="height: 14px;">
                <p style="font-size: 15px;" *ngIf="dato.total_payment">${{dato.total_payments | number:'1.2-2'}}</p>
                <p style="font-size: 15px;" *ngIf="!dato.total_payment">${{dato.total_payments | number:'1.2-2'}}</p>
              </div>
              <div style="height: 30px;">
                <button id="buttonPay" class="btn-agend bnt-loans-pago" [style.display]="verificarFechas(dato.range_to_date, dato.today) ? 'block' : 'none'" (click)="PostNominas(dato)" *ngIf="!permissions.hasOwnProperty('btn_pagar_nomina')">Pagar</button>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
