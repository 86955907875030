import { Component } from '@angular/core';

@Component({
  selector: 'app-perfil-superuser',
  templateUrl: './perfil-superuser.component.html',
  styleUrls: ['./perfil-superuser.component.css']
})
export class PerfilSuperuserComponent {
  mostrarDiv1 = true;
  mostrarDiv2 = false;
  mostrarDiv3 = false;
  
  restaurar_password(){
    this.mostrarDiv1 = false;
    this.mostrarDiv2 = true;
    this.mostrarDiv3 = false;
  }

  enviarFormulario() {
    this.mostrarDiv1 = false;
    this.mostrarDiv2 = false;
    this.mostrarDiv3 = true;
  }
}
