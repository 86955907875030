<div class="card-body data-content">
  <h1 class="title" *ngIf="esEdicion">Actualizar</h1>
<form autocomplete="off" class="form" name="miFormularioRuta" [formGroup]="miFormularioRuta" (ngSubmit)="enviarFormularioUser()" #formularioUser="ngForm">
 
  <div class="form-group row">
    
    <label class="col-lg-3 col-form-label form-control-label">Nombre ruta</label>
    <div class="col-lg-8 col-12 inputs">
      <input class="form-control input-form form-county" maxlength="25" id="name_route" name="name_route"  type="text" (blur)="convertToUppercase('name_route')"  placeholder="Nombre ruta" formControlName="name_route">
    </div>
  </div>
  <div class="form-group row" style="display: none;">
    <label class="col-lg-3 col-form-label form-control-label">Nombre caja</label>
    <div class="col-lg-8 col-12 inputs">
      <input class="form-control input-form form-county" type="text" id="name_cash_flow" name="name_cash_flow"  (blur)="convertToUppercase('name_cash_flow')" placeholder="Nombre caja" formControlName="name_cash_flow">
    </div>
  </div>


  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Sucursal</label>
    <div class="col-lg-8 col-12 inputs">
      <select name="sucursal_id" id="sucursal_id" class="form-control input-form form-county"  formControlName="sucursal_id">
        <option value="" selected disabled>--Seleccionar--</option>
        <option *ngFor="let sucursal of datos" [value]="sucursal.id">{{ sucursal.name }}</option>
      </select>
      
    </div>
  </div>


  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Zona horaria:</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <img src="" alt="">
        </span>
      </div>
      <select class="form-control input-form"  id="user_time_zone" size="0" formControlName="horario" (change)="fomrZone()">
        <option value="" selected disabled>
          --Elige--
        </option>
        <option value="America/Sao_Paulo">
          (GMT-03:00) Brasilia
        </option>
        <option value="America/Argentina/Buenos_Aires">
          (GMT-03:00) Argentina
        </option>
        <option value="America/Argentina/Buenos_Aires">
            (GMT-03:00) Buenos Aires
        </option>
        <option value="America/Sao_Paulo">
            (GMT-03:00) Sao Paulo
        </option>
        <option value="America/Santiago">
            (GMT-04:00) Santiago
        </option>
        <option value="America/Bogota">
          (GMT-05:00) Ecuador
        </option>
        <option value="America/Bogota">
            (GMT-05:00) Bogotá
        </option>
        <option value="America/Lima">
            (GMT-05:00) Lima
        </option>
        <option value="America/Mexico_City">
          (GMT-05:00) Mexico
      </option>
      <option value="America/Panama">
          (GMT-05:00) Panamá
      </option>
      <option value="America/Manaus">
          (GMT-04:00) Manaus
      </option>
        <option value="America/Caracas">
            (GMT-04:00) Caracas
        </option>
        <option value="America/Guyana">
            (GMT-04:00) Guyana
        </option>
        <option value="America/Asuncion">
            (GMT-04:00) Asunción
        </option>
        <option value="America/La_Paz">
            (GMT-04:00) La Paz
        </option>
        <option value="America/Montevideo">
            (GMT-03:00) Montevideo
        </option>
        <option value="America/Cayenne">
            (GMT-03:00) Cayenne
        </option>
        <option value="America/Paramaribo">
            (GMT-03:00) Paramaribo
        </option>
        <option value="America/Fortaleza">
            (GMT-03:00) Fortaleza
        </option>
    </select>
    </div>
  </div>

  <div id="cont_indi" class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Indicativo</label>
    <div class="col-lg-8 col-12 inputs">
      <input class="form-control input-form form-county" type="number" id="indit_br" name="indit_br"  inputmode="numeric" placeholder="Indicativo" maxlength="2">      
    </div>
  </div>


  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Ciudad</label>
    <div class="col-lg-8 col-12 inputs">
      <input class="form-control input-form form-county" type="text" id="city" name="city" placeholder="Ciudad" formControlName="city">      
    </div>
  </div>

  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label">Dias festivos</label>
    <div class="col-lg-8 col-12 inputs">
      <select name="day_holidays" id="day_holidays" class="form-control input-form form-county"  formControlName="day_holidays">
        <option value="" selected disabled>--Seleccionar--</option>
        <option *ngFor="let datos of festivos" [value]="datos.id">{{ datos.name }}</option>
      </select>
      
    </div>
  </div>
  
  <div class="form-group row" *ngIf="esEdicion">
    <label class="col-lg-3 col-form-label form-control-label">Usuarios</label>
    <div class="col-lg-8 col-12 inputs">
      <input id="user_list" name="user_list" class="form-control input-form form-county" type="text" placeholder="Usuarios" [value]="listUserSelect()" (click)="ListUserEdit()" *ngIf="!permissions.hasOwnProperty('btn_usuarios_ruta_editar')">
    </div>
  </div>
  
  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label"></label>
    <div class="col-lg-8 col-12 inputs">
      <input class="btn btn-primary subtmit-bt" *ngIf="!esEdicion" type="submit" value="Guardar" id="enviar_rutas">
      <button *ngIf="esEdicion" type="button" (click)="actualizarFormularioRuta(formularioUser.value)" class="save">Actualizar</button>
    </div>
  </div>
</form>
</div>