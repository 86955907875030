

<div class="menu-container">
  <app-menu-super-user></app-menu-super-user>
  <br>
<div class="card card-outline-secondary">
 
    <div class="card-body data-content">
        <h1 class="title">Crear pagos</h1>
    <form autocomplete="off" class="form" name="miFormularioUser"  #formularioUser="ngForm" (ngSubmit)="postLoan()">
      <div class="form-group row" >
        <label class="col-lg-3 col-form-label form-control-label">Tipo de pago</label>
        <div class="col-lg-8 col-12 inputs">
          <select class="form-control input-form"  name="type_payment" id="type_payment">
            <option value="">--Seleccionar--</option>
            <option value="Meta">Meta</option>
            <option value="Nomina">Nomina</option>
          </select>
        </div>
      </div> 

      <div class="form-group row" >
        <label class="col-lg-3 col-form-label form-control-label">Usuario</label>
        <div class="col-lg-8 col-12 inputs">
          <div class="dropdown">
            <div class="form-control input-form" (click)="MostarSelect()">
              {{ selectedOption ? selectedOption : 'Selecciona una opcion' }}
            </div>
            <div [class.dropdown-menu-show]="!showMenus" class="dropdown-menu">
              <input id="search_inpt" class="dropdown-menu-search" type="text"  placeholder="Buscar..." (input)="search()">
              <div class="dropdown-menu-inner">
                <div class="dropdown-menu-item" *ngFor="let item of filteredItems"  [attr.data-value]="item.id" [ngClass]="{ 'selected': item === selectedMenus }" (click)="selectOption(item)">{{ item.name }} - {{item.id}}</div>
              </div>
            </div>
          </div>
              
        </div>
      </div> 

      <div class="form-group row">
        <label class="col-lg-3 col-form-label form-control-label">Valor pagado</label>
          <div class="col-lg-8 col-12 inputs">
            <input disabled type="number" id="monto" name="monto"  inputmode="numeric"  class="form-control input-form"  placeholder="Valor a prestar"  [(ngModel)]="value_pago">
            
          </div>
      </div>

      <div class="form-group row">
        <label class="col-lg-3 col-form-label form-control-label">Fecha de pago</label>
        <div class="col-lg-8 col-12 inputs">
          <input disabled placeholder="Fecha Inicial" type="text" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
        </div>
      </div>


      <div class="form-group row" style="margin-bottom: 90px !important;">
        <label class="col-lg-3 col-form-label form-control-label"></label>
        <div class="col-lg-8 col-12 inputs">
          <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
        </div>
      </div>
      
      </form>
    </div>
  </div>
</div>
