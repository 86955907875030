
<div class="menu-container" style="overflow-y: scroll;">
<app-menu-super-user></app-menu-super-user>

  <br>
 <h2>Lista Agendar prestamos</h2>
  <div class="card-body data-content" style="display: block;">

    <div class="princila-contes" *ngFor="let dato of datos">
        <h4>Cliente: {{dato.customers_id.name}}</h4>
        <p style="font-size: 13px; ">
            <b>
                Direccion: 
            </b>
            {{dato.customers_id.customer_detail_id.address}}
        <br>
            <b>
                Fecha para el prestamo:
            </b>
            {{dato.date|date:"dd-MM-yyyy" }}
        <br>
            <b>
                Valor:                
            </b>
            $ {{dato.value|number:'1.2-2'}}
        <br><br>
            <button class="btn btsn-adds subtmit-bt" (click)="addLoan(dato)">Crear prestamo</button>
            <button class="btn btsn-remove subtmit-bt" (click)="removeAgendar(dato.id)">Rechazar</button>
        </p>
        
    </div>
  </div>

</div>