import { Component, OnInit } from '@angular/core';
import { Url, Permission } from '../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-list-payment-monima',
  templateUrl: './list-payment-monima.component.html',
  styleUrls: ['./list-payment-monima.component.css'],
})
export class ListPaymentMonimaComponent  implements OnInit {


  selectedDates: Date;
  selectedFinal: Date;
  constructor(private routers: Router, private route: ActivatedRoute,public url: Url,private decimalPipe: DecimalPipe) {
    this.selectedDates = new Date();
    this.selectedFinal = new Date();
  }
  nominas: any = [];

  ngOnInit() {
    this.getHistoryWages();
  }

  formatDate(dateString:any) {
    const date = new Date(dateString);
    const day = String(date.getDate() + 1).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  async getHistoryWages(): Promise<any> {
    let date = new Date(this.selectedDates)
    let finish = new Date(this.selectedFinal)
    let formattedDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    let formattedfinish = finish.getFullYear() + '-' + String(finish.getMonth() + 1).padStart(2, '0') + '-' + String(finish.getDate()).padStart(2, '0');
    const token = this.getCookie("_token")
    const id = parseInt(this.route.snapshot.params['id']);
    const response = await fetch(this.url.Ms1 + "history_wages/" + id + "?initial=" + formattedDate + "&finish=" + formattedfinish, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.nominas = jsonResponse
         return this.nominas
     }
}

search(){
  this.getHistoryWages()
}


}
