
<form autocomplete="off" class="form" name="miFormularioUser" #formularioUser="ngForm" (ngSubmit)="postImages()">
  <div class="form-group row" style="margin-top: 10px;">
    <div [class.inputs-imgs]="!img_1" [class.inputs-imgs2]="img_1" class="col-lg-8 col-12 ">
      <label for="" style="width: 58%; ">Cedula Frontal</label>
      <div [class.cont-img]="!img_1" [class.another-class]="img_1">
        <img class="img-previes" [src]="preview_1" alt="" *ngIf="img_1">
        <button type="button" *ngIf="img_1" class="kv-file-remove btn-removes" title="Remove file"
          (click)="removeImg1()">
          <i class="fa">x</i>
        </button>
        <i class="fa icos-ad" *ngIf="!img_1">+</i>
        <input id="images1" name="images1" [class.input-otro]="!img_1" [class.input-otro-disable]="img_1" type="file"
          accept="image/*" capture="environment" (change)="frontal($event)">
      </div>

    </div>
  </div>

  <!-- CARNET POSTERIOR -->
  <div class="form-group row">
    <div [class.inputs-imgs]="!img_2" [class.inputs-imgs2]="img_2" class="col-lg-8 col-12 ">
      <label for="" style="width: 58%; ">Cedula Posterior</label>
      <div [class.cont-img]="!img_2" [class.another-class]="img_2">
        <img class="img-previes" [src]="preview_2" alt="" *ngIf="img_2">
        <button type="button" *ngIf="img_2" class="kv-file-remove btn-removes" title="Remove file"
          (click)="removeImg2()">
          <i class="fa">x</i>
        </button>
        <i class="fa icos-ad" *ngIf="!img_2">+</i>
        <input id="images2" name="images2" [class.input-otro]="!img_2" [class.input-otro-disable]="img_2" type="file"
          accept="image/*" capture="environment" (change)="posterior($event)">
      </div>
    </div>
  </div>

  <!-- FOTO CLIENTE -->
  <div class="form-group row">
    <div [class.inputs-imgs]="!img_3" [class.inputs-imgs2]="img_3" class="col-lg-8 col-12 ">
      <label for="" style="width: 58%; ">Foto del cliente</label>
      <div [class.cont-img]="!img_3" [class.another-class]="img_3">
        <img class="img-previes" [src]="preview_3" alt="" *ngIf="img_3">
        <button type="button" *ngIf="img_3" class="kv-file-remove btn-removes" title="Remove file"
          (click)="removeImg3()">
          <i class="fa">x</i>
        </button>
        <i class="fa icos-ad" *ngIf="!img_3">+</i>
        <input id="images3" name="images3" [class.input-otro]="!img_3" [class.input-otro-disable]="img_3" type="file"
          accept="image/*" capture="environment" (change)="imagencliente($event)">
      </div>
    </div>
  </div>


  
  <!-- FOTO NEGOCIO -->
  <div class="form-group row">
    <div [class.inputs-imgs]="!img_4" [class.inputs-imgs2]="img_4" class="col-lg-8 col-12 ">
      <label for="" style="width: 58%; ">Foto del Negocio</label>
      <div [class.cont-img]="!img_4" [class.another-class]="img_4">
        <img class="img-previes" [src]="preview_4" alt="" *ngIf="img_4">
        <button type="button" *ngIf="img_4" class="kv-file-remove btn-removes" title="Remove file"
          (click)="removeImg4()">
          <i class="fa">x</i>
        </button>
        <i class="fa icos-ad" *ngIf="!img_4">+</i>
        <input id="images4" name="images4" [class.input-otro]="!img_4" [class.input-otro-disable]="img_4" type="file"
          accept="image/*" capture="environment" (change)="comercio($event)">
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-lg-3 col-form-label form-control-label"></label>
    <div class="col-lg-8 col-12 inputs">
      <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
    </div>
  </div>
</form>
