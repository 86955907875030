import { Component, OnInit } from '@angular/core';
import { Url, Permission } from '../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-form-gruop-nomina',
  templateUrl: './form-gruop-nomina.component.html',
  styleUrls: ['./form-gruop-nomina.component.css'],
})
export class FormGruopNominaComponent  implements OnInit {

  nominas: any[] = [];
  Dias_pay: number = 0;
  permissions: any ={}
  constructor(private routers: Router, private route: ActivatedRoute,public url: Url,private decimalPipe: DecimalPipe,) { }
  
  ngOnInit() {
    this.get_nomina()
    this.permissions = Permission.ElementPermissionObjects()
  }
   
  formateaMiles(numero: any) {
    return this.decimalPipe.transform(numero, '1.2-2');
  }
 
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  verificarFechas(date_1: string, date_2: string): boolean {
    const date_1_ = new Date(date_1);
    const date_2_ = new Date(date_2);
    if (date_1_.getTime() <= date_2_.getTime()) {
      return true;
    }
    return false;
  }

  async get_nomina(): Promise<any> {
    const token = this.getCookie("_token")
    const id = parseInt(this.route.snapshot.params['id']);
    const response = await fetch(this.url.Ms1 + "get_nomina_route/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.nominas = jsonResponse;
         return this.nominas;
     }
  }

  Dias_payInfo(info: any){
    const rangeToDate: string = info;
    const fechaDada: Date = new Date(rangeToDate);
    const fechaActual: Date = new Date();
    const diferenciaMilisegundos: number = fechaActual.getTime() - fechaDada.getTime();
    const diferenciaDias: number = Math.floor(diferenciaMilisegundos / (1000 * 3600 * 24));
    this.Dias_pay = diferenciaDias
    return this.Dias_pay 
  }

  PostNominas(dato:any){

    if (Number.isInteger(parseInt(dato.comision))) {
      dato.comision=dato.comision;
    } else {
        dato.comision=0;
    }
    let total_payment = parseInt(dato.wages.salary) + parseInt(dato.comision)
    const token = this.getCookie("_token")
    const sucursal = this.getCookie("_branch")
    let htmlContent = `
      <label style="font-weight: 600;font-size: 21px;">Usuario</label>
      <input class="form-control input-form" type="text" value="${dato.info_user.name}" >
      <label style="font-weight: 600;font-size: 21px;">Valor a pagar</label>
      <input disabled class="form-control input-form" type="text" value="$ ${this.formateaMiles(total_payment)}" >
      <label style="font-weight: 600;font-size: 21px;">Tipo de pago</label>
      <select class="form-control input-form" id="select_payment" name="select_payment">
        <option selected disabled value="">--Seleccionar--</option>
        <option value="True">Transferencia (Sucursal)</option>
        <option value="False">Efectivo (Ruta)</option>

      </select>
    `;

    Swal.fire({
      title: "Pago nomina", 
      html: htmlContent,  
      confirmButtonText: "Pagar", 
      showCloseButton: true,
      confirmButtonColor: '#0e658e',
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let tipo_pago = (document.getElementById("select_payment") as HTMLSelectElement).value
        let booleanValue;
        if (tipo_pago == ""){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Llena todos los campos...',
            showConfirmButton: false,
            timer: 3600
          });
          return;
        }
        if (tipo_pago === 'True') {
          booleanValue = true;
        } else if (tipo_pago === 'False') {
          booleanValue = false;
        } else {
          // Si el valor no es ni 'True' ni 'False'
          booleanValue = null; // O puedes manejarlo de otra manera
        }
        let data = {
          user_id:dato.user_id,
          salary_id: dato.id,
          cash_id: String(dato.cash_flow_id),
          value_motion: total_payment,
          transfer: booleanValue,
          sucursal_id: sucursal,
          route_id:dato.route_id
        }
        const response = await fetch(this.url.Ms1 + "pay_nomina", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(data)
        });
        if (response.ok) {

          Swal.fire({
            icon: 'success',
            title: 'Pago realizado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }
      }
    })

  }


  async pagarBono(button:any) {
    let htmlcontest =  `
    <label for="">Tipo de pago</label>
    <select class="form-control input-form" name="select_payment" id="select_payment">
        <option selected disabled value="">--Seleccionar--</option>
        <option value="True">Transferencia (Sucursal)</option>
        <option value="False">Efectivo (Ruta)</option>
    </select>
    `
    Swal.fire({
      title: "Pago bonos", 
      showCloseButton: true,
      html:htmlcontest,
      confirmButtonText: "Pagar", 
      confirmButtonColor: '#0e658e',
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let tipo_pago = (document.getElementById("select_payment") as HTMLSelectElement).value
        let booleanValue;
        if(tipo_pago == "" || tipo_pago == "--Seleccionar--"){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Llena todos los campos...',
            showConfirmButton: false,
            timer: 3600
          });
          return;
        }
        if (tipo_pago === 'True') {
          booleanValue = true;
        } else if (tipo_pago === 'False') {
          booleanValue = false;
        } else {
          // Si el valor no es ni 'True' ni 'False'
          booleanValue = null; // O puedes manejarlo de otra manera
        }
      
      const token = this.getCookie("_token")
      const sucursal = this.getCookie("_branch")

      const userId = button.dataset.userId;
      const bonusId = button.dataset.goalId;
      const cashId = button.dataset.cashId;
      const totalPayable = button.dataset.totalPayable;
      const routeId = button.dataset.routeId;

      const data = {
        user_id: userId,
        bonus_id: bonusId,
        cash_id: cashId,
        value_motion: totalPayable,
        transfer: booleanValue,
        sucursal_id:sucursal,
        route_id:routeId
      };

      const response = await fetch(this.url.Ms1 + "pay_goals", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
  
        Swal.fire({
          icon: 'success',
          title: 'Pago realizado',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
  
    }

    })
 

};


formatDate(dateString:any) {
  const date = new Date(dateString);
  const day = String(date.getDate() + 1).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

PostBonos(dato: any) {
  const token = this.getCookie("_token");
  let htmlContent = '';
  let buttonCounter = 1; // Variable para contar los botones y asignarles IDs únicos

  dato.goals.forEach((goal: any) => {
    const fechaOriginal = new Date(goal.next_payment);

    const dia = fechaOriginal.getDate();
    const mes = fechaOriginal.getMonth() + 1; 
    const anio = fechaOriginal.getFullYear().toString().slice(-2);

    const diaFormateado = (dia < 10) ? `0${dia}` : dia;
    const mesFormateado = (mes < 10) ? `0${mes}` : mes;

    const fechaFormateada = `${diaFormateado}-${mesFormateado}-${anio}`;

    const payable = goal.payable ? '' : 'display: none;';
    const uniqueId = `eliminarButton_${buttonCounter}`; // ID único basado en el contador
    htmlContent += `
    <style>
      .eliminarButton{
        padding: 6px 14px;
        border-radius: 4px;
        font-size: 15px;
        border: none;
        color: #fff;
        background-color: #0e658e;
      }
      .swal2-html-container{
        overflow-x:scroll;
      }
      
  .swal2-content{
    padding: 0 !important;
  }
    </style>
    <table class="table table-striped">
      <thead>
        <th>Nombre</th>
        <th>Valor</th>
        <th>Proximo pago</th>
        <th>Pendiente</th>
        <th>Accion</th>
      </thead>
      <tbody>
        <tr>
          <td>${goal.name}</td>
          <td>$${this.formateaMiles(goal.total_payable)}</td>
          <td>
          ${goal.frequency}
          ${this.formatDate(goal.to_date)}

          </td>
          `;
          if(goal.bonus_last > 0){
            htmlContent += `
                <td style="color: red;">
                  Pendiente ${goal.bonus_last}
                </td>
             `;
          }
          else{
            htmlContent += `
                <td style="color: green;">
                  Sin pendiente
                </td>
            `;
          }
         
          
          if(!this.permissions.hasOwnProperty('btn_pagar_bonos')){
            htmlContent += `
             <td>
               <button class="eliminarButton" style="${payable}" id="${uniqueId}" data-user-id=${dato.user_id} data-goal-id=${goal.id} data-cash-id=${dato.cash_flow_id} data-total-payable=${goal.total_payable} data-route-id=${goal.route_id}>Pagar</button>
              </td>
            `;
          }
          htmlContent += `
          
        </tr>
      </tbody>
    </table>`;
    
    buttonCounter++; // Incrementar el contador para el próximo botón
  });
  
  Swal.fire({
    title: 'Pago Bonos',
    showCloseButton: true,
    html: htmlContent,
    showConfirmButton: false,
    onOpen: () => {
      let currentButton = 1;
      dato.goals.forEach(() => {
        const uniqueId = `eliminarButton_${currentButton}`;
        const button = document.getElementById(uniqueId);
        if (button) {
          button.addEventListener('click', () => {
            this.pagarBono(button);
          });
        }
        currentButton++;
      });
    }
  });
}
}
