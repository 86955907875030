import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css'],
})
export class UpdateClientComponent  implements OnInit {

  constructor(public url: Url,) { }
  dato : any;
  modal: any;
  names: any;
  surname: any;
  img1: any = "https://via.placeholder.com/250";
  img2: any = "https://via.placeholder.com/250";
  img3: any = "https://via.placeholder.com/250";
  img4: any = "https://via.placeholder.com/250";
  dni:  any;
  permissions: any ={}

  ngOnInit() {
    this.permissions = Permission.ElementPermissionObjects()
    if(this.dato){
      if("customer_detail_id" in this.dato){
        this.names = this.dato.name
        this.dni = this.dato.id
        this.surname = this.dato.customer_detail_id.surname
        this.imagenList(this.dato.customer_detail_id.id)
      }
      else{
        this.names = this.dato.customers_id.name
        this.dni = this.dato.customers_id.id
        this.surname = this.dato.customers_id.customer_detail_id.surname
        this.imagenList(this.dato.customers_id.customer_detail_id.id)
      }

    }
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  public async imagenList(ids: any){
    
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "image_customer/" + ids, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420"
        },
      });
      if (response.ok) {
        const jsonResponse = await response.json();
        
        jsonResponse.forEach((element:any) => {
          if(element.type_image == "carnet frontal" || element.type_image == "img1"){
            const imageUrl = this.url.Ms1 + `image_view/${element.image}`;
            let img = document.getElementById("frontal") as HTMLImageElement;
            if(img){
                this.img2 = imageUrl
                img.src = imageUrl;
            }
          }

          if(element.type_image == "carnet posterior" || element.type_image == "img2"){
            const imageUrl = this.url.Ms1 + `image_view/${element.image}`;
            let img = document.getElementById("posterior") as HTMLImageElement;
            if(img){
                this.img3 = imageUrl
                img.src = imageUrl;
            }
          }
          if(element.type_image == "imagen cliente" || element.type_image == "img3"){
            const imageUrl = this.url.Ms1 + `image_view/${element.image}`;
            let img = document.getElementById("selfi") as HTMLImageElement;
            if(img){
                this.img4 = imageUrl
                img.src = imageUrl;
            }
          }

          if(element.type_image == "imagen negocio" || element.type_image == "img4"){
            const imageUrl = this.url.Ms1 + `image_view/${element.image}`;
            let img = document.getElementById("negocio") as HTMLImageElement;
            if(img){
                this.img1 = imageUrl
                img.src = imageUrl;
            }
          }
        });
      }
  }
  triggerFileInput(input_id:any) {
    const fileInput = document.getElementById(input_id) as HTMLInputElement;
    const preview = document.getElementById('img-ad') as HTMLInputElement;
    fileInput.click();
    if(fileInput){
      fileInput.addEventListener('change', function() {
        const file = this.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            let img_base = e.target.result
            if (preview) {
              preview.src = img_base
            }
          };
          reader.readAsDataURL(file);
        }
        else {
          if (preview) {
            preview.innerHTML = 'No image selected';
          }
        }
      })
    }
 
  }
    
  openImg(title:any, input_id:any, imgs:any, type: any){
    if(!this.permissions.hasOwnProperty('btn_cliente_editar_imagen')){
      const token = this.getCookie("_token")
      const html_c = `
          
          <img style="border-radius:10px; margin-top:20px; width: 250px; height: 250px;" src="${imgs}" id="img-ad" alt="Imagen 1">
          <input type="file" id="${input_id}" style="display: none;"/>
      `;
      
      Swal.fire({
        title: title,
        html: html_c,
        showCloseButton: true,
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#0e658e',
        onOpen: () => {
          let img = document.getElementById("img-ad")
          if (img) {
            img.addEventListener("click", this.triggerFileInput.bind(this, input_id));
          }
        }
    }).then(async (result) =>{
      if(result.isConfirmed){
        const image_up = (document.getElementById(input_id) as HTMLInputElement).files;
        const formData = new FormData();
        if(image_up){
          formData.append('img', image_up[0]);
        }
        formData.append('img_id', type)
        formData.append('customer_id', this.dni)

        const response = await fetch(this.url.Ms1 + "save_image", {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
          },
          body: formData
        })
        if(response.ok){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Modificado!',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e'
          })

        }

      }


      
    });
    }else{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'No tiene permisos editar imagenes',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return
    }
    
   
    
     
  }


}
