

<div class="menu-container">
  <app-menu-super-user></app-menu-super-user>
  <br>
<div class="card card-outline-secondary">
 
    <div class="card-body data-content">
        <h1 class="title" *ngIf="!esEdicion">Usuario</h1>
        <h1 class="title" *ngIf="esEdicion">Actualizar</h1>
    <form autocomplete="off" class="form" name="miFormularioUser" [formGroup]="miFormularioUser" (ngSubmit)="enviarFormularioUser()" #formularioUser="ngForm">
       
        <div class="form-group row"  *ngIf="!esEdicion">
          <label class="col-lg-3 col-form-label form-control-label">Documento de identidad</label>
          <div class="col-lg-8 col-12 inputs">
            <input class="form-control input-form input-format" id="id" name="id"  type="number" maxlength="20"  inputmode="numeric" placeholder="Cedula" formControlName="cedula">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Pseudonimo</label>
          <div class="col-lg-8 col-12 inputs">
            <input class="form-control input-form" type="text" id="seudonimo" name="seudonimo" placeholder="Pseudonimo" formControlName="seudonimo">
          </div>
        </div>
        <div class="form-group row"  *ngIf="!esEdicion">
          <label class="col-lg-3 col-form-label form-control-label">Contraseña</label>
          <div class="col-lg-8 col-12 inputs">
            <input class="form-control input-form " *ngIf="!esEdicion" type="password" id="password1" name="password1"  placeholder="Contraseña">
          </div>
        </div>
        <div class="form-group row"  *ngIf="!esEdicion">
            <label class="col-lg-3 col-form-label form-control-label">Confirmar contraseña</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" *ngIf="!esEdicion" type="password" id="password2" name="password2"  placeholder="Confirmar contraseña">
            </div>
          </div>
        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Ciudad</label>
          <div class="col-lg-8 col-12 inputs">
            <input class="form-control input-form form-county input-format" type="text" (blur)="convertToUppercase('ciudad')" id="ciudad" name="ciudad" placeholder="Ciudad" formControlName="ciudad">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Nombre completo</label>
          <div class="col-lg-8 col-12 inputs">
            <input type="text"  class="form-control input-form form-county input-format" id="nombre_user" name="nombre_user" (blur)="convertToUppercase('nombre_user')"  placeholder="Nombre completo" formControlName="nombre_user">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-3 col-form-label form-control-label">Numero</label>
          <div class="col-lg-8 col-12 inputs">
            <input id="numero" name="numero" class="form-control input-form input-format" type="number" maxlength="11" inputmode="numeric" placeholder="numero" formControlName="numero">
          </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Direccion</label>
            <div class="col-lg-8 col-12 inputs">
              <input id="direccion" name="direccion" class="form-control input-form form-county input-format" type="text" placeholder="direccion"  (blur)="convertToUppercase('direccion')"  formControlName="direccion">
            </div>
          </div>

          
          <div class="form-group row" >
            <label class="col-lg-3 col-form-label form-control-label">Sucursal</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form form-county input-format" id="sucursal_id" size="0" (change)="onSucursalChange($event)" formControlName="sucursal_id" >
                  <option value="" disabled selected>--Selecionar--</option>
                  <option *ngFor="let sucursal of datos_sucursal" [value]="sucursal.id">{{sucursal.name}}</option>
                </select>
            </div>
          </div>
   

          <div class="form-group row" *ngIf="!esEdicion">
            <label class="col-lg-3 col-form-label form-control-label">Rol Usuario</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form form-county input-format" id="user_time_zone" size="0">
                  <option value="" disabled selected>--Selecionar--</option>
                  <option value="3" name="roles">Socio</option>
                  <option value="5" name="roles">Cobrador</option>
                  <option value="4" name="roles">Supervisor</option>
                  <option value="2" name="roles">Administrador</option>
                </select>
            </div>
          </div>

          <div class="form-group row" >
            <label class="col-lg-3 col-form-label form-control-label">Grupo Nomina</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form form-county input-format" id="wages_id" name="wages_id" size="0" formControlName="wages_id">
                  <option value="">--Selecionar--</option>
                  <option *ngFor="let wages of datos_wages" [value]="wages.id">{{ wages.details }}</option>
                </select>
            </div>
          </div>




          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Rutas</label>
            <div class="container-select">
              <div class="select-btn" #selectBtn>
                <span class="btn-text" #btnText>Rutas</span>
              </div>

              <ul  id="routes_check" class="list-items" #itemsContainer>
                <li class="item-monto" *ngFor="let routes of datos_routes"  #itemMonto
                [ngClass]="{'checked': selectedRouteId.includes(routes.id)}" (click)="click_checks($event)">
                  <span class="checkbox">
                    <i class="fa-solid fa-check check-icon"></i>
                  </span>
                  <span class="item-text" [attr.data-value]="routes.id">{{ routes.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <br>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Plan de cuentas</label>
            <div class="container-select">
              <div class="select-btn" #selectBtn2>
                <span class="btn-text2" #btnText2>Conceptos</span>
              </div>

              <ul class="list-items" #itemsContainer2>
                <li  class="item-monto" (click)="click_checks_Total($event)">
                  <span class="checkbox">
                    <i class="fa-solid fa-check check-icon"></i>
                  </span>
                  Todos
                </li>
            
                <li class="item-monto monto-sele" *ngFor="let routes of datos_mode"  #itemMonto
                [ngClass]="{'checked2': selectedConceptId.includes(routes.income_expense_configuration_id.id)}"  (click)="click_checks2($event)">
                  <span class="checkbox">
                    <i class="fa-solid fa-check check-icon"></i>
                  </span>
                  <span class="item-text2" [attr.data-value]="routes.income_expense_configuration_id.id">{{ routes.income_expense_configuration_id.name }}</span>
                </li>
              </ul>

              
            </div>
          </div>

          
        <div class="form-group row" style="margin-bottom: 160px !important;">
          <label class="col-lg-3 col-form-label form-control-label"></label>
          <div class="col-lg-8 col-12 inputs">
            <input class="btn btn-primary subtmit-bt" *ngIf="!esEdicion" type="submit" value="Guardar">
            <button *ngIf="esEdicion" type="button" (click)="actualizarFormularioUser(formularioUser.value)" class="save">Actualizar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
