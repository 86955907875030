import { Component, EventEmitter, OnInit, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
import { DecimalPipe } from '@angular/common';
import { FormMovimientosComponent } from '../form-movimientos/form-movimientos.component';

@Component({
  selector: 'app-form-caja-sucursal',
  templateUrl: './form-caja-sucursal.component.html',
  styleUrls: ['./form-caja-sucursal.component.css'],
})
export class FormCajaSucursalComponent  implements OnInit {
  constructor(private router: Router,public url: Url, private decimalPipe: DecimalPipe,private route: ActivatedRoute,private formBuilder: FormBuilder,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {
    this.miFormularioCash = this.formBuilder.group({
      dates: [new Date(), Validators.required], // Fecha actual como valor predeterminado
      
    });
    this.miFormularioCash.get('dates')?.valueChanges.subscribe(nuevaFecha => {
      const fecha = new Date(nuevaFecha);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Nota: Los meses en JavaScript son base 0
      const anio = fecha.getFullYear();
      const fechaFormateada = `${dia}/${mes}/${anio}`;
      this.fecha_query = fechaFormateada
      this.consulta();
    });

  }
  id_cash_sucursal: string = "";
  chas_info: any = [];
  showCash: boolean = true;
  fecha_query = "" ;
  ngOnInit() {
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    let cash_id = this.route.snapshot.params['id']
    document.cookie = "_cash_branch" + "=" + cash_id + ";" + expiresStr + ";path=/";
    this.getCash();
    $(".fab-button-movimientos").click(function() {
      $(".fab-menu3").slideToggle("fast", function() {
      });
    });
  }

  miFormularioCash: FormGroup = this.formBuilder.group({
    dates: [new Date(), Validators.required], // Fecha actual como valor predeterminado
  });
  


  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async consulta(){
    const token = this.getCookie("_token")
    const id = this.route.snapshot.params['id'];
    const response = await fetch(this.url.Ms1 + "get_cash_sucu_date/" + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,

      },
      body: JSON.stringify({
        date: this.fecha_query
      }),
    });
      this.chas_info = await response.json()
      const result = this.chas_info
      const investment = (document.querySelector('#investment') as HTMLInputElement);
      const pix_payments = (document.querySelector('#pix_payment') as HTMLInputElement);
      const expenses = (document.querySelector('#expenses') as HTMLInputElement);
      const ingresos_diarios = (document.querySelector('#ingresos_diarios') as HTMLInputElement);
      const total_balance = (document.querySelector('#total_balance') as HTMLInputElement);
      if (response.status == 200){
        this.showCash = false
        investment.value = this.formateaMiles(result.investment);
        pix_payments.value = this.formateaMiles(result.pix_payments);
        expenses.value = this.formateaMiles(result.expenses);
        ingresos_diarios.value = this.formateaMiles(result.daily_balance);
        total_balance.value = this.formateaMiles(result.total_balance);
        this.id_cash_sucursal = this.formateaMiles(result.id);
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'No existe caja para este dia',
          text: 'validad informacion',
          showConfirmButton: true,
          confirmButtonColor: '#0e658e',
        });

    
   
    } 
  
  }


  async inversionModal(){
      const token =  this.getCookie('_token');
      const sucursal =  this.getCookie('_branch');
      const response = await fetch(this.url.Ms1 + "routes_list/sucursal/" + sucursal, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      });
      const data = await response.json(); // Convertir la respuesta a JSON
      let isSelectedAdded = false; 
      const options = data.map((route: any) => {
        if (!isSelectedAdded) {
          isSelectedAdded = true;
          return `
            <option selected disabled>--Seleccionar--</option>
            <option value="${route.cash_flow_id}" data-id="${route.id}">${route.name}</option>
          `;
        } else {
          return `<option value="${route.cash_flow_id}" data-id="${route.id}">${route.name}</option>`;
        }
      }).join('');



      const htmlContent = `
      <h1>Inversion a cobro</h1>
      <div style="display: grid;">
          <label for="total_values">Total</label>
          <input id="total_values" class="form-control input-form" type="number" inputmode="numeric">
          <label for="routes">Rutas</label>
          <select class="form-control input-form" id="routes">
            ${options}
          </select>
      </div>`;
      Swal.fire({
        html: htmlContent,
        showCloseButton: true,
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#0e658e',
        preConfirm: async () => {
            const total_values = (document.getElementById('total_values') as HTMLInputElement).value;
            const routes = (document.getElementById('routes') as HTMLSelectElement).value;
            const element = (document.getElementById('routes') as HTMLSelectElement)
            const selectedOption = element.options[element.selectedIndex];
            const dataId = selectedOption.getAttribute('data-id');
            try {
              if (total_values == '' || routes == '--Seleccionar--') {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Llena el campo',
                  showConfirmButton: false,
          
                });
                return;
              }else{
                const response = await fetch(
                  this.url.Ms1 + 'transfer_to_route',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                      sucursal_id: sucursal,
                      cash_id: routes,
                      total: total_values,
                      route: dataId
                    }),
                  }
                );
                const data = await response.json();
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Inversion realizada',
                  showConfirmButton: true, // Mostrar el botón "OK"
                  confirmButtonText: 'OK', // Texto del botón "OK"
                  allowOutsideClick: false, // Evitar el clic fuera del cuadro
                  allowEscapeKey: false, // Evitar la tecla "esc"
                  confirmButtonColor: '#0e658e',
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              }
             
            } catch (error) {
              console.error('Error:', error);
            }
            
        },
      });
  }

  async getCash(): Promise<any> {
    let cash_id = this.route.snapshot.params['id']
    const token = this.getCookie("_token")
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    const id = this.route.snapshot.params['id'];
    document.cookie = "_cash_branch" + "=" + id + ";" + expiresStr + ";path=/";

    const response = await fetch(this.url.Ms1 + "get_cash_sucu_id/" + cash_id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,

       },
     });
     this.chas_info = await response.json()
     const result = this.chas_info
     const investment = (document.querySelector('#investment') as HTMLInputElement);
     const pix_payments = (document.querySelector('#pix_payment') as HTMLInputElement);
     const expenses = (document.querySelector('#expenses') as HTMLInputElement);
     const ingresos_diarios = (document.querySelector('#ingresos_diarios') as HTMLInputElement);
     const total_balance = (document.querySelector('#total_balance') as HTMLInputElement);
    if (response.status == 200){
      this.showCash = false
    }
     investment.value = this.formateaMiles(result.investment);
     pix_payments.value = this.formateaMiles(result.pix_payments);
     expenses.value = this.formateaMiles(result.expenses);
     ingresos_diarios.value = this.formateaMiles(result.daily_balance);
     total_balance.value = this.formateaMiles(result.total_balance);
     this.id_cash_sucursal = this.formateaMiles(result.id);

   }

   paymentTransferRoute(){
    const rutas = this.chas_info.rutas; 
    let htmlContent = rutas.map((ruta:any) => {
      const key = Object.keys(ruta)[0];
      const value = this.formateaMiles(ruta[key]);
      return `
        <label style="font-weight: 600; font-size: 21px;">${key}</label>
        <input class="form-control input-form" type="text" disabled value="${value}" readonly>
      `;
    }).join(''); 
    Swal.fire({
      title: "Pagos por rutas", 
      html: htmlContent,  
      showConfirmButton: false, 
      showCloseButton: true,
    })

   }

   async updateCash(){
    const token = this.getCookie("_token")
    const id = this.getCookie("_cash_branch")
    const formdata = {
      pix_payments: "0" ,
      investment: "0",
      expenses: "0", 
    };
    try {
      const response = await fetch(this.url.Ms1 + "caja_sucursal/" + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formdata)
      });
      if (response.ok){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Actualizado',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
      }
    }
    catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }
   
  formateaMiles(numero: any) {
    let numer_string = this.decimalPipe.transform(numero, '1.2-2');
    return numer_string?.startsWith("-") ? "-$" + numer_string.slice(1) : "$" + numer_string;
  }
  History_cash(){
    this.router.navigate(['/history-cash-sucursal']);
  }

   MovimientoForm(){    
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormMovimientosComponent);
    const componentRef: ComponentRef<FormMovimientosComponent> = this.viewContainerRef.createComponent(componentFactory);
    Swal.fire({
      title: 'Movimientos',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })

  }
  
  MovimientoList(){
    const sucursal = this.getCookie("_branch")
    this.router.navigate(['list-movimiento-sucursal', sucursal]);
  }
}
