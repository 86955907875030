
<div class="menu-container" *ngIf="showListSucursa">

<app-menu-super-user></app-menu-super-user>
<div> 
  <button *ngFor="let iten of sucursal" class="btns-agg"  (click)="onSucursal(iten)">
    {{ iten.label }}
      <span class="agregar">&nbsp;+</span>
  </button>
    <br>
  </div>

    <div class="list-sucursa" *ngIf="showListSucursa">
      <app-list-sucursal></app-list-sucursal>
    </div>


  
      <div class="menu-items-container">
        <button *ngFor="let item of menuItems" class="menu-item" (click)="onMenuItemClick(item)">
          {{ item.label }}
          <span class="agregar">&nbsp;+</span>
        </button>
      </div>
</div>
    
<router-outlet></router-outlet>
