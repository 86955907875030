<div class="card-body data-content">
  <h3 class="title"  *ngIf="!esEdicion" >Crear Cliente</h3>
  <h3 class="title"  *ngIf="esEdicion" >Actualizar Cliente</h3>
  <form autocomplete="off" class="form" name="miFormularioUser"  #formularioUser="ngForm" (ngSubmit)="addLoanMode()">

    <div class="form-group row" *ngIf="!esEdicion" >
      <label class="col-lg-3 col-form-label form-control-label">Cedula</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form" type="number" maxlength="20"  inputmode="numeric" id="identify" name="identify" placeholder="Identificacion" >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Nombre y Apellido</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form" type="text"  id="name" name="name" placeholder="Nombre y Apellido"  (blur)="convertToUppercase('name')" >
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Caracteristicas </label>
      <div class="col-lg-8 col-12 inputs">
        <input type="text"  class="form-control input-form" id="surname" name="surname" placeholder="Caracteristicas" (blur)="convertToUppercase('surname')"  >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Telefono</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <img src="" alt="">
          </span>
        </div>
        <input type="tel" maxlength="20"  inputmode="numeric" class="form-control input-form" id="phone" name="phone" 
        placeholder="Teléfono" (input)="enforcePrefix()">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Direccion</label>
      <div class="col-lg-8 col-12 inputs">
        <input type="text"  class="form-control input-form" id="address" name="address" placeholder="Direccion" (blur)="convertToUppercase('address')" >
      </div>
    </div>


   

    <!-- FOTO COMERCIO -->
    <!-- <div class="form-group row"  *ngIf="!esEdicion">
      <div [class.inputs-imgs]="!img_4" [class.inputs-imgs2]="img_4"  class="col-lg-8 col-12 " >
        <label for="" style="width: 40%; margin-left: 13%;">Foto del negocio</label>
        <div [class.cont-img]="!img_4" [class.another-class]="img_4">
          <img class="img-previes" [src]="preview_4" alt="" *ngIf="img_4">
          <button type="button" *ngIf="img_4" class="kv-file-remove btn-removes" title="Remove file" (click)="removeImg4()">
            <i class="fa">x</i>
          </button>
          <i class="fa icos-ad" *ngIf="!img_4">+</i>
          <input id="images4" name="images4"  [class.input-otro]="!img_4" [class.input-otro-disable]="img_4"  type="file"  accept="image/*" (change)="comercio($event)">
        </div>
      </div>
    </div> -->



      
    <div class="form-group row" >
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input class="btn btn-primary subtmit-bt" *ngIf="!esEdicion" type="submit" value="Crear">
        <input class="btn btn-primary subtmit-bt" *ngIf="esEdicion" value="Actualizar" (click)="actualizar_customer()">
      </div>
    </div>
  </form>


</div>
