import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-form-agendar',
  templateUrl: './form-agendar.component.html',
  styleUrls: ['./form-agendar.component.css'],
})
export class FormAgendarComponent  implements OnInit {


  miFormularioAgendar: FormGroup = this.formBuilder.group({
    client: ['', Validators.required],
    date: ['', Validators.required],
    value: ['', Validators.required],
    id: ['', Validators.required],
  
  });
  minDateValue: Date;
  showMenus = false;
  selectedOption: string | null = null; // Inicialmente no se ha seleccionado ninguna opción
  selectedMenus: any | null = null; 
  selectMenuPass =  "";
  searchQuery: string = "";
  actualize = false;
  loans_ids: any = ""
  get filteredItems(): any[] {
    return this.datos.filter(datos =>
      datos.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  constructor(
    public url: Url, 
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { 
    this.searchQuery = '';
    this.datos = [];
    this.minDateValue = new Date();
  }

  async ngOnInit() {
    await this.getClient();
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('dato')) {
        let dato = JSON.parse(params['dato']);
        if (dato) {
          this.actualize = true
          this.loans_ids = dato.id_loan
          this.miFormularioAgendar.patchValue({
            value: dato.value_loan,
            client: dato.name,
            id: dato.id
          })
  
        }
     
      }

    })
  }



  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  
  
  datos: any[] = [];
  async getClient(): Promise<any> {
    const token = this.getCookie("_token")
    const ruta_id = this.getCookie("_route")
    const response = await fetch(this.url.Ms1 + "cliente/ruta/" + ruta_id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
  }

  search(){
    let searhcs = (document.querySelector('#search_inpt') as HTMLInputElement).value
    this.searchQuery = searhcs;
  }
  
  formatearFecha(fecha: any,type: any){
    let dia = fecha.getDate()
    if(type == "tarde"){
      dia = fecha.getDate();
    }else{
      dia = fecha.getDate() + 1;
    }
    let mes = fecha.getMonth() + 1; // Sumamos 1 al mes ya que en JavaScript los meses van de 0 a 11
    let año = fecha.getFullYear();
    dia = dia < 10 ? '0' + dia : dia;
    mes = mes < 10 ? '0' + mes : mes;
    return `${año}-${mes}-${dia}`;
  }


  async PostAgendamiento(){
    const token = this.getCookie("_token")
    const cliente_id = this.selectMenuPass
    const button = (document.querySelector('#buttons_enviar') as HTMLInputElement); 
    const date_initial = (document.querySelector('#date_initial') as HTMLSelectElement).value;

    const date_payment2 = (document.querySelector('#date_initial') as HTMLSelectElement).value;

    let [dia, mes, año] = date_payment2.split('/').map(Number);
    let fecha = new Date(año, mes - 1, dia); 


    const monto = (document.querySelector('#monto') as HTMLInputElement).value;
    let data = {}
    button.style.display = "none";

    if(this.actualize == true){
      data = {
        date: this.formatearFecha(fecha,''),
        value: this.miFormularioAgendar.value.value,
        customers_id: this.miFormularioAgendar.value.id,
        loan_id: this.loans_ids
      };
    }else{
      data = {
        date: this.formatearFecha(fecha,''),
        value: monto,
        customers_id: cliente_id,
        loan_id: this.loans_ids
      };
  
    }

    if(!date_initial){
      button.style.display = "inline-block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llenar la fecha',
        showConfirmButton: false,

      });
      return;
    }
 
    try {
      const response = await fetch(this.url.Ms1 + "agendar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Prestamo agendado',
          showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            button.style.display = "inline-block";
            this.router.navigate(['list-loans']);
          }
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'error!',
              text: 'No se pude crear el cliente',
              showConfirmButton: true,
            })
            button.style.display = "inline-block";
          }
        })
      }
      
    } catch (error) {
      button.style.display = "inline-block";
      console.error('Error al enviar el formulario', error);
    }
  }
  selectOption(item: any) {
    this.selectedOption = item.name;
    this.selectedMenus = item;
    this.selectMenuPass = item.id;
  }
  MostarSelect(){
    if(this.showMenus == false){
      this.showMenus = true
    }
    else{
      this.showMenus = false
    }
  }

}
