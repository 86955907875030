import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormLoansComponent } from '../form-loans/form-loans.component';
import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-imagen-client',
  templateUrl: './imagen-client.component.html',
  styleUrls: ['./imagen-client.component.css'],
})
export class ImagenClientComponent  implements OnInit {
  selectedFile: File | null = null;
  preview_1: string | ArrayBuffer | null = null;
  preview_2: string | ArrayBuffer | null = null;
  preview_3: string | ArrayBuffer | null = null;
  preview_4: string | ArrayBuffer | null = null;

  img_1 = false;
  img_2 = false;
  img_3 = false;
  img_4 = false;
  dni: any = ""
  dato: any = {}
  constructor(public url: Url, private router: Router,private route: ActivatedRoute,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) { }


  ngOnInit() {}

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  frontal(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.previewImage(file, "frontal");
    }
  }

  posterior(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.previewImage(file, "posterior");
    }
  }

  imagencliente(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.previewImage(file, "cliente");
    }
  }

  comercio(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.previewImage(file, "comercio");
    }
  }

  previewImage(file: File, type: string): void {
    if (type == "frontal"){
      this.img_1 = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview_1 = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    if (type == "posterior"){
      this.img_2 = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview_2 = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    if (type == "cliente"){
      this.img_3 = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview_3 = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    if (type == "comercio"){
      this.img_4 = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview_4 = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    
  }

  removeImg1(){
    this.img_1 = false;
  }
  removeImg2(){
    this.img_2 = false;
  }
  removeImg3(){
    this.img_3 = false;
  }
  removeImg4(){
    this.img_4 = false;
  }
  

  async postImages(){
    const token = this.getCookie("_token")
    const images1 = (document.getElementById("images1") as HTMLInputElement).files;
    const images2 = (document.getElementById("images2") as HTMLInputElement).files;
    const images3 = (document.getElementById("images3") as HTMLInputElement).files;
    const images4 = (document.getElementById("images4") as HTMLInputElement).files;

    if (!(images1 && images1.length > 0) && !(images2 && images2.length > 0) && !(images3 && images3.length > 0) && !(images4 && images4.length > 0)) {
      // Mensaje de error si ninguna imagen fue cargada
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Debes subir al menos una imagen...',
        showConfirmButton: false,
      });
      return;
    }

    const formData = new FormData();
    if (images1 && images1.length > 0) {
      formData.append('img1', images1[0]);
      formData.append('img_id1', 'img1')
    }
    if (images2 && images2.length > 0) {
      formData.append('img2', images2[0]);
      formData.append('img_id2', 'img2')
    }
    if (images3 && images3.length > 0) {
      formData.append('img3', images3[0]);
      formData.append('img_id3', 'img3')
    }
    if (images4 && images4.length > 0) {
      formData.append('img4', images4[0]);
      formData.append('img_id4', 'img4')
    }
    formData.append('customer_id', this.dni)

    try {
      const response = await fetch(this.url.Ms1 + "save_image/client", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: formData
      });

      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Imagenes guardadas',
          showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormLoansComponent);
            const componentRef: ComponentRef<FormLoansComponent> = this.viewContainerRef.createComponent(componentFactory);
            componentRef.instance.selectOption(this.dato)
            Swal.fire({
              title: 'Prestamos',
              showCloseButton: true,
              showConfirmButton: false,  // Mostrar el botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              html:componentRef.location.nativeElement
            })
          }
        })
      }

    } catch (error) {
      console.error('Error en la solicitud', error);
    }

  }

}
