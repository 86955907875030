<ng-container>
  
  <div class="menu-container" *ngIf="showListSucursa">
  
  <app-menu-super-user></app-menu-super-user>
  <div *ngIf="!permissions.hasOwnProperty('btn_sucursal_create')" > 
    <button *ngFor="let iten of sucursal" class="btns-agg" (click)="onSucursal(iten)">
      {{ iten.label }}
        <span class="agregar">&nbsp;+</span>
    </button>
      <br>
    </div>
  
      <div class="list-sucursa" *ngIf="showListSucursa" style="overflow-y: scroll; overflow-x: hidden;">
        <app-list-sucursal  (llamarFuncion)="funcionLlamada()"></app-list-sucursal>
      </div>
    
        <div class="menu-items-container">
          <button class="menu-item" (click)="onMenuItemClick('Usuario')"  *ngIf="!permissions.hasOwnProperty('btn_usuarios_crear')" >
            Usuarios
            <span class="agregar">&nbsp;+</span>
          </button>
          <button class="menu-item" (click)="onMenuItemClick('Ruta')" *ngIf="!permissions.hasOwnProperty('btn_ruta_create')">
            Ruta
            <span class="agregar">&nbsp;+</span>
          </button>
          <button class="menu-item" (click)="onMenuItemClick('Festivos')"  *ngIf="!permissions.hasOwnProperty('btn_feestivo_create')">
            Festivos
            <span class="agregar">&nbsp;+</span>
          </button>

        </div>
  </div>
      
  <router-outlet></router-outlet>
  
</ng-container>