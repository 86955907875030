import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent  implements OnInit {

  constructor(private routers: Router, private route: ActivatedRoute,public url: Url) { }
  esEdicion = false;
  rol: string | null = null;


  ngOnInit() {
    // Obtener el valor de la cookie _rol
    this.rol = this.getCookie('_rol');
  }

  private getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }
  
  async enviarFormularioUser() {
    const token =  this.getCookie('_token');
    this.esEdicion = true;
    const email = (document.querySelector('#email_user') as HTMLSelectElement).value;
    if (!email) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena el campo',
        showConfirmButton: false,

      });
      return;
    }
    const formdataUser = {
      email
    }

    try {
      const response = await fetch(this.url.Ms1 + "forgot_password", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      })
     
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Se envio un correo para el cambio de contraseña',
  
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        });
        this.logut()
      }
    }
    catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }


  
  logut(){
    const cookiesToDelete = ['_rol', '_id', '_token'];

    // Establecer una fecha de expiración pasada para cada cookie
    const pastDate = new Date(0).toUTCString();

    cookiesToDelete.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=${pastDate}; path=/`;
    });
    window.location.reload();
  }


  async restarUserPass(){
    const token =  this.getCookie('_token');
    const pass_ant = (document.querySelector('#pass_ant') as HTMLInputElement).value;
    const pass_new = (document.querySelector('#pass_new') as HTMLInputElement).value;
    const pass_confir = (document.querySelector('#pass_comfirm') as HTMLInputElement).value;
    const id =  this.getCookie('_id');

    if(pass_new != pass_confir){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Las contraseñas no coiciden',
        showConfirmButton: false,
      });
      return;
    }

    if (!pass_ant || !pass_new || !pass_confir){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena el campo',
        showConfirmButton: false,

      });
      return;
    }

    const formdataUser = {
      pass_ant,
      pass_new,
      id,
    }
    try {
      const response = await fetch(this.url.Ms1 + "restar_password", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      })
      if (response.ok) {
        let info = await response.json()
        if ("error" in info){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Contraseña del usuario invalida',
             showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
    
          });
          return;
        }
        else{
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Contraseña cambiada',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              this.logut()
            }
          });
          return;
        }
       
      }
      else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Contraseña del usuario invalida',
           showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
  
        });
        return;
      }
    }
    catch{

    }

  }

}
