import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-vista-uno-super-user',
  templateUrl: './vista-uno-super-user.component.html',
  styleUrls: ['./vista-uno-super-user.component.css']
})
export class VistaUnoSuperUserComponent {
  faArrowLeft = faArrowLeft;
  faCog = faCog;
  menuItems = [{ label: 'Crear Usuario' }, { label: 'Crear Ruta' }];
  sucursal = [{ label: 'Nueva Sucursal' }];
  isRotated = false;
  isConfigOpen = false;
  showListSucursa = true;

  constructor(private router: Router) {}

  onMenuItemClick(item: any) {
    if (item.label === 'Crear Usuario') {
      this.router.navigate(['form-usuario']);
    } else if (item.label === 'Crear Ruta') {
      this.router.navigate(['form-ruta']);
    }
  }


  onSucursal(item: any) {
    this.router.navigate(['form-sucursal']);
  }


  goBack() {
    // this.router.navigateByUrl('');
  }

  openConfig() {
    this.isRotated = !this.isRotated;
    this.isConfigOpen = !this.isConfigOpen;
  }

}
