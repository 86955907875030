import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import {ChangeViewLoans } from '../list-client/list-client.component';
@Component({
  selector: 'app-alert-payment',
  templateUrl: './alert-payment.component.html',
  styleUrls: ['./alert-payment.component.css'],
})
export class AlertPaymentComponent  implements OnInit {
  selectedDates: Date;
  minDateValue: Date;
  options_seelec: any;
  id_loans: any = 0;
  constructor(public url: Url,private parames: ActivatedRoute,private router: Router) {
    this.selectedDates = new Date();
    this.minDateValue = new Date();
   }

  ngOnInit() {}

  formatDate(inputDate:string) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  async PostAlert(){
    const token = this.getCookie("_token")
    const id = this.id_loans;
    let fecha = (document.querySelector('#date_motion') as HTMLSelectElement).value;
    let detalle = (document.querySelector('#detalle_alert') as HTMLSelectElement).value;
    
    let data = {
      date: fecha,
      detalle: detalle,
      loans_id: id
    }
    const response = await fetch(this.url.Ms1 + "alert/payment", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
      body: JSON.stringify(data)
    });
      if (response.ok) {

        Swal.fire({
          icon: 'success',
          title: 'Alerta creada',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
          
        }).then(async (result) => {
          if (result.isConfirmed){
            let fecha_format = new Date(this.formatDate(fecha));
            let today = new Date()
            fecha_format.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            if(fecha_format.getTime() == today.getTime()){
                true
            }else{
              var conteActivos = (document.getElementById('content_activos-'+id) as HTMLInputElement); 
              conteActivos.style.display = "none";
            }
            await ChangeViewLoans(this.options_seelec)
          }
         
        })
    }
    
  }
  
}
