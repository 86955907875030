import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Url,Permission } from '../service/data.service';
@Component({
  selector: 'app-list-saludo',
  templateUrl: './list-saludo.component.html',
  styleUrls: ['./list-saludo.component.css']
})
export class ListSaludoComponent {
  showVistaUser = false;
  role_user = '';
  claseTitular: string = "imagen-container2";
  showSaludo = true;
  showTextSaludo = true;
  textSaludo = '';
  name_user = '';
  permissions: any = {};
  constructor(private router: Router,public url: Url) {}
  branchIds = "";

  getMessage(users_id: string){
    const token = this.getCookie("_token")

    fetch(this.url.Ms1 + "message/" + users_id, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    })
    .then(response => response.json())
    .then(result => {
      if(result.error){
        var branch = this.getCookie("_branch")
        this.branchIds = branch ?? "";
        return
      }
      if(result[0].users_id.is_staff == true){
        this.textSaludo = result[0].message
      }else{
        if (result.length > 0 && result[0].users_id && result[0].users_id.routes) {
         
          for (const entry of result[0].users_id.routes) {
            const branchId = entry.routes_id?.branch_id;
        
            if (typeof branchId === "number") {
              this.branchIds = branchId.toString();
            }
          }
        this.textSaludo = result[0].message
        }
      }
      
    })
  }



  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  nextProfile(){
    const role = this.getCookie("_rol")
    if(role != null){
      if(role == "SuperUsuario"){
        this.showSaludo = false;
        this.showTextSaludo = false;
        this.showVistaUser = true;
        this.router.navigate(['/super-usuario']);
      }
      else{
        if(!this.permissions.hasOwnProperty('1')){
          this.router.navigate(['/super-usuario']);
          return
        }
        if(!this.permissions.hasOwnProperty('2')){
          const branc_ids = this.getCookie("_branch")
          this.router.navigate(['/list-rutas/'+ branc_ids]);
          return
        }
        if(!this.permissions.hasOwnProperty('3')){
          this.router.navigate(['/list-loans']);
          return
        }
      }
    }      
  }
  getValidToken(){
    const token = this.getCookie("_token")
    if (!token){
      this.router.navigate(['']);
    }
  }
  

  ngOnInit(): void {
    this.getValidToken()
    this.permissions = Permission.ElementPermissionObjects()
    const roles = this.getCookie("_rol")
    const users_ids =  this.getCookie("_id")
    const name =  this.getCookie("_name")
    this.role_user = roles ? roles : "";
    this.name_user = name ? name : "";

    if (typeof users_ids === 'string') {
      this.getMessage(users_ids);
    }
  }

}
