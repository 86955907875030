import { Component, OnInit } from '@angular/core';
import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { FormLoansComponent } from '../form-loans/form-loans.component';

import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-list-agendar-loan',
  templateUrl: './list-agendar-loan.component.html',
  styleUrls: ['./list-agendar-loan.component.css'],
})
export class ListAgendarLoanComponent  implements OnInit {
  selectedOption: string = "Clientes del dia";

  constructor(private router: Router,public url: Url,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.getAgendar();
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  datos: any[] = [];
  async getAgendar():Promise<any> {
    const route = this.getCookie("_route")
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "agendar/" + route, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    });
      if (response.ok) {
        const jsonResponse = await response.json();
        this.datos = jsonResponse;
        return this.datos;
    }

  }


  async removeAgendar(id: any){
    const token = this.getCookie("_token")
    Swal.fire({
      title: 'Rechazar',
      icon: 'warning',
      text: '¿Seguro quiere rechazar el prestamo?',
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'OK',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(this.url.Ms1 + "agendar/" + id, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          });
          if (response.ok) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Guardando...',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()     
              }
            });
          }
        }catch (error) {
          console.error('Error al enviar el formulario', error);
        }
      }
    })
  }




  addLoan(dato:any){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormLoansComponent);
    const componentRef: ComponentRef<FormLoansComponent> = this.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.options_seelec = this.selectedOption;
    componentRef.instance.dato_ac = dato

    Swal.fire({
      title: 'Prestamos',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })  
  }
  

}
