import { LoginComponent } from './login/login.component';
import { SuperUsuarioComponent } from './super-usuario/super-usuario.component';
import { VistaUnoComponent } from './vista-uno/vista-uno.component';
import { MenuSuperUserComponent } from './menu-super-user/menu-super-user.component';
import { VistaUnoSuperUserComponent } from './vista-uno-super-user/vista-uno-super-user.component';
import { FormSucursalComponent } from './form-sucursal/form-sucursal.component';
import { PacificComponent } from './Pacific/Pacific.component';
import { FormUsuarioComponent } from './form-usuario/form-usuario.component';
import { FormRutaComponent } from './form-ruta/form-ruta.component';
import { FormMetasComponent } from './form-metas/form-metas.component';
import { FormBonosComponent } from './form-bonos/form-bonos.component';
import { FormNominaComponent } from './form-nomina/form-nomina.component';
import { FormPagoNominaComponent } from './form-pago-nomina/form-pago-nomina.component';
import { ListSucursalComponent } from './list-sucursal/list-sucursal.component';
import { ListUsuarioComponent } from './list-usuario/list-usuario.component';
import { PerfilSuperuserComponent } from './perfil-superuser/perfil-superuser.component';
import { ListRutasComponent } from './list-rutas/list-rutas.component';
import { ListSaludoComponent } from './list-saludo/list-saludo.component';
import { FromConceptoComponent } from './from-concepto/from-concepto.component';
import { FormFestivosComponent } from './form-festivos/form-festivos.component';
import { FormCajaComponent } from './form-caja/form-caja.component';
import { FormModalidadComponent } from './form-modalidad/form-modalidad.component';
import { ListUserSucursalComponent } from './list-user-sucursal/list-user-sucursal.component';
import {ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import {ResetPasswordComponent } from './reset-password/reset-password.component';
import {FormMessageComponent} from './form-message/form-message.component';
import { ListClientComponent } from './list-client/list-client.component';
import { FormLoansComponent } from './form-loans/form-loans.component';
import { FormClienteComponent } from './form-cliente/form-cliente.component';
import { PaymentsComponent } from './payments/payments.component';
import { FormMovimientosComponent } from './form-movimientos/form-movimientos.component';
import { FormAgendarComponent } from './form-agendar/form-agendar.component';
import { ListClientRutaComponent } from './list-client-ruta/list-client-ruta.component';
import { ListAgendarLoanComponent } from './list-agendar-loan/list-agendar-loan.component';
import { PaymentNominaComponent } from './payment-nomina/payment-nomina.component';
import { PaymentCancelComponent } from './payment-cancel/payment-cancel.component';
import { AlertPaymentComponent } from './alert-payment/alert-payment.component';
import { LoanRefinanceComponent } from './loan-refinance/loan-refinance.component';
import { HistoryCashComponent } from './history-cash/history-cash.component';
import { FormGruopNominaComponent } from './form-gruop-nomina/form-gruop-nomina.component';
import { FormCajaSucursalComponent } from './form-caja-sucursal/form-caja-sucursal.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ListNominaComponent } from './list-nomina/list-nomina.component';
import { DetailsRouteComponent } from './details-route/details-route.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {ListPermissionsComponent} from './list-permissions/list-permissions.component';
import {ListMovimientosComponent} from './list-movimientos/list-movimientos.component';
import {ListVerifikaComponent} from './list-verifika/list-verifika.component';
import {UpdateClientComponent} from './update-client/update-client.component';
import { HistoryCashSucursalComponent } from './history-cash-sucursal/history-cash-sucursal.component';
import { AnimationComponent } from './animation/animation.component';
import { LogoComponent } from './logo/logo.component';
import { ListMovimientoSucursalComponent } from './list-movimiento-sucursal/list-movimiento-sucursal.component';
import { ImagenClientComponent } from './imagen-client/imagen-client.component';
import { ListPaymentMonimaComponent } from './list-payment-monima/list-payment-monima.component';
import { FormsModule } from '@angular/forms'; 
import { Routes, RouterModule } from '@angular/router';
import { NgModule, LOCALE_ID  } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData, CommonModule,DecimalPipe  } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms'; 
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { Url } from './service/data.service';
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule



defineLocale('es', esLocale);
registerLocaleData(es);
export function getBsDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), { locale: 'es' });
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SuperUsuarioComponent,
    VistaUnoComponent,
    MenuSuperUserComponent,
    VistaUnoSuperUserComponent,
    FormSucursalComponent,
    FormUsuarioComponent,
    FormRutaComponent,
    PacificComponent,
    FormMetasComponent,
    FormBonosComponent,
    FormNominaComponent,
    FormPagoNominaComponent,
    ListSucursalComponent,
    ListUsuarioComponent,
    PerfilSuperuserComponent,
    ListRutasComponent,
    ListSaludoComponent,
    FromConceptoComponent,
    FormFestivosComponent,
    FormCajaComponent,
    FormModalidadComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FormMessageComponent,
    ListClientComponent,
    FormLoansComponent,
    FormClienteComponent,
    PaymentsComponent,
    FormMovimientosComponent,
    FormAgendarComponent,
    ListAgendarLoanComponent,
    ListClientRutaComponent,
    PaymentNominaComponent,
    ListUserSucursalComponent,
    PaymentCancelComponent,
    AlertPaymentComponent,
    LoanRefinanceComponent,
    HistoryCashComponent,
    FormGruopNominaComponent,
    FormCajaSucursalComponent,
    UpdatePasswordComponent,
    ListNominaComponent,
    PermissionsComponent,
    ListPermissionsComponent,
    ListMovimientosComponent,
    DetailsRouteComponent,
    AnimationComponent,
    ListVerifikaComponent,
    UpdateClientComponent,
    LogoComponent,
    HistoryCashSucursalComponent,
    ListMovimientoSucursalComponent,
    ImagenClientComponent,
    ListPaymentMonimaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BsDatepickerConfig, useFactory: getBsDatepickerConfig },
    { provide: LOCALE_ID, useValue: 'es-*' },
    Url,
    DecimalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
