
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2>Cambiar contraseña</h2>
        <form autocomplete="off" class="form" name="miFormularioSucursal" style="margin-top: 85px;"   (ngSubmit)="reset_pass()" >
          <div class="form-group row">
            <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Contraseña</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="password" id="password" name="password" placeholder="contraseña"  formControlName="password">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-6 col-form-label form-control-label" style="white-space: nowrap;">Confirmar contraseña</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="password" id="confirm_password" name="confirm_password" placeholder="Confirmar contraseña"  formControlName="confirm_password">
            </div>
          </div>
         
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs">
              <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar">
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
