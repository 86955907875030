
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h3 style="display: inline-block;">Creacion bono</h3>
        <a class="link-buttonAdd" *ngIf="!listConcept && (!permissions.hasOwnProperty('btn_bonos_update') || !permissions.hasOwnProperty('btn_bonos_delete'))"  (click)="EditBono()">Editar o eliminar +</a>
        <a class="link-buttonAdd" *ngIf="listConcept"  (click)="EditBono()">Crear +</a>

        <form autocomplete="off" class="form" name="miFormularioBonos" [formGroup]="miFormularioBonos" (ngSubmit)="enviarFormularioBonos()">

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Sucursal</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form" name="sucursa_ids" id="sucursa_ids" (change)="BonosSucursal()">
                <option value="" disabled selected>--Seleccionar--</option>
                <option *ngFor="let sucursal of datos" [value]="sucursal.id">{{ sucursal.name }}</option>
              </select>
            </div>
          </div>

          <div class="form-group row" *ngIf="listConcept">
            <label class="col-lg-3 col-form-label form-control-label">Lista de bonos</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form" name="list_bonus" id="list_bonus" (change)="getBonos()">
                <option value="" disabled selected>--Seleccionar--</option>
                <option *ngFor="let bono of datos_bonos" [value]="bono.id">{{ bono.name }}</option>
              </select>
            </div>
          </div>


            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label" >Nombre</label>
                <div class="col-lg-8 col-12 inputs">
                    <input id="names" name="names" class="form-control input-form" type="text" placeholder="" style="text-transform: uppercase;">
                </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-3 col-form-label form-control-label" >Valor</label>
              <div class="col-lg-8 col-12 inputs">
                  <input  class="form-control input-form" type="number" id="value" name="value" placeholder=""  inputmode="numeric" formControlName="nombre"  style="text-transform: uppercase;">
              </div>
             </div>

            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label" >Valor por ventas nuevas</label>
                <div class="col-lg-8 col-12 inputs">
                    <input  class="form-control input-form" type="number" id="ventas_bono" name="ventas_bono" placeholder=""  inputmode="numeric" formControlName="nombre"  style="text-transform: uppercase;">
                </div>
            </div>
            
            <div class="form-group row">
              <label class="col-lg-3 col-form-label form-control-label" >Porcentaje por retiradas:</label>
              <div class="col-lg-8 col-12 inputs">
                <input  class="form-control input-form"  type="number" name="retirada_select"  inputmode="numeric" id="retirada_select">
              </div>
            </div>

            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label" >Frecuencia</label>
                <div class="col-lg-8 col-12 inputs">
                    <select name="frecuencia" id="frecuencia" class="form-control input-form"  [(ngModel)]="frecuenciaSeleccionada" formControlName="frecuencia"> 
                        <option value="">--Seleccionar--</option>
                        <option value="semanal">Semanal</option>
                        <option value="quincenal">Quincenal</option>
                        <option value="mensual">Mensual</option>
                        <option value="anual">Anual</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label" >Dias</label>
                <div class="col-lg-8 col-12 inputs">
                    <select class="form-control input-form" id="numero" name="numero" [(ngModel)]="valorNumeroSeleccionado"  formControlName="numero">
                        <option value="" disabled>--Seleccionar--</option>
                        <option *ngFor="let valor of valoresNumero[frecuenciaSeleccionada]" [value]="valor">{{ valor }}</option>
                    </select>
                </div>
            </div>

           
    

            <div class="form-group row">
                <label class="col-lg-3 col-form-label form-control-label"></label>
                <div class="col-lg-8 col-12 inputs" style="margin-bottom: 100px !important; display: flex; justify-content: center;">
                  <input class="btn btn-primary subtmit-bt" id="subtmit-boton" type="submit" value="Guardar" *ngIf="!listConcept">

                  <button class="btn btn-primary subtmit-bt1" *ngIf="listConcept && !permissions.hasOwnProperty('btn_bonos_update')" (click)="updateBonus()">Actualizar</button>
                  <button class="btn btn-primary subtmit-bt2" (click)="DeleteBonus()" *ngIf="listConcept && !permissions.hasOwnProperty('btn_bonos_delete')">Eliminar</button>
                </div>
              </div>


        </form>
      </div>
    </div>
  </div>


</div>