
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
        <div class="card-body data-content">
          <h2>Lista de usuarios</h2>
          <button class="btns-agg" (click)="message()">Agregar mensaje personalizado</button>
          <br><br>

          <div class="table-responsive custom-table-responsive">

            <table class="table custom-table">
              <thead>
                <tr>  
                  <th scope="col">#</th>
                  <th scope="col">Cedula</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Acciones</th>
                  <th scope="col">Contraseña</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let dato of datos; let i = index">
                  <tr  scope="row"  [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse-' + i"
                  [attr.aria-expanded]="!collapsedRows[i]" [attr.aria-controls]="'collapse-' + i">
                    <th scope="row">
                      <b>{{ i + 1 }}</b>
                    </th>
                    <td (click)="toggleRow(i)">{{dato.id}}</td>
                    <td style="text-transform: uppercase;">
                      {{dato.name}}
                      <small class="d-block" style="text-transform: none;">Rol: {{dato.roles_id.type}}</small>
                    </td>
                    <td>
                      {{dato.address}}
                      <small class="d-block">Ciudad: {{dato.country}}</small>
                    </td>
                    <td class="botones">
                      <button class="btn btn-danger" style="margin-right: 5px;">
                        <fa-icon [icon]="faTrashAlt" (click)="borrar(dato)" class="icono-borrar" *ngIf="!permissions.hasOwnProperty('btn_usuarios_borrar')"></fa-icon>
                      </button>
                      <button class="btn btn-primary" style="background-color: #0e658e;">
                        <fa-icon [icon]="faPencilAlt" (click)="actualizar(dato)" class="icono-actualizar" *ngIf="!permissions.hasOwnProperty('btn_usuarios_editar')"></fa-icon>
                      </button>
                    </td>
                    <td class="botones">
                      <button style="width: 93px;"  class="btns-agg"  (click)="update_pass(dato.id)">Cambiar</button>
                    </td>
                  </tr>
                  <tr [class.d-none]="!collapsedRows[i]" style="border-radius: 0px;">
                    <th class="sub_info_tables" style="color: #ff0000;font-size:23px;text-align: center;">↳</th>
                    <th class="sub_info_tables">
                      Bonos
                      <small style="color: #000;" class="d-block">Valor bonos: ${{dato.goals_id|number:"1.2-2"}}</small>
                    </th>
                    <th class="sub_info_tables">
                      Salario
                      <small style="color: #000;" class="d-block">Valor: $ {{dato.wages_id?.salary|number:"1.2-2"}}</small>
                      
                    </th>
                    <th class="sub_info_tables"></th>
                    <th class="sub_info_tables"></th>
                    <th class="sub_info_tables"></th>
                  </tr>
                
                  
                  <tr class="spacer"><td colspan="100"></td></tr>
                  </ng-container>



                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
