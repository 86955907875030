import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SuperUsuarioComponent } from './super-usuario/super-usuario.component';
import { VistaUnoComponent } from './vista-uno/vista-uno.component';
import { VistaUnoSuperUserComponent } from './vista-uno-super-user/vista-uno-super-user.component';
import { FormSucursalComponent } from './form-sucursal/form-sucursal.component';
import { PacificComponent } from './Pacific/Pacific.component';
import { FormUsuarioComponent } from './form-usuario/form-usuario.component';
import { FormRutaComponent } from './form-ruta/form-ruta.component';
import { FormLoansComponent } from './form-loans/form-loans.component';
import { FormMetasComponent } from './form-metas/form-metas.component';
import { FormBonosComponent } from './form-bonos/form-bonos.component';
import { FormNominaComponent } from './form-nomina/form-nomina.component';
import { FormPagoNominaComponent } from './form-pago-nomina/form-pago-nomina.component';
import { ListSucursalComponent } from './list-sucursal/list-sucursal.component';
import { ListUsuarioComponent } from './list-usuario/list-usuario.component';
import { PerfilSuperuserComponent } from './perfil-superuser/perfil-superuser.component';
import { ListRutasComponent } from './list-rutas/list-rutas.component';
import { ListSaludoComponent } from './list-saludo/list-saludo.component';
import { FromConceptoComponent } from './from-concepto/from-concepto.component';
import { FormFestivosComponent } from './form-festivos/form-festivos.component';
import { FormCajaComponent } from './form-caja/form-caja.component';
import { FormModalidadComponent } from './form-modalidad/form-modalidad.component';
import { ListUserSucursalComponent } from './list-user-sucursal/list-user-sucursal.component';
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import {FormMessageComponent} from './form-message/form-message.component';
import { ListClientComponent } from './list-client/list-client.component';
import { FormClienteComponent } from './form-cliente/form-cliente.component';
import { PaymentsComponent } from './payments/payments.component';
import { FormMovimientosComponent } from './form-movimientos/form-movimientos.component';
import { FormAgendarComponent } from './form-agendar/form-agendar.component';
import { ListClientRutaComponent } from './list-client-ruta/list-client-ruta.component';
import { ListAgendarLoanComponent } from './list-agendar-loan/list-agendar-loan.component';
import { PaymentNominaComponent } from './payment-nomina/payment-nomina.component';
import { PaymentCancelComponent } from './payment-cancel/payment-cancel.component';
import { AlertPaymentComponent } from './alert-payment/alert-payment.component';
import { HistoryCashComponent } from './history-cash/history-cash.component';
import { HistoryCashSucursalComponent } from './history-cash-sucursal/history-cash-sucursal.component';
import { FormGruopNominaComponent } from './form-gruop-nomina/form-gruop-nomina.component';
import { FormCajaSucursalComponent } from './form-caja-sucursal/form-caja-sucursal.component';
import { LoanRefinanceComponent } from './loan-refinance/loan-refinance.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ListNominaComponent } from './list-nomina/list-nomina.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {ListPermissionsComponent} from './list-permissions/list-permissions.component';
import {ListMovimientosComponent} from './list-movimientos/list-movimientos.component';
import {ListVerifikaComponent} from './list-verifika/list-verifika.component';
import { DetailsRouteComponent } from './details-route/details-route.component';

import { ListPaymentMonimaComponent } from './list-payment-monima/list-payment-monima.component';


import { ListMovimientoSucursalComponent } from './list-movimiento-sucursal/list-movimiento-sucursal.component';
import { AnimationComponent } from './animation/animation.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'super-usuario', component: SuperUsuarioComponent },
  { path: 'lista-userSucursal', component: ListUserSucursalComponent },
  { path: 'vista-uno', component: VistaUnoComponent },
  { path: 'form-caja/:id', component:  FormCajaComponent},
  { path: 'form-sucursal', component: FormSucursalComponent },
  { path: 'form-modalidad', component: FormModalidadComponent },
  { path: 'form-usuario', component: FormUsuarioComponent},
  { path: 'form-festivos', component: FormFestivosComponent},
  { path: 'form-ruta', component: FormRutaComponent},
  { path: 'form-concepto', component: FromConceptoComponent},
  { path: 'vista-uno-super-user', component: VistaUnoSuperUserComponent },
  { path: 'Pacific', component: PacificComponent},
  { path: 'metas', component: FormMetasComponent},
  { path: 'bonos', component: FormBonosComponent},
  { path: 'nomina', component: FormNominaComponent},
  { path: 'pago-nomina2', component: FormPagoNominaComponent},
  { path: 'list-sucursal', component: ListSucursalComponent},
  { path: 'list-usuario', component: ListUsuarioComponent},
  { path: 'perfin-superuser', component: PerfilSuperuserComponent},
  { path: 'list-rutas/:id', component: ListRutasComponent},
  { path: 'list-saludo', component: ListSaludoComponent},
  { path: 'forgot_pass', component: ForgotPasswordComponent},
  { path: 'reset_password/:token', component: ResetPasswordComponent},
  { path: 'form-message', component: FormMessageComponent},
  { path: 'list-loans', component: ListClientComponent},
  { path: 'form-cliente', component: FormClienteComponent},
  { path: 'form-payment/:id/:feePayment/:cuota', component: PaymentsComponent},
  { path: 'form-movimiento', component: FormMovimientosComponent},
  { path: 'list-client-ruta', component: ListClientRutaComponent},
  { path: 'list-agendar-loan', component: ListAgendarLoanComponent},
  { path: 'form-agendar', component: FormAgendarComponent}, 
  { path: 'form-loans', component: FormLoansComponent},
  { path: 'payment-wages', component: PaymentNominaComponent},
  { path: 'alert-payment/:id', component: AlertPaymentComponent},
  { path: 'loan-refinance/:id', component: LoanRefinanceComponent},
  { path: 'history-cash', component: HistoryCashComponent},
  { path: 'history-cash-sucursal', component: HistoryCashSucursalComponent},
  { path: 'pago-nomina/:id', component: FormGruopNominaComponent},
  { path: 'loan-cancel', component: PaymentCancelComponent},
  { path: 'cash-branch/:id', component: FormCajaSucursalComponent},
  { path: 'update_pasword/:id', component: UpdatePasswordComponent},
  { path: 'list-nominas', component: ListNominaComponent},
  { path: 'list-wages-payment/:id', component: ListPaymentMonimaComponent},
  { path: 'list-verik/:id', component: ListVerifikaComponent},
  { path: 'list-movimiento-sucursal/:sucursal', component: ListMovimientoSucursalComponent},
  { path: 'permissions', component: PermissionsComponent},
  { path: 'list-permissions/:usuario', component: ListPermissionsComponent},
  { path: 'list-movimiento/:route', component: ListMovimientosComponent},
  { path: 'details-route/:id', component: DetailsRouteComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
