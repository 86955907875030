<div class="card-body data-content">
  <form autocomplete="off" class="form" name="miFormularioMovimiento"  (ngSubmit)="PostMovimiento()">
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Tipo de movimiento</label>

      <div class="col-lg-8 col-12 inputs">
        <select class="form-control input-form" id="type_motion" name="type_motion" size="0">
          <option value="">--Seleccionar--</option>
          <ng-container *ngFor="let mode of datos_mode">
            <option
                    [value]="mode.income_expense_configuration_id.id" 
                    [attr.data-type]="mode.income_expense_configuration_id.type" 
                    (click)="logModeId(mode)">
              {{ mode.income_expense_configuration_id.name === 'Inversion' ? 'Inversion a caja sucursal' : mode.income_expense_configuration_id.name }} 
              - {{ mode.income_expense_configuration_id.type }}
            </option>
          </ng-container>
        </select>
      </div>

      

    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Fecha del movimiento</label>
      <div class="col-lg-8 col-12 inputs">
        <input disabled type="text" id="date_motion" name="date_motion" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Valor total</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form" type="number" id="value_motion" name="value_motion"  inputmode="numeric" placeholder="Valor del movimiento" [(ngModel)]="valorMovimiento">
      </div>
    </div>

    <div class="form-group row">
      <div [class.inputs-imgs]="!img_1" [class.inputs-imgs2]="img_1"  class="col-lg-8 col-12 " >
        <label for="" style="width: 58%;">Foto del recibo</label>
        <div [class.cont-img]="!img_1" [class.another-class]="img_1">
          <img class="img-previes" [src]="preview_1" alt="" *ngIf="img_1">
          <button type="button" *ngIf="img_1" class="kv-file-remove btn-removes" title="Remove file" (click)="removeImg1()">
            <i class="fa">x</i>
          </button>
          <i class="fa icos-ad" *ngIf="!img_1">+</i>
          <input id="images1" name="images1"  [class.input-otro]="!img_1" [class.input-otro-disable]="img_1"  type="file"  accept="image/*" (change)="frontal($event)">
        </div>
        
      </div>
    </div>


    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input class="btn btn-primary subtmit-bt" type="submit" value="Guardar" [disabled]="submitting">
      </div>
    </div>
  </form>
</div>
