import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
@Component({
  selector: 'app-list-user-sucursal',
  templateUrl: './list-user-sucursal.component.html',
  styleUrls: ['./list-user-sucursal.component.css']
})
export class ListUserSucursalComponent {
  datos: any[] = [];

  constructor(private router: Router,public url: Url) {}

  ngOnInit(): void {
    this.getUsers();
  }

  addUser(){
    this.router.navigate(['form-usuario']);
  }
  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

    
  async getUsers(): Promise<any> {
    const token = this.getCookie("_token")
    const branch_id = this.getCookie("_branch")

    const response_user = await fetch(this.url.Ms1 + "users", {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    const response = await fetch(this.url.Ms1 + "get_user_by_sucursal/" + branch_id, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    });

    const UserAll = await response_user.json();
    const userBySucursal = await response.json();

    const userIDs = userBySucursal.map((user: any) => user.user_id);

    const userTosucursal = UserAll.filter((item:any) => userIDs.includes(item.id.toString()));
    console.log(userTosucursal)
    this.datos = userTosucursal;
    return this.datos;
    };
}
