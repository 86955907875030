import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Url } from '../service/data.service';
interface Item {
  text: string;
  checked: boolean;
}

@Component({
  selector: 'app-form-sucursal',
  templateUrl: './form-sucursal.component.html',
  styleUrls: ['./form-sucursal.component.css'],
})
export class FormSucursalComponent implements OnInit, AfterViewInit {
  @ViewChildren('itemMonto', { read: ElementRef })

  itemMontos!: QueryList<ElementRef>;
  miFormularioSucursal: FormGroup = this.formBuilder.group({
    nombre_sucursal: ['', Validators.required],
    numero: ['', Validators.required],
    pais: ['', Validators.required],
    moneda: ['', Validators.required],
  
    tiempo_laboral: ['', Validators.required],
    tiempo_laboral_final: ['', Validators.required],
  });
  dato: any;
  selectedItems: string[] = [];
  esEdicion = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public url: Url,
    private renderer: Renderer2
  ) {}

  @ViewChild('selectBtn') selectBtnRef!: ElementRef;
  @ViewChild('btnText') btnTextRef!: ElementRef;
  @ViewChild('itemsContainer') itemsContainerRef!: ElementRef;

  addClickEventListener() {
    const selectBtn = this.selectBtnRef.nativeElement;
    selectBtn.addEventListener('click', () => {
      selectBtn.classList.toggle('open');
    });
  }

  ngAfterViewInit() {
    this.addClickEventListener();
    setTimeout(() => {
      this.itemMontos.forEach((itemMonto) => {
        const element = itemMonto.nativeElement;
        element.addEventListener('click', () => {
          element.classList.toggle('checked');
        });
      });
    }, 1000);
  }

  ngOnInit() {
    const inputs = document.querySelectorAll<
      HTMLInputElement | HTMLTextAreaElement
    >('input[type="text"], input[type="number"], textarea');
    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        if (input.value === input.defaultValue) {
          input.value = '';
          input.style.opacity = '1';
        }
      });
      input.addEventListener('blur', () => {
        if (input.value === '') {
          input.value = input.defaultValue;
          input.style.opacity = '0.5';
        }
      });
      if (input.value === input.defaultValue) {
        input.style.opacity = '0.5';
      }
    });
       
        if (this.dato) {
          console.log(this.dato)
          this.esEdicion = true;
          this.miFormularioSucursal.patchValue({
            nombre_sucursal: this.dato.name,
            numero: this.dato.phone,
            pais: this.dato.country,
            moneda: this.dato.currency,
            tiempo_laboral: this.dato.working_time,
            tiempo_laboral_final: this.dato.date_finysh_day,
          });
        
      }

  }

  getCookie(name: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async enviarFormularioSucursal() {
    const token = this.getCookie('_token');
    const name = (document.querySelector('#nombre_sucursal') as HTMLInputElement).value;
    const phone = (document.querySelector('#numero') as HTMLInputElement).value;
    const country = (document.querySelector('#pais') as HTMLSelectElement).value;
   
    const currency = (document.querySelector('#moneda') as HTMLInputElement).value;
    const enviar = (document.querySelector('#enviar-sucursal') as HTMLInputElement);
  
    const working_time = (document.querySelector('#start_time') as HTMLInputElement).value;
    const date_finysh_day = (document.querySelector('#end_time') as HTMLInputElement).value;
    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Telefono solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 
     
    if (
      !name ||
      !phone ||
      !country ||
      !currency ||
      !working_time ||
      !date_finysh_day
    ) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
    enviar.style.display = "none";
  

    const formdataSucursal = {
      name,
      phone,
      country,
      currency,
      working_time,
      date_finysh_day
    };
    try {
      const response = await fetch(this.url.Ms1 + 'sucursal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formdataSucursal),
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          enviar.style.display = "block";
          if (result.isConfirmed) {
            window.location.reload()
          }
        });

        // Vaciar los campos de entrada del formulario
        const elementos = [
          '#nombre_sucursal',
          '#numero',
          '#pais',
          '#moneda',
          '#tiempo_laboral',
          '#date_finysh_day',
          '#detalle',
        ];
        for (let i = 0; i < elementos.length; i++) {
          (document.querySelector(elementos[i]) as HTMLInputElement).value = '';
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al guardar el formulario...\n\n',
          text: '',
          showConfirmButton: false,
        });
        enviar.style.display = "block";
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }

  async actualizarFormularioSucursal(formulario: any) {
    const token = this.getCookie('_token');
    const name = formulario.nombre_sucursal.toUpperCase();
    const country = formulario.pais.toUpperCase();
    const currency = formulario.moneda.toUpperCase();
    const phone = formulario.numero;
    const working_time = formulario.tiempo_laboral;
    const tiempo_laboral_final = formulario.tiempo_laboral_final;
    
    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Telefono solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 
    
    if (
      !phone ||
      !country ||
      !name ||
      !currency ||
      !working_time || 
      !tiempo_laboral_final
    ) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    const formdataUser = {
      name,
      country,
      phone,
      currency,
      working_time,
      tiempo_laboral_final,
    };

    try {
      const response = await fetch(this.url.Ms1 + 'sucursal/' + this.dato.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formdataUser),
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload() 
          }
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al actualizar Usuario...\n\n',
          text: 'validad informacion',
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }

  convertToUppercase(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
 
}
