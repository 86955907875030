
<div class="menu-container">
  <app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
      <div style="text-align:-webkit-center;">
        <label for="">Fecha Inicial</label>
          <input placeholder="Fecha Inicial" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',minDate: minDate, maxDate: maxDate }">
      </div>
      <div style="text-align:-webkit-center;">
        <label for="">Fecha final</label>
        <input placeholder="Fecha Inicial" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedFinal" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', maxDate: maxDate ,minDate: minDate }">
      </div>
      <div style="text-align:-webkit-center;">
        <button class="btns-agg" (click)="detailSearchCash()">Consultar</button>
      </div>

      <div class="card-body data-content" style="overflow-y: scroll;">
        <h2>Detalle ruta</h2>
        <div style="width: 100%; height: 550px;" *ngFor="let item of datos">

          
          <div class="conten-infosD">
            <div class="title-infosD">
              <div class="info-req" id="recaudo_pretend" (click)="ViewsInfos('1')">
                <div class="info-tts">
                  <b>Recaudo pretendido</b>
                  <img src="../../assets/nuevos_client.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Recaudo_Pretendido}}</b>
                </div>
              </div>

              <div class="info-req"  *ngIf="showAmountRecaudo">
                <div class="info-tts" style="padding-left: 30px;">
                  <b>Cantidad</b>
                </div>
                <div class="value-tts">
                  <b>{{item.Recaudo.cantidad_pagos_recaudo}}</b>
                </div>
              </div>

              <div class="info-req"  id="pagos_efectivo" (click)="ViewsInfos('2')">
                <div class="info-tts">
                  <b>Pagos en efectivo</b>
                  <img src="../../assets/efectivo.svg" alt="" style="width: 30px;float: inline-end"  >
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Pagos_Efectivo}} | {{porcentaje_efectivo}}%</b>
                </div>
              </div>

              <div class="info-req"  *ngIf="showAmountEfectivo">
                <div class="info-tts" style="padding-left: 30px;">
                  <b>Cantidad pagos</b>
                </div>
                <div class="value-tts">
                  <b>{{item.Recaudo.cantidad_pagos_efectivos}}</b>
                </div>
              </div>
              
              <div class="info-req" id="pagos_transfer"  (click)="ViewsInfos('3')">
                <div class="info-tts">
                  <b>Pagos en transferencia</b>
                  <img src="../../assets/pago_transfer.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Pagos_Transferencia}} | {{porcentaje_transfer}}%</b>
                </div>
              </div>

              <div class="info-req"  *ngIf="showAmountTransfer" >
                <div class="info-tts" style="padding-left: 30px;">
                  <b>Cantidad pagos</b>
                </div>
                <div class="value-tts">
                  <b>{{item.Recaudo.cantidad_pagos_transfer}}</b>
                </div>
              </div>

              <div class="info-req">
                <div class="info-tts">
                  <b>Ganancia porcentual</b>
                  <img src="../../assets/ganancias_porcen.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Ganancias}}</b>
                </div>
              </div>

              <div class="info-req">
                <div class="info-tts">
                  <b>Rentabilidad</b>
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Ganancia_Bruta}}</b>
                </div>
              </div>

              <div class="info-req">
                <div class="info-tts">
                  <b>Total recaudado</b>
                </div>
                <div class="value-tts">
                  <b>${{item.Recaudo.Recaudo_Total}} | {{porcentaje_recaudo}}%</b>
                </div>
              </div>
            </div>
            
          </div>
          
          <div class="conten-infosD">
            <div class="title-infosD">
              <div class="info-req">
                <div class="info-tts">
                  <b>Clientes activos</b>
                  <img src="../../assets/client_activos.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>0</b>
                </div>
              </div>
              
              <div class="info-req">
                <div class="info-tts">
                  <b>Clientes Nuevos</b>
                  <img src="../../assets/nuevos_client.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>{{item.Clientes.Nuevos}}</b>
                </div>
              </div>

              <div class="info-req">
                <div class="info-tts">
                  <b>Clientes Renovados</b>
                  <img src="../../assets/renovado.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>{{item.Clientes.Renovados}}</b>
                </div>
              </div>
              
              <div class="info-req">
                <div class="info-tts">
                  <b>Clientes Cancelados</b>
                  <img src="../../assets/Untitled-1.svg" alt=""style="width: 30px;float: inline-end" >
                </div>
                <div class="value-tts">
                  <b>{{item.Clientes.Cancelados}}</b>
                </div>
              </div>


              <div class="info-req">
                <div class="info-tts">
                  <b>Clientes efectivos</b>
                </div>
                <div class="value-tts">
                  <b>0</b>
                </div>
              </div>
              <div class="info-req">
                <div class="info-tts">
                  <b>Interes pretendido</b>
                </div>
                <div class="value-tts">
                  <b>$ {{item.Recaudo.interest_prentendido}}</b>
                </div>
              </div>
            </div>
            
          </div>

        </div>


      </div>
    </div>

  </div>

</div>