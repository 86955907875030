
<div class="menu-container" style="position: relative;">

  <app-menu-super-user></app-menu-super-user>

  <div class="list-sucursa">
    <h2 style="text-align: center;">Roles permisos</h2> 

    <div class="contaner-princi">
      <div class="contaner-list" ng-reflect-ng-class="[object Object]">
        <div class="contener-datas">
          <div style="width: 100%; text-align: start;">
            <div
              style="text-align: center; border-bottom: solid 2px #adb5bd59; width: 95%; margin: auto; height: 35px;">
              <label for="" class="info-client"> SOCIO </label>
            </div>
            <div style="display: block; overflow: hidden; overflow-x: auto;">
              <div class="row" style="text-align: center; display: block;">
                <div style="position: absolute;">
                  <button style="margin: auto;" class="btn-agend bnt-loans-pago" (click)="list_permission('Socio')"> Ver Permisos</button></div>
              </div>
            </div>
          </div>
          <div style="right: 50%; position: absolute; left: 46%;"></div>
        </div><!--bindings={}-->
      </div>
    </div>


    <div class="contaner-princi">
      <div class="contaner-list" ng-reflect-ng-class="[object Object]">
        <div class="contener-datas">
          <div style="width: 100%; text-align: start;">
            <div
              style="text-align: center; border-bottom: solid 2px #adb5bd59; width: 95%; margin: auto; height: 35px;">
              <label for="" class="info-client"> ADMINISTRADOR </label>
            </div>
            <div style="display: block; overflow: hidden; overflow-x: auto;">
              <div class="row" style="text-align: center; display: block;">
                <div style="position: absolute;">
                  <button style="margin: auto;" class="btn-agend bnt-loans-pago" (click)="list_permission('Administrador')"> Ver Permisos</button></div>
              </div>
            </div>
          </div>
          <div style="right: 50%; position: absolute; left: 46%;"></div>
        </div><!--bindings={}-->
      </div>
    </div>

 

    <div class="contaner-princi">
      <div class="contaner-list" ng-reflect-ng-class="[object Object]">
        <div class="contener-datas">
          <div style="width: 100%; text-align: start;">
            <div
              style="text-align: center; border-bottom: solid 2px #adb5bd59; width: 95%; margin: auto; height: 35px;">
              <label for="" class="info-client"> SUPERVISOR </label>
            </div>
            <div style="display: block; overflow: hidden; overflow-x: auto;">
              <div style="display: block; overflow: hidden; overflow-x: auto;">
                <div class="row" style="text-align: center; display: block;">
                  <div style="position: absolute;">
                  <button style="margin: auto;" class="btn-agend bnt-loans-pago" (click)="list_permission('Supervisor')"> Ver Permisos</button></div>
              </div>
            </div>
          </div>
          <div style="right: 50%; position: absolute; left: 46%;"></div>
        </div><!--bindings={}-->
      </div>
    </div>

  </div>
  
  <div class="contaner-princi">
    <div class="contaner-list" ng-reflect-ng-class="[object Object]">
      <div class="contener-datas">
        <div style="width: 100%; text-align: start;">
          <div
            style="text-align: center; border-bottom: solid 2px #adb5bd59; width: 95%; margin: auto; height: 35px;">
            <label for="" class="info-client"> COBRADOR </label>
          </div>
          <div style="display: block; overflow: hidden; overflow-x: auto;">
         
            <div class="row" style="text-align: center; display: block;">
              <div style="position: absolute;">
                <button style="margin: auto;" class="btn-agend bnt-loans-pago" (click)="list_permission('Cobrador')"> Ver Permisos</button>
              </div>
            </div>
          </div>
        </div>
        <div style="right: 50%; position: absolute; left: 46%;"></div>
      </div><!--bindings={}-->
    </div>
  </div>
</div>