
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
        <div class="card-body data-content">
          <h2>Historico De Caja</h2>
          <div style="text-align: -webkit-center;">
            <div>
              <label for="">Fecha Inicial</label>
              <div>
                <input placeholder="Fecha Inicial" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
              </div>
            </div>
            <div>
              
              <label for="">Fecha final</label>
              <input placeholder="Fecha Inicial" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedFinal" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
            </div>
            <div>
              <i class="fa-solid fa-magnifying-glass"  style="font-size: 21px;padding-bottom: 15px;" (click)="search()"></i>
            </div>
          </div>
          <table class="table table-responsive table-bordered table-hover"  style=" display: block;border-collapse:collapse; text-align: center;">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Inversion</th>
                    <th>Caja Inicial</th>
                    <th>Gastos Diario</th>
                    <th>Prestamos</th>
                    <th>Pagos Diarios</th>
                    <th>Pagos Transferencia</th>
                    <th>Resultado Diario</th>
                    <th>Dinero Total</th>
                    <th>Resultado Total</th>
                </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let dato of datos; let i = index">
                  <tr>
                    <td style="white-space: nowrap;">{{adjustDateToLocale(dato.date)|date:"dd/MM/yyyy"}}</td>
                    <td>{{formatNumber(dato.investment|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.base|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.expenses|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.loans|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.payments|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.total_pix|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.income_dayli|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.saldo_total|number:"1.2-2")}}</td>
                    <td>{{formatNumber(dato.total_balance|number:"1.2-2")}}</td>
                  </tr>
              </ng-container>
          </tbody>
        </table>
          
         
          
         
        </div>
      </div>
    </div>
</div>
