import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
  AfterViewInit,
  Renderer2,
}from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
import { event } from 'jquery';


interface Route {
  id: number,
  routes_id: {
    id: number;
    name: string;
    // Otros atributos
  };
  // Otros atributos de la ruta
}

@Component({
  selector: 'app-form-usuario',
  templateUrl: './form-usuario.component.html',
  styleUrls: ['./form-usuario.component.css']
})
export class FormUsuarioComponent implements OnInit{
  @ViewChildren('itemMonto', { read: ElementRef })

  itemMontos!: QueryList<ElementRef>;

  miFormularioUser: FormGroup = this.formBuilder.group({
    'rol': ['', Validators.required,],
    'cedula': ['', Validators.required],
    'seudonimo': ['', Validators.required],
    'nombre_user': ['', Validators.required,],
    'password1': ['', Validators.required],
    'password2': ['', Validators.required],
    'moneda': ['', Validators.required],
    'ciudad': ['', Validators.required],
    'numero': ['', Validators.required],
    'direccion': ['', Validators.required],
    'wages_id': ['', Validators.required],
    'sucursal_id': ['', Validators.required],
  });
  constructor(private formBuilder: FormBuilder, private routers: Router, private route: ActivatedRoute,public url: Url) {}
   
  router: any;
  dato: any;
  esEdicion = false;
  id = ""
  selectedRouteId: number[] = [];
  selectedConceptId: number[] = [];
  selectedRouteId2: number[] = [];


  @ViewChild('selectBtn') selectBtnRef!: ElementRef;
  @ViewChild('btnText') btnTextRef!: ElementRef;
  @ViewChild('itemsContainer') itemsContainerRef!: ElementRef;


  @ViewChild('selectBtn2') selectBtnRef2!: ElementRef;
  @ViewChild('btnText2') btnTextRef2!: ElementRef;
  @ViewChild('itemsContainer2') itemsContainerRef2!: ElementRef;


  addClickEventListener() {
    const selectBtn = this.selectBtnRef.nativeElement;
    selectBtn.addEventListener('click', () => {
      selectBtn.classList.toggle('open');
    });
  }
  addClickEventListener2() {
    const selectBtn2 = this.selectBtnRef2.nativeElement;
    selectBtn2.addEventListener('click', () => {
      selectBtn2.classList.toggle('open');
    });
  }



  ngAfterViewInit() {
    this.addClickEventListener();
    this.addClickEventListener2();
  }
  ngOnInit(): void {
    this.getSucursal();

    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('dato')) {
        this.dato = JSON.parse(params['dato']);
        this.esEdicion = !!this.dato;
        if (this.dato) {

          const selectElement = document.getElementById('sucursal_id') as HTMLSelectElement;
          selectElement.classList.add('inahiniltys');
          const routes_check = document.getElementById('routes_check') as HTMLInputElement;
          routes_check.classList.add('inahiniltys');

          if("routes" in this.dato){
            var sucusal_id = this.dato.routes?.[0]?.routes_id.branch_id.id
            this.getWages(sucusal_id)
            this.onSucursalChange(this.dato.routes[0].routes_id.branch_id.id, true)
                      
            const routeIds = this.dato.routes.map((route: Route) => route.routes_id.id);
            const route_pk = this.dato.routes.map((route: Route) => route.id);
            this.selectedRouteId = routeIds;
            this.selectedRouteId2 = route_pk;
          }
          else{
            var sucusal_id = this.dato.branch_id
            this.getWages(sucusal_id)
            this.onSucursalChange(sucusal_id, true)
          }
          
          this.miFormularioUser.patchValue({
            'cedula': this.dato.id,
            'seudonimo': this.dato.pseudonym,
            'nombre_user': this.dato.name,
            'ciudad': this.dato.country,
            'numero': this.dato.phone,
            'direccion': this.dato.address,
            'wages_id': this.dato.wages_id.id,
            'sucursal_id': sucusal_id
          });

          
          
        }
      }
      
    });
  } 

  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  click_checks(event: Event) {
        const element = event.currentTarget as HTMLElement;
        element.classList.toggle('checked');
        let checked = document.querySelectorAll(".checked");
        var btnText = document.querySelector(".btn-text") as HTMLElement;
        let selectedTexts: string[] = [];
        checked.forEach((item: Element) => {
          selectedTexts.push(item.textContent || "");
        });

        // Asignamos los textos concatenados al botón
        if (selectedTexts.length > 0) {
          btnText.innerText = selectedTexts.join(", ");
        } else {
          btnText.innerText = "Seleccionar Ruta";
        }
  
  }


  click_checks2(event: Event) {
    const target = event.currentTarget as HTMLElement;
    const itemId = target.querySelector('.item-text2')?.getAttribute('data-value');
    var btnText = document.querySelector(".btn-text2") as HTMLElement;
    if (itemId) {
      
        // Alternar el estado seleccionado
        if (this.selectedConceptId.includes(Number(itemId))) {
            this.selectedConceptId = this.selectedConceptId.filter(id => id !== Number(itemId));
        } else {
            if (this.selectedConceptId.length >= 6) {
                btnText.textContent = 'Conceptos seleccionados';
                this.selectedConceptId.push(Number(itemId));
                return;
          }
            this.selectedConceptId.push(Number(itemId));
        }
        const selectedTexts = this.datos_mode
            .filter(route => this.selectedConceptId.includes(route.income_expense_configuration_id.id))
            .map(route => route.income_expense_configuration_id.name)
            .join(', ');

        // Actualizar el texto del botón
        btnText.textContent = selectedTexts;
    }
}

click_checks_Total(event: Event) {
  const element = event.currentTarget as HTMLElement;
  element.classList.toggle('checked2');
  let checked = document.querySelectorAll(".monto-sele");
  var btnText = document.querySelector(".btn-text2") as HTMLElement;
  let selectedTexts: string[] = [];
  const isAllChecked = element.classList.contains('checked2');

  checked.forEach((item: Element) => {
    item.classList.toggle('checked2', isAllChecked);
    if (isAllChecked) {
      selectedTexts.push(item.textContent || ""); // Agregar texto solo si está marcado
    }
    
  });

  // Asignamos los textos concatenados al botón
  if (selectedTexts.length > 0) {
    if(selectedTexts.length <= 6){
      btnText.innerText = selectedTexts.join(", ");
    }
    if(selectedTexts.length > 6){
      btnText.innerText = "Conceptos seleccionados"
    }
  } else {
    btnText.innerText = "Conceptos";
  }

}

 async ConceptSucursal(id:any,update: any = null){
  if (update != null){
    const token = this.getCookie("_token")
    let user_id =  this.dato.id
    const response = await fetch(this.url.Ms1 + "concepto_sucursal_client/" + user_id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    });
    let info = await response.json() 
    const list_id = info.map((item:any) => item.income_expense_configuration_id.id)
    this.selectedConceptId = list_id
    
  }
  else{
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "concepto_sucursal_client/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    });
    this.datos_mode = await response.json()
  }
  }
  
  datos_sucursal: any[] = [];
  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_sucursal = jsonResponse;
         return this.datos_sucursal;
     }
   }

  async onSucursalChange(event: any, update: any = null) {
    if (update != null){
      this.getRutas(event);
      this.ConceptSucursal(event,true)
      this.getModeLoan()
    }else{
      const selectedValue = event.target.value;
      this.ConceptSucursal(selectedValue)
      this.getRutas(selectedValue);
      this.getWages(selectedValue)

    }

  }

  datos_mode: any[] = [];
  async getModeLoan(): Promise<any> {
    const token = this.getCookie("_token")
    if("routes" in this.dato ){
      var id = this.dato.routes[0].routes_id.branch_id.id
    }
    else{
      var id = this.dato.branch_id
    }
    const response = await fetch(this.url.Ms1 + "concepto_sucursal_client/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_mode = jsonResponse;
         return this.datos_mode;
     }
   }

  selectedItems: string[] = [];
  selectedItemsConcept: string[] = [];
  datos_routes: any[] = [];
  async getRutas(id: any): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "routes/sucursal/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_routes = jsonResponse;
         return this.datos_routes;
     }
   }

   datos_wages: any[] = [];
   async getWages(id:any): Promise<any> {
     const token = this.getCookie("_token")
     const response = await fetch(this.url.Ms1 + "nomina_sucursal/" +id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "ngrok-skip-browser-warning": "69420"
        },
      });
        if (response.ok) {
          const jsonResponse = await response.json();
          this.datos_wages = jsonResponse;
          return this.datos_wages;
      }
    }
  
  async enviarFormularioUser() {
    const token = this.getCookie("_token")
    const id = (document.querySelector('#id') as HTMLInputElement).value
    const pseudonym = (document.querySelector('#seudonimo') as HTMLInputElement).value;
    const password1 = (document.querySelector('#password1') as HTMLInputElement).value;
    const password2 = (document.querySelector('#password2') as HTMLInputElement).value;
    const country = (document.querySelector('#ciudad') as HTMLInputElement).value;
    const name = (document.querySelector('#nombre_user') as HTMLInputElement).value;
    const phone = (document.querySelector('#numero') as HTMLInputElement).value;
    const address = (document.querySelector('#direccion') as HTMLInputElement).value;
    const roles_id =  (document.querySelector('#user_time_zone') as HTMLSelectElement).value;
    const items = this.itemsContainerRef.nativeElement.children;
    const concepts = this.itemsContainerRef2.nativeElement.children;
    const branch_ids = (document.getElementById("sucursal_id") as HTMLSelectElement).value;
    this.selectedItems = [];
    this.selectedItemsConcept = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.classList.contains('checked')) {
        const itemValue = item.querySelector('.item-text');
        const itemText = itemValue.getAttribute('data-value');
        this.selectedItems.push(itemText);
      }
    }

    for (let i = 0; i < concepts.length; i++) {
      const concept = concepts[i];
      if (concept.classList.contains('checked2')) {
        const itemValue = concept.querySelector('.item-monto.monto-sele .item-text2');
        if (itemValue) {
          console.log(itemValue);
          const itemText = itemValue.getAttribute('data-value');
          this.selectedItemsConcept.push(itemText);
          console.log(this.selectedItemsConcept)
        } else {
          console.log('itemValue is null or undefined');
        }
      }
    }
    const routes_id = this.selectedItems
    const concept = this.selectedItemsConcept
    const wages_id = (document.querySelector('#wages_id') as HTMLSelectElement).value;
 
    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Telefono solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 

    if (!id || !roles_id || !pseudonym || !password1 || !password2 || !address || !phone || !country || !name || !wages_id || !concept) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,

      });
      return;
    }



    if (password1 !== password2) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Las contraseñas no son iguales...',
        showConfirmButton: false,

      });
      return;
    }
    const formdataUser = {
      id,
      name,
      pseudonym,
      password: password1,
      address,
      phone,
      country,
      branch_id: branch_ids,
      roles_id,
      ...(routes_id.length > 0 && { routes_id }),
      ...(wages_id !== "" && { wages_id }),
      ...(concept.length > 0  && { concept })
    };
    try {
      const response = await fetch(this.url.Ms1 + "users", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      });
        if (response.ok) {
          const result = await response.json()
          if('error' in result){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Error al guardar el formulario...\n\n',
              text: 'Al parecer este Usuario ya se encuentra registrado.',
              showConfirmButton: false,
            });
            return
          }
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              this.routers.navigate(['/super-usuario']);              
            }
          });
          // Vaciar los campos de entrada del formulario
          const elementos = ['#seudonimo','#id', '#password1', '#password2', '#direccion', '#numero', '#ciudad', '#nombre_user', "#routes_id"];
          for (let i = 0; i < elementos.length; i++) {
          (document.querySelector(elementos[i]) as HTMLInputElement).value = '';
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al guardar el formulario...\n\n',
          text: 'Al parecer este Usuario ya se encuentra registrado.',
          showConfirmButton: false,
        });
        return
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }


  }  

  convertToUppercase(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  async actualizarFormularioUser(formulario: any){
    const token = this.getCookie("_token")
    const pseudonym = formulario.seudonimo
    const address = formulario.direccion
    const phone = formulario.numero
    const country = formulario.ciudad
    const name = formulario.nombre_user
    const wages_id = formulario.wages_id
    const id = formulario.cedula
    const id_user_route = this.selectedRouteId2
    const concepts = this.itemsContainerRef2.nativeElement.children;

    for (let i = 0; i < concepts.length; i++) {
        const concept = concepts[i];
        if (concept.classList.contains('checked2')) {
          const itemValue = concept.querySelector('.item-monto.monto-sele .item-text2');
          if (itemValue) {
            const itemText = itemValue.getAttribute('data-value');
            this.selectedItemsConcept.push(itemText);
          } else {
            console.log('itemValue is null or undefined');
          }
        }
      }
    const concept = this.selectedItemsConcept

    let checked = document.querySelectorAll(".checked");
    let selected2: string[] = [];
    selected2 = Array.from(checked).map((item: Element) => {
      return item.querySelector('.item-text')?.getAttribute('data-value') || '';
    });

    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Telefono solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 

    if (!pseudonym || !address || !phone || !country || !name || !wages_id) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }


    const formdataUser = {
      id,
      name,
      pseudonym,
      address,
      phone,
      country,
      wages_id,
      routes: selected2,
      id_user_route,
      incomes: concept
    };
    try {
      const response = await fetch(this.url.Ms1 + "users/" + this.dato.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataUser)
      })
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            this.routers.navigate(['list-usuario']);
          }
      });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al actualizar Usuario...\n\n',
          text: 'validad informacion',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        });
      }
    }
    catch (error) {
      console.error('Error al enviar el formulario', error);
    }

    

  }
 

}

