
<div class="menu-container" style="position: relative;">

  <app-menu-super-user></app-menu-super-user>
  <h4>Lista movimientos</h4>
  <div style="width: 100%; border-right: solid 2px #adb5bd59; font-size: 16px; padding-left: 10px; overflow-y: scroll;">
    <table aria-describedby="DataTables_Table_0_info" role="grid" width="100%" id="basic"
      class=" zui-table dataTable  table table-striped table-bordered table-hover">
      <thead>
        <tr role="row">

          <th><span  class="sort-by">Fecha</span></th>
          <th style="min-width: 90%;"><span  class="sort-by">Nombre</span></th>
          <th><span  class="sort-by">Tipo</span></th>
          <th style="min-width: 115px;"><span  class="sort-by">Valor</span></th>
          <th style="min-width: 115px;"><span  class="sort-by">Creado por</span></th>
          <th style="min-width: 115px;"><span  class="sort-by">Comprobante</span></th>
          <th><span  class="sort-by">Accion</span></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let dato of datos">
          <td>
            {{ fechaFormateada(dato.motion_date) }}
          </td>
          <td>
            {{dato.income_expense_configuration_id.name}}
          </td>
          <td>
            {{typeMovimiento(dato.income_expense_configuration_id.type)}}
          </td>
          <td>
            {{ formatearNumero(dato.motion_total) }}
          </td>
          <td>
            {{dato.user_register?.name}}
          </td>
          <td style="text-align: center;">
            <button style="margin:auto;" class="btn btn-primary" *ngIf="dato.pic_id" (click)="showReceipt(dato.pic_id)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
                <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z"/>
              </svg>
            </button>
          </td>
          <td *ngIf="dato.income_expense_configuration_id.type != 'Inversion'">
            <button class="btn btn-danger" *ngIf="isToday(dato.motion_date)" (click)="DeleteMovimiento(dato.id)">Eliminar</button>
          </td>
          <td *ngIf="dato.income_expense_configuration_id.type == 'Inversion'">
            
          </td>
        </tr>

      </tbody>
    </table>
  </div>

</div>