import { Component, EventEmitter, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { faArrowRight, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
import { FormRutaComponent } from '../form-ruta/form-ruta.component';
@Component({
  selector: 'app-list-rutas',
  templateUrl: './list-rutas.component.html',
  styleUrls: ['./list-rutas.component.css']
})
export class ListRutasComponent {

  constructor(private router: Router,public url: Url, private parames: ActivatedRoute,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}

  selectedItems: any[] = [];
  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;
  faArrowRight = faArrowRight;
  datos_ruta: any[] = [];
  permissions: any = {}

  ngOnInit(): void {
    this.getRoutes();

    document.cookie = `_cash=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `_route=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    this.permissions = Permission.ElementPermissionObjects()

    $(document).ready(function() {
    $(".btns-nominas").click(function() {
      $(".fab-menu2").slideToggle("fast", function() {

      });
    });
  })

  }

  listPayNomina(){
      let ids = this.getCookie("_branch")
      this.router.navigate(['list-wages-payment',ids]);
     
  }

  ListaNomina(){
    let optionsHtml = this.datos_ruta.map(ruta => {
      return `<option value="${ruta.id}">${ruta.name}</option>`;
    }).join('');

    let htmlContent = `
      <label for="select_payment">Rutas</label>
      <select class="form-control input-form" name="select_payment" id="select_payment">
        <option selected disabled value="">--Seleccionar--</option>
        ${optionsHtml}
      </select>
  `;

    Swal.fire({
      title: 'Seleccionar ruta',
      text: "Selecciona una ruta para ver sus nominas",
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonColor: '#0e658e',
      confirmButtonText: 'Consultar',
      html: htmlContent,
      preConfirm: () => {
        const selectedRoute = (document.getElementById('select_payment') as HTMLSelectElement).value;
        if (!selectedRoute) {
          Swal.showValidationMessage('Por favor, selecciona una ruta.');
        }
        return selectedRoute;
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.router.navigate(['pago-nomina',result.value]);
      }

    })
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async getUserRoute(): Promise<any> {
    const token = this.getCookie("_token")
    const id = this.parames.snapshot.params['id'];
    const response = await fetch(this.url.Ms1 + "routes_r/users/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    if (response.ok) {
      // informacion para actualizar ruta
       const jsonResponse = await response.json();
       console.log(jsonResponse)
    }
  }
  
  async getRoutes(): Promise<any> {
    const token = this.getCookie("_token")
    const id = this.parames.snapshot.params['id'];
    if(id == "null"){
      alert("Este usuario no tiene rutas")
      return
    }
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_branch" + "=" + id + ";" + expiresStr + ";path=/";


    const response = await fetch(this.url.Ms1 + "sucursal/routes_r/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
        // informacion para actualizar ruta
         const jsonResponse = await response.json();
         this.datos_ruta = jsonResponse;

       
     
        return this.datos_ruta;
     }

   }

   borrar( dato: any ){
    const token = this.getCookie("_token")
    Swal.fire({
      title: 'Seguro quieres eliminar \n',
      text: dato.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(this.url.Ms1 + "routes/" + dato.id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
        })
        let restulsta = await response.json();
        if(restulsta.message == "La ruta tiene clientes"){
          Swal.fire({
            icon: 'error',
            title: 'La ruta tiene clientes \n',
            text: dato.nombre,
            showConfirmButton: false,
          })
        }
        else{
          Swal.fire({
            icon: 'success',
            title: 'Eliminado \n',
            text: dato.nombre,
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
            
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
          
        }
       
      }
    })
  }

  actualizar(datos_ruta: any) {
    // Elimina las referencias circulares si existen
    this.eliminarReferenciasCirculares(datos_ruta);
  
    const navigationExtras: NavigationExtras = {
      queryParams: { dato: JSON.stringify(datos_ruta) }
    };
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormRutaComponent);
    const componentRef: ComponentRef<FormRutaComponent> = this.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.dato = datos_ruta;
    
    Swal.fire({
      title: 'Rutas',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })

  
    // this.router.navigate(['form-ruta'], navigationExtras);
  }

  DetalleRoutes(id: any){
    this.router.navigate(['details-route', id]);
  }

  // Función para eliminar referencias circulares en un objeto
  eliminarReferenciasCirculares(obj: any, visited = new WeakSet()) {
    if (typeof obj === 'object' && obj !== null) {
      if (visited.has(obj)) {
        // Si ya hemos visitado este objeto, lo eliminamos para evitar ciclos
        delete obj.elemento;
        // Continuamos con otras propiedades si es necesario
        // ...
  
        return;
      }
      visited.add(obj);
  
      // Recorremos las propiedades del objeto para eliminar referencias circulares
      for (const prop in obj) {
        this.eliminarReferenciasCirculares(obj[prop], visited);
      }
    }
  }

  verInfoRoutes(dato: any) {
    dato.isRotated = !dato.isRotated;
    dato.isConfigOpen = !dato.isConfigOpen;
    const index = this.selectedItems.indexOf(dato.id);
    if (index >= 0) {
        this.selectedItems.splice(index, 1);
    } else {
        this.selectedItems.push(dato.id);
    }
  }

  FormNomina(){
    this.router.navigate(['nomina']);
  }

  FormCaja(id: any){
    this.router.navigate(['form-caja', id]);
  }

  FormFestivos(){
    this.router.navigate(['form-festivos']);
  }
  
  FormConceptos(){
    this.router.navigate(['form-concepto']);
  }

  FormModalidad(){
    this.router.navigate(['form-modalidad']);
  }

  formatCounty(time:any){
    let id_stw = "";
    switch (time) {
      case "America/Sao_Paulo":
      case "America/Manaus":
      case "America/Fortaleza":
        id_stw = "BR";  // Brasil
        break;
      case "America/Argentina/Buenos_Aires":
        id_stw = "AR";  // Argentina
        break;
      case "America/Santiago":
        id_stw = "CL";  // Chile
        break;
      case "America/Bogota":
        id_stw = "CO";  // Colombia
        break;
      case "America/Lima":
        id_stw = "PE";  // Perú
        break;
      case "America/Mexico_City":
        id_stw = "MX";  // México
        break;
      case "America/Panama":
        id_stw = "PA";  // Panamá
        break;
      case "America/Caracas":
        id_stw = "VE";  // Venezuela
        break;
      case "America/Guyana":
        id_stw = "GY";  // Guyana
        break;
      case "America/Asuncion":
        id_stw = "PY";  // Paraguay
        break;
      case "America/La_Paz":
        id_stw = "BO";  // Bolivia
        break;
      case "America/Montevideo":
        id_stw = "UY";  // Uruguay
        break;
      case "America/Cayenne":
        id_stw = "GF";  // Guayana Francesa
        break;
      case "America/Paramaribo":
        id_stw = "SR";  // Surinam
        break;
      default:
        id_stw = "Timezone not found";
    }
    return id_stw
  }

  Cliente(ids: any, cash: any,time_zone:any,prefix:any){
    let time_z = this.formatCounty(time_zone)
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_route" + "=" + ids + ";" + expiresStr + ";path=/";
    document.cookie = "_cash" + "=" + cash + ";" + expiresStr + ";path=/";
    document.cookie = "_IdCountry" + "=" + time_z + ";" + expiresStr + ";path=/";
    document.cookie = "_Prefix" + "=" + prefix + ";" + expiresStr + ";path=/";


    const rol = this.getCookie("_rol");
    const inicio = this.getCookie("_inicio")
    const final = this.getCookie("_final")
    const dates = new Date();
    const currentHour = dates.getHours();
    const currentMinute = dates.getMinutes();
    if (inicio && final) {
      const startHour = parseInt(inicio.split(":")[0]);
      const startMinute = parseInt(inicio.split(":")[1]);
      
      const endHour = parseInt(final.split(":")[0]);
      const endMinute = parseInt(final.split(":")[1]);

      if (rol != "SuperUsuario") {
        if (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) {
            if (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
                this.router.navigate(['list-loans']);
            } else {
                alert("La sucursal esta inactiva por hora de cierre");
            }
        } else {
            alert("La hora actual es anterior al horario de inicio permitido.");
      }
    } else {
        this.router.navigate(['list-loans']);
    }

    }

  }
 
}
