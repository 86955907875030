
<div class="menu-container">
<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content" style="overflow-y: scroll; overflow-x: hidden;">
        <h2 style="display: inline;">Caja</h2>
      
        <form autocomplete="off" class="form" name="miFormularioCash"  [formGroup]="miFormularioCash" #formularioUser="ngForm" (ngSubmit)="postCash()">
          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Fecha Caja</label>
            <div class="col-lg-8 col-12 inputs">
              <input placeholder="Fecha Inicial" type="text" id="dates" name="dates" class="form-control input-form" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" formControlName="dates">
              </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Pagos Transferencia</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="total_pix" name="total_pix" placeholder="Selecciona un dia" formControlName="total_pix">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Inversion</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="investment" name="investment" placeholder="Inversion" formControlName="investment">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Caja inicial</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="base" name="base" placeholder="base" formControlName="base">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Prestamos</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="sale" name="sale" placeholder="Ventas" formControlName="sale">
            </div>
          </div>
      
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Gastos Diario</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="expenses" name="expenses" placeholder="Egreso"  formControlName="expenses">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Pagos Diario</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="nombre_concepto" name="nombre_concepto" placeholder="Ingreso"  formControlName="income">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Resultado Diario</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="ingresos_diarios" name="ingresos_diarios" placeholder="Ingreso"  formControlName="income_dayli">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Resultado total: (Efectivo + Transferencia)</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text"  id="total_balance" name="total_balance" placeholder="Saldo Acumulado" formControlName="total_balance">
            </div>
          </div>
          

          <div class="form-group row" style="display: none;">
            <label class="col-lg-3 col-form-label form-control-label">Detalle</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form" type="text" id="detalle_investment" name="detalle_investment" placeholder="Detalle"  formControlName="detalle_investment">
            </div>
          </div>
          
          <div class="form-group row" style="margin-bottom: 160px !important;">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs" style="text-align: center;">
              <input class="btn btn-primary subtmit-bt" style="width: 40vw;" type="button" (click)="HistoryCash()" value="Historico De Caja"> 
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
