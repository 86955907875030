import { Component, EventEmitter, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { Router } from '@angular/router';
import { FormRutaComponent } from '../form-ruta/form-ruta.component';
import Swal from 'sweetalert2';
import { FormSucursalComponent } from '../form-sucursal/form-sucursal.component';
import { FormFestivosComponent } from '../form-festivos/form-festivos.component';
import { Url, Permission } from '../service/data.service';
@Component({
  selector: 'app-super-usuario',
  templateUrl: './super-usuario.component.html',
  styleUrls: ['./super-usuario.component.css']
})
export class SuperUsuarioComponent {
  sucursal = [{ label: 'Nueva Sucursal' }];
  isRotated = false;
  isConfigOpen = false;
  showListSucursa = true;
  permissions: any = {}
  
  @Output() llamarFuncion = new EventEmitter<void>();

  constructor(private router: Router,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}

  onMenuItemClick(item: any) {
    if (item === 'Usuario') {
      this.router.navigate(['form-usuario']);
    } else if (item === 'Ruta') {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormRutaComponent);
      const componentRef: ComponentRef<FormRutaComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Rutas',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })
    }else if (item === 'Festivos'){

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormFestivosComponent);
      const componentRef: ComponentRef<FormFestivosComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Crear Festivos',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })

    }
  }

  ngOnInit(): void {
    this.permissions = Permission.ElementPermissionObjects()
  }

  onSucursal(item: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormSucursalComponent);
      const componentRef: ComponentRef<FormSucursalComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Sucursal',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })
  }


  goBack() {
    // this.router.navigateByUrl('');
  }

  openConfig() {
    this.isRotated = !this.isRotated;
    this.isConfigOpen = !this.isConfigOpen;
  }


  funcionLlamada(){
   this.showListSucursa = false;
    this.llamarFuncion.emit();
  }

}
