
<div class="menu-container" style="position: relative;">

  <app-menu-super-user></app-menu-super-user>

  <div class="list-sucursa">
    <h3 style="text-align: center;">Lista permisos roles</h3>

    <div class="contaner-princi">
      <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(5)}" *ngFor="let dato of ListGroupPermissions">
        <div class="contener-datas">
          <div style="width: 100%; text-align: start;">
            <div
              style="text-align: center; border-bottom: solid 2px #adb5bd59; width: 95%; margin: auto; height: 35px;">
              <label for="" class="info-client">{{dato.name}}</label>
            </div>
            
            
          </div>
          <div style="right: 50%; position: absolute; left: 46%;">
            <i class="back" (click)="verInfoLoan(dato)"  >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
          </i>
           
          </div>
          
        </div>
        <div class="config-menu"  [ngClass]="{'show': dato.isConfigOpen}">
          <div class="info-loanClient">
            <div style="display: flex; width: 100%; margin: 0px 5%;">
              <div style="font-size: 14px; width: 50%; text-align: center; background-color: lightgray;">
                <b>Permisos</b> 
              </div>
              <div  style="font-size: 14px; width: 50%; text-align: center; background-color: lightgray;">
                <b>Activo</b> 
              </div>
       
            </div>
        
        </div>
        <div style="display: flex; width: 90%; margin: 3px 5%;" *ngFor="let permission of dato.permissions" >
          <div style="font-size: 14px; width: 90%; text-align: center;">
            <div style="display: table; margin: 0px auto; width: 100%;  text-align: start;">
              <b style="text-transform: uppercase;">{{permission.tipo}}</b> 
            </div>
          </div>
          <div  style="font-size: 14px; width: 10%; text-align: center;">
            <li class="item-monto principal_dos" [ngClass]="{'checked2': !isPermissionSelected(permission.id),'not_checked2': isPermissionSelected(permission.id)}"  (click)="checkPermissos($event)">
              <span class="checkbox" >
                <i class="fa-solid fa-check check-icon" [attr.data-modulo]="dato.name" [attr.data-name]="permission.tipo" [attr.data-value]="permission.id" ></i>
              </span>
            </li>
          </div>
        </div>



      </div>
    </div>

    </div>

    <button ype="button" class="save" (click)="submitPermissions()">Guardar</button>



</div>
