import { Component, EventEmitter, Output,ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { faArrowDown, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
import * as $ from 'jquery'; // Importa jQuery
import { add } from 'ngx-bootstrap/chronos';
import { JsonPipe } from '@angular/common';
import { PaymentsComponent } from '../payments/payments.component';
import { LoanRefinanceComponent } from '../loan-refinance/loan-refinance.component';
import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { AlertPaymentComponent } from '../alert-payment/alert-payment.component';
import { FormMovimientosComponent } from '../form-movimientos/form-movimientos.component';
import { FormLoansComponent } from '../form-loans/form-loans.component';
import { FormClienteComponent } from '../form-cliente/form-cliente.component';
import { UpdateClientComponent } from '../update-client/update-client.component';
import Sortable from 'sortablejs';

interface CardElement extends HTMLElement {
  id: string;
}
export async function ChangeViewLoans(optionValue: any) {
  try{
    const selectElement = document.getElementById('select-search') as HTMLSelectElement;
    const options = selectElement.options;
    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.value === optionValue) {
            selectElement.selectedIndex = i;
            const event = new Event('change');
            selectElement.dispatchEvent(event);
            break;
        }

      }
  }catch{
    return
  }

}

interface SortableEvent extends Event {
  item: CardElement;
  newIndex?: number;
  oldIndex?: number;
}


@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.css'],
})
export class ListClientComponent {

  constructor(private router: Router,public url: Url,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {
    this.selectedDates = new Date();
  }
  selectedDates: Date;
  selectedItems: any[] = [];
  faArrowDown = faArrowDown;
  faArrowRight = faArrowRight;
  isConfigOpen = false;
  revision: any = [];
  originalDatos: any[] = [];
  isRotated = false;
  sale_info_origi: any[] = [];
  prestamosClientesDelDia: any[] = [];
  prestamosPagosDelDia: any[] = [];
  selectedOption: string = "Clientes del dia";
  pagos_dia: any[] = [];
  list_filter_sale: any[] = [];
  filter_data = false;
  permissions: any = {}

  ngOnInit(): void {
    this.getRevisions()
    this.onSelectChange(this.selectedOption);
    this.permissions = Permission.ElementPermissionObjects()
    $(document).ready(function() {
      $(".fab-button-client").click(function() {
        $(".fab-menu2").slideUp("fast");
        $(".fab-menu3").slideUp("fast");
        $(".fab-menu").slideToggle("fast", function() {
        
        });
      });
      $(".fab-button-agendar").click(function() {
        $(".fab-menu").slideUp("fast");
        $(".fab-menu3").slideUp("fast");
        $(".fab-menu2").slideToggle("fast", function() {

        });
      });
      
      $(".fab-button-movimientos").click(function() {
        $(".fab-menu").slideUp("fast");
        $(".fab-menu2").slideUp("fast");
        $(".fab-menu3").slideToggle("fast", function() {
        
        });
      });


    });
  }

  getFormattedCurrency(value: any): string {

    let formattedValue = Number(value).toFixed(2);
    formattedValue = formattedValue.replace('.', ',');
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `$${formattedValue}`;
  }


  async getRevisions(){
    const token = this.getCookie("_token")
    const id = this.getCookie("_route")
    const response = await fetch(this.url.Ms1 + "get_revisions/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
      if (response.ok) {
        const jsonResponse = await response.json();
        jsonResponse.sort((a:any, b:any) => b.revision - a.revision);
        if(jsonResponse.length == 0){
          this.revision = false
        }
      else{
        this.revision = true;
      } 
      }
  }

  RamdomColor(): string {
    const colors = ['CRITICO', 'ADVERTENCIA', 'VENCIDO', 'CERTIFICAR'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  esMismaFecha(fecha1: Date, fecha2: Date): boolean {
    return (
      fecha1.getFullYear() === fecha2.getFullYear() &&
      fecha1.getMonth() === fecha2.getMonth() &&
      fecha1.getDate() === fecha2.getDate()
    );
  }

  infos(datos: any): any{
    let alerta = ''
    let message = ''
    let color = ''
    if(datos.customers_id.color != null){
      switch (datos.customers_id.color){
        case 'rojo':
          alerta = "Advertencia"
          message = "Cliente sin pagos continuos"
          color = "red"
        break
        case 'cafe':
          alerta = "Vencido"
          message = "Cliente con días en mora"
          color = "#795548"
        break
        case 'amarillo':
          alerta = "Critico"
          message = "Cliente sin capacidad de pago"
          color = "#ffc107"
        break
        case 'naranja':
          alerta = "Certificar"
          message = "Validar crédito con el cliente"
          color = "#ff9800"
        break
        case 'verde':
          alerta = "Activo"
          message = "Cliente activos"
          color = "#7dc924"
        break
      
      }
    }
    else{
      switch (datos.alert){
        case 'rojo':
          alerta = "Advertencia"
          message = "Cliente sin pagos continuos"
          color = "red"
        break
        case 'cafe':
          alerta = "Vencido"
          message = "Cliente con días en mora"
          color = "#795548"
        break
        case 'amarillo':
          alerta = "Critico"
          message = "Cliente sin capacidad de pago"
          color = "#ffc107"
        break
        case 'naranja':
          alerta = "Certificar"
          message = "Validar crédito con el cliente"
          color = "#ff9800"
        break
        case 'verde':
          alerta = "Activo"
          message = "Cliente activos"
          color = "#7dc924"
        break
      
      }
     
    }
     return { color: color, alerta: alerta, message: message };
   
  }
  


  datos: any[] = [];
  async onSelectChange(option:any = null){
    const route = this.getCookie("_route")
    const token = this.getCookie("_token")
    let loader = document.getElementById("conten_loads")
    if (loader) {
      loader.style.display = "block";
    }


    if (option === 'Clientes del dia') {
      this.RamdomColor()
      const date = new Date();
      const day = date.getUTCDate().toString().padStart(2, '0');
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = date.getUTCFullYear();
      this.prestamosClientesDelDia = []
     
      let alert_p = {
        id: route,
        date:`${year}/${month}/${day}`
      }

      const response_alert = await fetch(this.url.Ms1 + "daily_customers", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(alert_p)
      });
      

      const alertData = await response_alert.json();
        if (alertData && Array.isArray(alertData)) {
          let loader = document.getElementById("conten_loads")
          if (loader) {
            loader.style.display = "none";
          }
          this.prestamosClientesDelDia.push(...alertData);
      }

      this.datos = alertData.filter((dato: any) => {
        const isActiveState = dato.state === "Activo";
        return  isActiveState;
    });
      
      const paymentLast = this.prestamosClientesDelDia.filter(loan => loan.alert_payment_detail === "Pago mas tarde");
      const daily = this.prestamosClientesDelDia.filter(loan => loan.alert_payment_detail !== "Pago mas tarde");
      this.prestamosClientesDelDia = [
        {
            "payment_last": paymentLast,
            "daily": daily
        }
    ];
      this.list_filter_sale = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: [] },
        { datos: this.datos },
        { sale_day: [] }
      ]
      this.originalDatos = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: this.prestamosPagosDelDia },
        { datos: this.datos },
        { sale_day: []}
      ]
    }
  
    if (option === 'Ventas del dia') {
      let sale_info_refinance:any = []
      const response_sale_day = await fetch(this.url.Ms1 + "get_loans_maked_today/" + route,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420"
        },
      });

      let sale_info = await response_sale_day.json()
      if(sale_info){
        let refinance: { refinance_id: any }[] = sale_info.filter((item:any) => item.refinance_id !== null);
        sale_info = sale_info.filter((item:any) => 
          !refinance.some((ref_item:any) => ref_item.refinance_id === item.refinance_id)
        );
        sale_info_refinance = refinance
        let loader = document.getElementById("conten_loads")
        if (loader) {
          loader.style.display = "none";
        }
      }
      
      this.list_filter_sale = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia:[] },
        { datos: this.datos },
        { sale_day: sale_info  },
        { refinance_day: sale_info_refinance }
      ]
      this.originalDatos = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: this.prestamosPagosDelDia },
        { datos: this.datos },
        { sale_day: sale_info }
      ]
      this.sale_info_origi = sale_info


    }

    if (option === 'Pagos del dia') {
      const pagos_ids = await  fetch(this.url.Ms1 + "get_loans_by_payments_today/" + route, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420"
        },
      });
      let result = await pagos_ids.json()
      if(result){
        let loader = document.getElementById("conten_loads")
        if (loader) {
          loader.style.display = "none";
        }
      }
      this.list_filter_sale = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: result },
        { datos: this.datos },
        { sale_day: [] }
      ]
      this.originalDatos = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: result },
        { datos: this.datos },
        { sale_day: [] }
      ]

    }
    if (option === 'Clientes de la ruta') {
      const response = await fetch(this.url.Ms1 + "prestamo/ruta/" + route, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420"
        },
        body:JSON.stringify({
          route_id: route,
          page: 1, 
          quantity: 600
        })
      });
      const jsonResponse = await response.json();

      this.datos = jsonResponse.filter((dato: any) => {
        const isActiveState = dato.state === "Activo";
        return  isActiveState;
        });
        if(jsonResponse){
          let loader = document.getElementById("conten_loads")
            if (loader) {
              loader.style.display = "none";
            }
        }
      this.list_filter_sale = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: [] },
        { datos: this.datos },
        { sale_day: [] }
      ]
      this.originalDatos = [
        { prestamosClientesDelDia: this.prestamosClientesDelDia },
        { prestamosPagosDelDia: this.prestamosPagosDelDia },
        { datos: this.datos },
        { sale_day: [] }
      ]
      setTimeout(() => {
        this.changeOrderBy();
      }, 100);
    }
  }

  async changeOrderBy(){
    const token = this.getCookie("_token")
    const cardList = document.querySelector('#containers_cards') as HTMLElement;
    const sortable = new Sortable(cardList, {
      animation: 400,
      ghostClass: 'ghost',
      delay: 200,
      onEnd: (evt: SortableEvent) => {
        const cardElements = cardList.querySelectorAll('.card_ruta') as NodeListOf<CardElement>;
        const cardIds = Array.from(cardElements).map(card => {
          const dataLoanAttr = card.getAttribute("data-loan");
          return dataLoanAttr ? parseInt(dataLoanAttr) : 0;
        });
        const response = fetch(this.url.Ms1 + "change_order",{
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420"
          },
          body:JSON.stringify({
            cardIds
          })
        })
      }
    });
    
  }
   
  filterList(searchTerm: any): void {
    const info =  searchTerm?.target?.value?.toLowerCase() || '';
    if (!info) {
      this.filter_data = false;
      this.list_filter_sale = this.originalDatos
    } else {
      this.filter_data = true;
      this.list_filter_sale[2].datos = this.datos.filter(item =>
        (item.customers_id.id.toString().includes(info) ||
        item.customers_id.name.toLowerCase().includes(info) ||
        item.customers_id.customer_detail_id.surname.toLowerCase().includes(info))
        
      );
    }
    
  }
  VerifikClient(datos: any){
    let  htmlContent = ""
    let outstandingBalance = parseFloat(datos.loan_details_id.outstanding_balance)
    let formattedOutstandingBalance = "N/A";
    formattedOutstandingBalance = outstandingBalance.toFixed(2);
    htmlContent += `
    <div>
      <label for="">Saldo prestamo</label>
      <input id="inputPrestamo" disabled class="form-control input-form" type="number" inputmode="numeric" value="$ ${formattedOutstandingBalance}">
      <br>
      <label for="">Saldo cliente</label>
      <input id="inputCliente" class="form-control input-form" type="number" inputmode="numeric">
      <br>
      <b>Total: $<span id="totalValid">0</span></b>
    </div>
 `
    Swal.fire({
      title: "Verificar cliente",
      html: htmlContent,
      showCloseButton: true,
      showConfirmButton: true,  
      confirmButtonText: 'Pagar',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0e658e',
      onOpen: () =>{
        document.getElementById('inputCliente')?.addEventListener('input', function(e){
          let value_client = parseFloat(datos.loan_details_id.outstanding_balance)
          let value_prestamo = parseFloat((e.target as HTMLInputElement).value);
          let total = (value_client - (isNaN(value_prestamo) ? 0 : value_prestamo)).toFixed(2);
          let info = (document.getElementById('totalValid') as HTMLInputElement).value = total.toString();
          if(parseFloat(total) < 0){
            document.getElementById('totalValid')!.innerText = total.toString().replace("-","");
          }
          else{
            document.getElementById('totalValid')!.innerText = "-"+total.toString();
          }

        })
      }
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        let totals = (document.getElementById('totalValid') as HTMLInputElement).value
        let info = await this.verifkPost(datos,totals)
        
      }
    })

  }

  async verifkPost(loan: any,value_total: any){
    const token = this.getCookie("_token")
    const chas_flow_id = this.getCookie("_cash")
    const sucursal = this.getCookie("_branch")
    var fecha = new Date(this.selectedDates);
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1; // Los meses empiezan desde 0
    var año = fecha.getFullYear();
    var fechaFormateada = dia + "/" + mes + "/" + año;
    const date_payment = fechaFormateada
    const id = loan.id
    const detail_id = parseFloat(loan.loan_details_id.id)
    const numericValue = parseFloat(loan.loan_details_id.positive_balance)
    let saldos = parseFloat(numericValue.toFixed(2));
    const values = parseFloat(value_total)
    const pendiente = parseFloat(loan.loan_details_id.outstanding_balance)
    let valor_cuota = ""
    let value_cuota_rela = loan.fee_payment


    function DefinirSaldo(Cuota:number, Saldo:number, Pago:number) {
      Pago = Pago + Saldo;
      Saldo = Pago % Cuota;
      return parseFloat(Saldo.toFixed(2));
    }
    
    let saldoCalc=DefinirSaldo(parseFloat(value_cuota_rela),saldos,values)
    
    const data: {
        date: string;
        fee_payment: string;
        totla_value: number;
        number_installments: number;
        loans_id: any;
        chas_flow_id: string | null;
        missing_installments: number;
        outstanding_balance: number;
        detail_id: number;
        positive_balance: number;
        state?: string; // Declarar 'state' como una propiedad opcional
        is_adjust: boolean,
        sucursal_id: any;
    } = {
        date: date_payment,
        fee_payment: valor_cuota,
        totla_value: parseFloat(values.toFixed(2)),
        number_installments: 0,
        loans_id: id,
        chas_flow_id: chas_flow_id,
        missing_installments: 0,
        outstanding_balance: parseFloat(pendiente.toFixed(2)),
        detail_id: detail_id,
        positive_balance: saldoCalc,
        is_adjust: true,
        sucursal_id: sucursal,
    };
    
    const response = await fetch(this.url.Ms1 + "pago_loan", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
        return
    }
    else{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Este prestamo ya fue previamente revisado.',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      })
      return
    }

    }

  verInfoLoan(dato: any) {
    dato.isRotated = !dato.isRotated;
    dato.isConfigOpen = !dato.isConfigOpen;
    const index = this.selectedItems.indexOf(dato.id);
    if (index >= 0) {
        this.selectedItems.splice(index, 1);
    } else {
        this.selectedItems.push(dato.id);
    }
  }

  dateFrecuency(dato: any){
    const start_date_str = dato.start_date
    const datePart = start_date_str.substring(5, 16); // "16 Jan 2024"
    const start_date = new Date(datePart);
    const day = start_date.getDate();
    const nombresDias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    let frecuentes = ''
    switch (dato.frequency){
      case 'Quincenal':
        frecuentes = day.toString() + "/" + ((day + 15) % 30).toString()
      break
      case 'Mensual':
        frecuentes = day.toString()
      break
      case 'Semanal':
        const dayOfWeek = start_date.getDay();
        frecuentes = nombresDias[dayOfWeek];
      break
      default:
        frecuentes = dato.frequency
    }
    return frecuentes
  }

  formattedExpiryDate(dato: any): any {
    const date_fecha = new Date(dato);
    // Configurar el idioma español
    const spanishDatePipe = new DatePipe('es-ES');
    console.log( spanishDatePipe.transform(date_fecha, 'dd MMM yy'))
    return spanishDatePipe.transform(dato, 'dd MMM yy');
  }

  async getSucursal(): Promise<any>{
    const token = this.getCookie("_token")
    const id = this.getCookie("_branch")
    const response = await fetch(this.url.Ms1 + "routes/sucursal/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    let info = await response.json()
    return info
  }

  async SendWhatsappPhone(phone: any){
  const info =  await this.getSucursal()
  let country = info[0].branch_id.country

  const countryCodeURL = 'https://restcountries.com/v3.1/name/' + country;
    fetch(countryCodeURL)
      .then(response => response.json())
      .then(data => {
        const CountryCode = data[0].idd.root + data[0].idd.suffixes[0]
        if (CountryCode){
          const phoneNumber = phone; // El número de teléfono al que quieres enviar un mensaje por WhatsApp
          const message = 'Envio mensaje.';
          const whatsappLink = `https://api.whatsapp.com/send/?phone=${CountryCode}${phoneNumber}&text=${encodeURIComponent(message)}`;
          window.open(whatsappLink, '_blank');
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'No se puedo localizar el indicativo',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        })
      });
  }

  loansForm(){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormLoansComponent);
    const componentRef: ComponentRef<FormLoansComponent> = this.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.options_seelec = this.selectedOption;

    Swal.fire({
      title: 'Prestamos',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })  
  }
  
  ClientForm(){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormClienteComponent);
    const componentRef: ComponentRef<FormClienteComponent> = this.viewContainerRef.createComponent(componentFactory);
    Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })
   
  }

  ListVerifik(){
    const ruta = this.getCookie("_route")
    this.router.navigate(['list-verik', ruta]);
  }

  ClientList(){
    this.router.navigate(['list-client-ruta']);
  }
  AgendartList(){
    this.router.navigate(['list-agendar-loan']);
  }

  MovimientoList(){
    const ruta = this.getCookie("_route")
    this.router.navigate(['list-movimiento', ruta]);
  }
  ListLoanCancel(){
    this.router.navigate(['loan-cancel']);
  }
  MovimientoForm(){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormMovimientosComponent);
    const componentRef: ComponentRef<FormMovimientosComponent> = this.viewContainerRef.createComponent(componentFactory);
    Swal.fire({
      title: 'Registro de movimientos',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })

  
  }
  AgendarForm(){
    this.router.navigate(['form-agendar']);
  }
  alert_payment(ids: any){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertPaymentComponent);
    const componentRef: ComponentRef<AlertPaymentComponent> = this.viewContainerRef.createComponent(componentFactory);
   
    componentRef.instance.id_loans = ids;
    componentRef.instance.options_seelec = this.selectedOption;

    Swal.fire({
      title: 'Alerta de pago',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })
  }

  async requst_loan(dato: any):  Promise<any>{
    const navigationExtras: NavigationExtras = {
      queryParams: { dato: JSON.stringify(dato), agendar: true }
    };
    this.router.navigate(['form-agendar'], navigationExtras);
  }

  refinanceLoan(ids: any){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoanRefinanceComponent);
    const componentRef: ComponentRef<LoanRefinanceComponent> = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.id_parm = ids;

    Swal.fire({
      title: 'Refinanciamiento',
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      html:componentRef.location.nativeElement
    })

  }
  formatDate(inputDate:string) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  } 

  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    const parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    return parsedDate;
  }
  
  async deletePayment(ids: any, loan: any, descript:any) {
    const token = this.getCookie("_token")
    const sucursal = this.getCookie("_branch")
    Swal.fire({
      title: 'Eliminar',
      icon: 'warning',
      text: 'Seguro quieres eliminar el pago?',
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'OK',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let data = {}
          if(descript != "undefined"){
            data = {
              id: ids,
              loans_id:loan,
              sucursal_id:sucursal,
              descript: descript
            }
          }else{
            data = {
              id: ids,
              loans_id:loan,
              sucursal_id:sucursal
            }
          }

          const response = await fetch(this.url.Ms1 + "pago_loan", {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
    
          })
          if (response.ok) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Eliminado!',
              text: 'Se elimino el pago',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            });
            window.location.reload()
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'No se puede eliminar el pago',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            })
          }
        }catch (error) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'No se puede eliminar el prestamo',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          })
        }
      }
    })



  
  }

  calcularDiferenciaEnDias(date_end: string, last_payment: string, type_loan: string): number {
    if (date_end && last_payment) {
      const dateEnd = new Date(date_end);
      const lastPayment = new Date(last_payment);
      if(type_loan != "Diario"){
        dateEnd.setDate(dateEnd.getDate() + 5);
      }
      const diferenciaEnMilisegundos = lastPayment.getTime() - dateEnd.getTime();
      const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      if (diferenciaEnDias < 0){
        return 0;
      }
      return diferenciaEnDias;
    } else {
      return 0;
    }
  }

  async PlanDate(dato: any,show: boolean = false){
    const token = this.getCookie("_token")
    const id = dato.id
    const fecha_pago = ''
    try {
      const response = await fetch(this.url.Ms1 + "pago_loan/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "ngrok-skip-browser-warning": "69420"
        },

      });
   
      const result = await response.json()
      const response2 = await fetch(this.url.Ms1 + "plan_payments", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          loan_id: id
        })
      });
      let info_plan = await response2.json()
      if('error' in result){
        
        const planDates = info_plan.plan;
          const inicial_date = this.formatDate(dato.start_date);
          const cuotas = parseInt(dato.total_installments)
          const cuotasPagas = cuotas-parseInt(dato.loan_details_id.missing_installments)
          const fequency = dato.frequency
          const value = dato.fee_payment
        
          let htmlContent = `
          <style>
          .swal2-close{
            position: absolute;
            top: 0;
            right: 0;
            width: 1.2em;
            height: 1.2em;
            transition: color 0.1s ease-out;
            border: none;
            background: transparent;
            color: #cccccc;
            font-family: serif;
            font-size: 40px;
            display: block !important;
            cursor: pointer;
          }
  
          .swal2-content {
          padding: 0px !important;
          overflow-x: scroll !important;
        }
        </style>
          <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Fecha</th>
            <th scope="col">Valor</th>
            <th scope="col">Estado</th>
          </tr>
        </thead>
        <tbody>
        `;
        let i=0;
        for (const date of planDates) {
          // Verificar si la fecha es una vacación

          if (i<cuotasPagas){
            htmlContent += `
            <tr>
                <th scope="row" style=text-wrap: nowrap;">${this.formatDate(date)}</th>
                <td>${value}</td>
                <td style="color: green;">Pagado</td>

            </tr>
            `;
          }else{
            htmlContent += `
            <tr>
                <th scope="row" style=text-wrap: nowrap;">${this.formatDate(date)}</th>
                <td>${value}</td>
                <td style="color: red;">Pendiente</td>
  
            </tr>
            `;
          }
      
          // Construir la fila de la tabla
          
          i++;
      }
      
      htmlContent += `
      </tbody>
      </table>
      `;

      if(show){
        Swal.fire({
          title: 'Fechas de pagos',
          html: htmlContent,
          confirmButtonColor: '#0e658e',
          confirmButtonText: '¿Eliminar prestamo?',
          onOpen: (modalElement: any) => {
            const closeButton = document.createElement('button');
            closeButton.className = 'swal2-close';
            closeButton.onclick = function() { Swal.close(); };
            closeButton.textContent = '×';
            modalElement.querySelector('.swal-title').appendChild(closeButton);
          }
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Eliminar',
              icon: 'warning',
              text: 'Seguro quiere eliminar el prestamo?',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
              cancelButtonColor: '#d33',
              showCancelButton: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                const cash_id = this.getCookie("_cash")
                const loan_id = dato.id;
                const details_id = dato.loan_details_id.id
                const valor = -Number(dato.value)
                const detalle_prestamo = {
                  detail_id: details_id,
                  loan_id: loan_id,
                  cash_id: cash_id,
                  value_loan: valor
                };
      
                try {
                  const response = await fetch(this.url.Ms1 + "prestamo", {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(detalle_prestamo)
                  });
                  if (response.ok) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Guardando...',
                      showConfirmButton: true,  // Mostrar el botón "OK"
                      confirmButtonText: 'OK',  // Texto del botón "OK"
                      allowOutsideClick: false, // Evitar el clic fuera del cuadro
                      allowEscapeKey: false,    // Evitar la tecla "esc"
                      confirmButtonColor: '#0e658e',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload()     
                      }
                    });
                  }
                }catch (error) {
                  alert("ERROR")
                }
              }
            })
          }
        })
      }

      
        
      }
      else if (response.ok) {
          const planDates = info_plan.plan;

          const inicial_date = this.formatDate(dato.start_date);
          const cuotas = parseInt(dato.total_installments) // 10 o 5 cuotas como elemplo
          const fequency = dato.frequency
          const value = parseFloat(dato.fee_payment)
          const payments = result
          const saldo = dato.loan_details_id.positive_balance
          const lengt = parseInt(payments.length)
          const missing= parseInt(dato.loan_details_id.missing_installments)
          const cuotasPagas = cuotas-parseInt(dato.loan_details_id.missing_installments)
          let prestamoPagado = ''
          let prestamoPagado2 = ''
          let htmlContent = `
          <style>
          .swal2-close{
            position: absolute;
            top: 0;
            right: 0;
            width: 1.2em;
            height: 1.2em;
            transition: color 0.1s ease-out;
            border: none;
            background: transparent;
            color: #cccccc;
            font-family: serif;
            font-size: 40px;
            display: block !important;
            cursor: pointer;
          }
          .saldo{
            display: block;
            margin-bottom: 14px;
            text-align: initial;
          }
          .swal2-content {
          padding: 0px !important;
          overflow-x: scroll !important;
        }
        </style>

          <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Fecha</th>
            <th scope="col">Valor</th>
            <th scope="col">Estado</th>
          </tr>
        </thead>
        <tbody>
        `;
        let i=0;
        for (const date of planDates) {
          // Verificar si la fecha es una vacación
          if (i<cuotasPagas){
            htmlContent += `
            <tr>
                <th scope="row" style=text-wrap: nowrap;">${this.formatDate(date)}</th>
                <td>$${value}</td>
                <td style="color: green;">Pagado</td>

            </tr>
            `;
          }else{
            htmlContent += `
            <tr>
                <th scope="row" style=text-wrap: nowrap;">${this.formatDate(date)}</th>
                <td>$${value}</td>
                <td style="color: red;">Pendiente</td>
  
            </tr>
            `;
          }
      
          // Construir la fila de la tabla
          
          i++;
      }

       
        if(show){
          
          Swal.fire({
            title: 'Fechas de pagos',
            html: htmlContent,
            confirmButtonColor: '#0e658e',
            confirmButtonText: '¿Eliminar prestamo?',
            onOpen: (modalElement: any) => {
              
              const closeButton = document.createElement('button');
              closeButton.className = 'swal2-close';
              closeButton.onclick = function() { Swal.close(); };
              closeButton.textContent = '×';
              const swalTitleElement = modalElement.querySelector('.swal-title');
              if (swalTitleElement) {
                swalTitleElement.appendChild(closeButton);
              }
            
            }
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Eliminar',
                icon: 'warning',
                text: 'Seguro quiere eliminar el prestamo?',
                showConfirmButton: true,  // Mostrar el botón "OK"
                confirmButtonText: 'OK',  // Texto del botón "OK"
                allowOutsideClick: false, // Evitar el clic fuera del cuadro
                allowEscapeKey: false,    // Evitar la tecla "esc"
                confirmButtonColor: '#0e658e',
                cancelButtonColor: '#d33',
                showCancelButton: true,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const loan_id = dato.id;
                  const details_id = dato.loan_details_id.id
                  const detalle_prestamo = {
                    detail_id: details_id,
                    loan_id: loan_id,
                  };
    
                  try {
                    const response = await fetch(this.url.Ms1 + "prestamo", {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                      },
                      body: JSON.stringify(detalle_prestamo)
                    });
                    let infos = await response.json()
                    if(infos.ok){
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Guardando...',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload()     
                        }
                      });
                    }else{
                      
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'No se puede eliminar el prestamo',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                    });
                  }
                  }catch (error) {
                    console.error('Error al enviar el formulario', error);
                  }
                }
              })
            }
          })
        }
        else{
          return
        }
      }
    }catch (error) {
      console.error('Error al enviar el formulario', error);
    }    
  }
  
  paymetTotal(data: any){
    let total = 0
    let cuotas = 0
    let cuotasPagas = 0

    data.forEach((element: any) => {
      total = (total) + Number(element.fee_payment)
      cuotas = parseInt(element.loans_id.total_installments)
      cuotasPagas = cuotas-parseInt(element.loans_id.loan_details_id.missing_installments)
    });
    return {total,cuotasPagas}
  }
  async modalCLient(dato: any,show: boolean = false){
    const token = this.getCookie("_token")
    const id = dato.id
    const fecha_pago = ''
    try {
      const response = await fetch(this.url.Ms1 + "pago_loan/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "ngrok-skip-browser-warning": "69420"
        },

      });
      let htmlContent = `
      <style>
      .swal2-actions button{
        margin-top: 50px;
      }
      .swal2-actions{
        z-index: 0 !important;
      }
      #plan_date{
        margin: auto;          
        display: block;
      }
      .eliminarButton{
        padding: 6px 14px;
        border-radius: 4px;
        font-size: 15px;
        border: none;
        background-color: #dc3545;
      }
      .swal2-close{
        position: absolute;
        top: 0;
        right: 0;
        width: 1.2em;
        height: 1.2em;
        transition: color 0.1s ease-out;
        border: none;
        background: transparent;
        color: #cccccc;
        font-family: serif;
        font-size: 40px;
        display: block !important;
        cursor: pointer;
      }
      .delete_add{
        margin-top: 5px !important;
      }

      .swal2-content {
      padding: 0px !important;
      overflow-x: scroll !important;
    }
    </style>
      <table class="table table-striped">
      <thead>
      <tr>
        
        `;
        if(!this.permissions.hasOwnProperty('btn_pagos_delete')){
          htmlContent += `
          <th scope="col">Fecha pago</th>
          <th scope="col">Valor pagado</th>
           <th scope="col">Acciones</th>
          `;
        }
        else{
          htmlContent += `
          <th scope="col" colspan="2">Fecha pago</th>
          <th scope="col">Valor pagado</th>
          `;
        }
    htmlContent += `
      </tr>
    </thead>
    <tbody>
    `;
      const result = await response.json()
      if('error' in result){
        if(show){
            htmlContent += `
            <tr>
              
            </tr>
          `;
          htmlContent += `
          </tbody>
          </table>
          <br>
          <div style="display: flex; justify-content: center; width:100%">
          <div class="row">
          <div class="col"></div>
          `;
          if(!this.permissions.hasOwnProperty('btn_prestamo_plan_ver')){
            htmlContent += `
            <div style="display: flex; justify-content: center; width: 100%;">
              <button id="plan_date" class="swal2-confirm swal2-styled">Plan de pagos</button>
            </div>
            `;
        }
        htmlContent += `
          <div class="col"></div>
          </div>
          </div>
        `;
          Swal.fire({
            title: 'Pagos Realizados',
            html: htmlContent,
            confirmButtonColor: '#0e658e',
            confirmButtonText: '¿Eliminar prestamo?',
            onOpen: (modalElement: any) => {
              const eliminarButton = modalElement.querySelectorAll('#plan_date');
              eliminarButton.forEach((button: HTMLElement) => {
                button.addEventListener('click', (event: MouseEvent) => {
                  this.PlanDate(dato,true);
                });
              })

              const closeButton = document.createElement('button');
              closeButton.className = 'swal2-close';
              closeButton.onclick = function() { Swal.close(); };
              closeButton.textContent = '×';
              modalElement.querySelector('.swal-title').appendChild(closeButton);
            }
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Eliminar',
                icon: 'warning',
                text: 'Seguro quiere eliminar el prestamo?',
                showConfirmButton: true,  // Mostrar el botón "OK"
                confirmButtonText: 'OK',  // Texto del botón "OK"
                allowOutsideClick: false, // Evitar el clic fuera del cuadro
                allowEscapeKey: false,    // Evitar la tecla "esc"
                confirmButtonColor: '#0e658e',
                cancelButtonColor: '#d33',
                showCancelButton: true,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const cash_id = this.getCookie("_cash")
                  const loan_id = dato.id;
                  const details_id = dato.loan_details_id.id
                  const valor = -Number(dato.value)
                  const detalle_prestamo = {
                    detail_id: details_id,
                    loan_id: loan_id,
                    cash_id: cash_id,
                    value_loan:valor,
                  };
        
                  try {
                    const response = await fetch(this.url.Ms1 + "prestamo", {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                      },
                      body: JSON.stringify(detalle_prestamo)
                    });
                    if (response.ok) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Prestamo eliminado',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload()     
                        }
                      });
                    }else{
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'No se pudo eliminar el prestamo',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                      })
                      return
                    }
                  }catch (error) {
                    console.error('Error al enviar el formulario', error);
                  }
                }
              })
            }
          })
        }         
      }
      else if (response.ok){
        if(show){
          const groupedData: any = {};

          // Iterar sobre los datos y agruparlos por refinance_id
          result.forEach((item: any) => {
            const refinanceId = item.refinance_id;
            
            if (refinanceId !== null) {
              if (!groupedData[refinanceId]) {
                groupedData[refinanceId] = [];
              }
              
              groupedData[refinanceId].push(item);
            }
          
          });
          let total_payment = this.paymetTotal(result)
          let refinance_id = result[0].refinance_id;
          let conts = 1;
          for (let i = 0; i < result.length; i++) {
            const item = result[i];
            let fecha = this.formatDate(item.date);
            if(refinance_id == item.refinance_id){
              htmlContent += `
              <tr>
                <td class="fecha_rows">${fecha}</td>
                <td>$${item.fee_payment}</td>
                <td class="col_actions">
                ${i === result.length - 1 
                    ? `<button class="eliminarButton" data-id="${item.id}" data-descrip="${item.description}"  data-loan="${item.loans_id.id}">Eliminar</button>` 
                    : (item.fee_payment === '0' ? 'No pago' : (item.is_pix === false ? 'Efectivo' : 'Transferencia'))}
            </td>
              </tr>
            `;
            }else{
              htmlContent += `
              <th colspan="4">
                Refinanciacion #${conts}
              </th>
              <tr>
                <td class="fecha_rows">${fecha}</td>
                <td>$${item.fee_payment}</td>
                <td class="col_actions">${i === result.length - 1 ? `<button class="eliminarButton" data-id="${item.id}" data-descrip="${item.description}" data-loan="${item.loans_id.id}">Eliminar</button>` : ''}</td>
              </tr>
            `;
            conts++
            }
            
            refinance_id = item.refinance_id
          }


        htmlContent += `
        </tbody>
        <tr>
          <th></th>
          <th>Total</th>
          <th>Cuotas pagas</th>
        </tr>
        <tr>
          <td></td>
          <td>$ ${total_payment.total.toFixed(2)}</td>
          <td>${total_payment.cuotasPagas}</td>
      </tr> 
        </table>
     
        <br>
        `;
        if(!this.permissions.hasOwnProperty('btn_prestamo_plan_ver')){
          htmlContent += `
              <div style="display: flex; justify-content: center; width: 100%;">
                <button  id="plan_date" class="swal2-confirm swal2-styled">Plan de pagos</button>
              </div>
            `;
        }



          Swal.fire({
            title: 'Pagos Realizados',
            html: htmlContent,
            ...(!this.permissions.hasOwnProperty('btn_prestamo_delete') ? {confirmButtonText: '¿Eliminar prestamo?', confirmButtonColor: '#0e658e', customClass:{confirmButton: 'delete_add'}} :{showConfirmButton:false}),
            onOpen: (modalElement: any) => {
              const plans = modalElement.querySelectorAll('#plan_date');
              plans.forEach((button: HTMLElement) => {
                button.addEventListener('click', (event: MouseEvent) => {
                  this.PlanDate(dato,true);
                });
              })


              const eliminarButton = modalElement.querySelectorAll('.eliminarButton');
              if(!this.permissions.hasOwnProperty('btn_pagos_delete')){
                eliminarButton.forEach((button: HTMLElement) => {
                  button.addEventListener('click', (event: MouseEvent) => {
                    const dataId = button.getAttribute('data-id');
                    const loanId = button.getAttribute('data-loan');
                    const descript = button.getAttribute('data-descrip');
                    this.deletePayment(dataId,loanId,descript );
                  });
                })
              }
              else{
                const fechas_row = modalElement.querySelectorAll('.fecha_rows'); 
                const actions =  modalElement.querySelectorAll('.col_actions');
                actions.forEach((buttons:  HTMLElement) => {
                  buttons.remove()
                })
                fechas_row.forEach((row: any) => {
                  row.colSpan = 2;
                })
              }
              
              
              const closeButton = document.createElement('button');
              closeButton.className = 'swal2-close';
              closeButton.onclick = function() { Swal.close(); };
              closeButton.textContent = '×';
              modalElement.querySelector('.swal-title').appendChild(closeButton);
            }
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Eliminar',
                icon: 'warning',
                text: 'Seguro quiere eliminar el prestamo?',
                showConfirmButton: true,  // Mostrar el botón "OK"
                confirmButtonText: 'OK',  // Texto del botón "OK"
                allowOutsideClick: false, // Evitar el clic fuera del cuadro
                allowEscapeKey: false,    // Evitar la tecla "esc"
                confirmButtonColor: '#0e658e',
                cancelButtonColor: '#d33',
                showCancelButton: true,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const cash_id = this.getCookie("_cash")
                  const loan_id = dato.id;
                  const details_id = dato.loan_details_id.id
                  const valor = -Number(dato.value)
                  const detalle_prestamo = {
                    detail_id: details_id,
                    loan_id: loan_id,
                    cash_id: cash_id,
                    value_loan: valor
                  };
        
                  try {
                    const response = await fetch(this.url.Ms1 + "prestamo", {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                      },
                      body: JSON.stringify(detalle_prestamo)
                    });
                    if (response.ok) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Guardando...',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload()     
                        }
                      });
                    }else{
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'No se pudo eliminar el prestamo',
                        showConfirmButton: true,  // Mostrar el botón "OK"
                        confirmButtonText: 'OK',  // Texto del botón "OK"
                        allowOutsideClick: false, // Evitar el clic fuera del cuadro
                        allowEscapeKey: false,    // Evitar la tecla "esc"
                        confirmButtonColor: '#0e658e',
                      })
                    }
                  }catch (error) {
                    console.error('Error al enviar el formulario', error);
                  }
                }
              })
            }
          })
        }
      }
    }catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  }
    
  Payment(loan: number, feePayment: string, cuotas: string, description: any,fullname:any,all: any){
    const formattedFeePayment = parseInt(feePayment)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PaymentsComponent);
    const componentRef: ComponentRef<PaymentsComponent> = this.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.loan_id = loan;
    componentRef.instance.formattedFeePayment = formattedFeePayment;
    componentRef.instance.cuotas_loan = cuotas;
    componentRef.instance.description_payment = description;
    componentRef.instance.options_seelec = this.selectedOption;
    componentRef.instance.fullNames = fullname

    // datos recibo
    let cuota_pay = String("{{cambio}}") + "/" + String(all.total_installments)
    let mora_r = this.calcularDiferenciaEnDias(all.possible_payment,all.today,all.frequency)
    componentRef.instance.fecha_ven = all.expiry_date
    componentRef.instance.cuota_pay = cuota_pay
    componentRef.instance.saldo_recibe = all.loan_details_id.outstanding_balance
    componentRef.instance.value_cuota_r = all.fee_payment
    componentRef.instance.type_frecuncy_r = all.frequency
    componentRef.instance.mora_r = mora_r
    Swal.fire({
      title: 'Pago cuota',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement,
    })

  }

  triggerFileInput(input_id:any) {
    const fileInput = document.getElementById(input_id) as HTMLInputElement;
    const preview = document.getElementById('img-ad') as HTMLInputElement;
    fileInput.click();
    if(fileInput){
      fileInput.addEventListener('change', function() {
        const file = this.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            let img_base = e.target.result
            if (preview) {
              preview.src = img_base
            }
          };
          reader.readAsDataURL(file);
        }
        else {
          if (preview) {
            preview.innerHTML = 'No image selected';
          }
        }
      })
    }
 
  }
    

  UpdtaeClientModal(dato:any){
    if(this.permissions.hasOwnProperty('btn_cliente_ver_imagen')){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'No tiene permisos para ver imagenes',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return
    }else{
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UpdateClientComponent);
      const componentRef: ComponentRef<UpdateClientComponent> = this.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.dato = dato;

      const swalContent = componentRef.location.nativeElement;
      const swal = this.url.showSwal(swalContent);
      this.url.setInitialSwal(swal);

      componentRef.instance.openImg = (title: string, input_id: string, imgs: string, type: string) => {
        if(!this.permissions.hasOwnProperty('btn_cliente_editar_imagen')){
          Swal.fire({
            title: title,
            html: `
              <img style="border-radius:10px; margin-top:20px; width: 250px; height: 250px;" src="${imgs}" id="img-ad" alt="Imagen 1">
              <input type="file" id="${input_id}" style="display: none;"/>
            `,
            showCloseButton: true,
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#0e658e',
            onOpen: () => {
              let img = document.getElementById("img-ad");
              if (img) {
                img.addEventListener("click", this.triggerFileInput.bind(this, input_id));
              }
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const token = this.getCookie("_token");
              const image_up = (document.getElementById(input_id) as HTMLInputElement).files;
              const formData = new FormData();
              if (image_up) {
                formData.append('img', image_up[0]);
              }
              formData.append('img_id', type);
              let dni:any;
              formData.append('customer_id', dato.customers_id.id);

              const response = await fetch(this.url.Ms1 + "save_image", {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer ' + token,
                },
                body: formData
              });
              if (response.ok) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Modificado!',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  confirmButtonColor: '#0e658e'
                }).then(data => {
                  componentRef.instance.imagenList(dato.customers_id.customer_detail_id.id);
                  const updatedContent = componentRef.location.nativeElement;
                  let infos = this.url.showSwal(updatedContent);
                  this.url.setInitialSwal(infos);
                });
              }
            }else{
              let infos = this.url.showSwal(swalContent);
              this.url.setInitialSwal(infos);
            }
          });
        }else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'No tiene permisos editar imagenes',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          return
        }
      };
    }
  }


}
