import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
/* URL PARA PRODUCCIONS*/
/*
export class Url {
  public Ms1: string = 'https://Pacificsoucoes.eastus.cloudapp.azure.com:3031/';
  public Ms2: string = 'https://Pacificsoucoes.eastus.cloudapp.azure.com:3032/';
  public Ms4: string = 'https://Pacificsoucoes.eastus.cloudapp.azure.com:3034/';
  public Ms5: string = 'https://Pacificsoucoes.eastus.cloudapp.azure.com:3035/';
}

/*** URL PARA LOCAL DE PRUBEAS (CAMBIAR POR IP PROPIA) 
 * */


/*Para usar NGROK ejecutar los siguientes comandos:*/
//Se debe abrir en una terminal de ubuntu esta ruta
//chmod +x init_ngrok.sh  --> Darle permisos al script
//./init_ngrok.sh         --> Ejecutar el script

/*

export class Url {
  public Ms1: string = 'https://ape-coherent-colt.ngrok-free.app/';
  public Ms2: string = 'https://louse-poetic-shiner.ngrok-free.app/';
  public Ms4: string = 'https://expert-harmless-rat.ngrok-free.app/';
  public Ms5: string = 'https://pleasant-ideally-lobster.ngrok-free.app/';
}
*/


export class Url {
  private initialSwal: any;
  //public Ms1: string = 'http://172.27.72.141:5000/';
  public Ms1: string = 'https://bakpacific.plataformal.com/';
  public Ms2: string = 'https://bakpacific.plataformal.com/';
  public Ms4: string = 'https://bakpacific.plataformal.com/';
  public Ms5: string = 'https://bakpacific.plataformal.com/';

showSwal(content: any) {
  return Swal.fire({
    showCloseButton: true,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    html: content
  });
}

setInitialSwal(swal: any) {
  this.initialSwal = swal;
}

getInitialSwal() {
  return this.initialSwal;
}

}



export class Permission {
  public static getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }
  
  public static validation(data: any): any {
    var _rol = Permission.getCookie('_rol');
    let conten: any = []
    if(_rol != "SuperUsuario"){
      var permission_list =  JSON.parse(data);
      permission_list.forEach((element:any) => {
        if(element.name === "Vista"){
          conten.push(element.front_element_id)
        }
        else if(element.front_element_id != null){
          conten.push(element.front_element_id)
        }
      });
      return conten
    }
    return conten

  }

  public static ElementPermissionObjects(): any {
    var valor = sessionStorage.getItem('permission');
    let info = Permission.validation(valor)
    let object: any ={}
    info.forEach((dato: any) =>{
      object[dato] = false
    })
    return object
  }
}



